import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DeliveringTaskListItem, ModalService, TaskListItem } from 'shared';
import { DeliveryTaskState } from 'shared';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import { environment } from 'dm-src/environments/environment';
import { PaymentType } from 'shared';
import { DeliveringTaskButtonsComponent } from 'dm-src/app/modules/my-tasks/task-group/task-group-item/delivering-task-item/delivering-task-buttons/delivering-task-buttons.component';

@Component({
    selector: '[delivering-task-item]',
    templateUrl: './delivering-task-item.component.html',
    styleUrls: ['./delivering-task-item.component.scss'],
})
export class DeliveringTaskItemComponent implements OnInit {
    @Input() task: DeliveringTaskListItem;
    @ViewChild(DeliveringTaskButtonsComponent)
    child: DeliveringTaskButtonsComponent;
    public style: string;
    public isCollapsed = true;
    public isVisible: boolean;
    public dateFormat: string;
    public PaymentType = PaymentType;

    public get checkButtonVisible(): boolean {
        return this.task.state === DeliveryTaskState[DeliveryTaskState.InProgress];
    }

    public get hasTotalPriceDiscount(): boolean {
        return this.task.hasTotalPriceDiscount;
    }

    public get hasRokshDiscount(): boolean {
        return this.task.hasRokshDiscount;
    }

    public get hasTotalPriceOrRokshDiscount(): boolean {
        return this.task.hasTotalPriceDiscount || this.task.hasRokshDiscount;
    }

    constructor(
        private _myTasksService: MyTasksService,
        private _modalService: ModalService
    ) {
        this.dateFormat = environment.deliveryDateFormat;
    }

    ngOnInit(): void {
        this.isVisible = this.checkTaskVisibility();
        this._myTasksService.taskGroups.subscribe(() => {
            this.checkTaskVisibility();
        });
    }

    public showCheckModal() {
        this._myTasksService.setSelectedTask(this.task);
        this._modalService.setModalVisibility(true, 'package-verify-modal');
    }

    private checkTaskVisibility() {
        return !(
            this.task.state === DeliveryTaskState[DeliveryTaskState.Done] ||
            this.task.state === DeliveryTaskState[DeliveryTaskState.Failed]
        );
    }

    handleCollapse() {
        this.isCollapsed = !this.isCollapsed;
        this.child.handleCollapse(this.isCollapsed);
    }
}
