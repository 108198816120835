import { Component, Input, OnInit, Inject } from '@angular/core';
import {
    OrderItem,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    EventType,
    ActionType,
    OrderStateEventLog,
} from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { CollectingStatesService } from '../../../collecting-states.service';

@Component({
    selector: '[app-collected-items-buttons]',
    templateUrl: './collected-items-buttons.component.html',
    styleUrls: ['./collected-items-buttons.component.scss'],
})
export class CollectedItemsButtonsComponent {
    @Input() orderItem: OrderItem;

    constructor(
        private _orderItemsService: CollectingItemsService,
        public states: CollectingStatesService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService
    ) {}

    public cancel() {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.CollectedOrderItemCancelButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._orderItemsService.removeItemFromCollected(this.orderItem);
    }

    public removedFromBasket() {
        this._orderItemsService.removeItemFromBasket(this.orderItem);
    }
}
