<ng-template #modal let-modal>
    <div
        class="modal-header modal-fullscreen bg-{{ bgColorClass }} text-{{ colorClass }}"
    >
        <h4 class="modal-title" id="modal-basic-title">{{ title | translate }}</h4>
        <button
            *ngIf="hasCloseIcon"
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="closeModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-content> </ng-content>
    </div>
</ng-template>
