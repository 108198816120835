import { PaymentType } from '../types/payment-type';

export class CollectingDoneResponse {
    public isPaymentSucceeded: boolean;
    public isPaidBefore: boolean;
    public isTotalPriceValid: boolean;
    public isVirtualPaymentAvailable: boolean;
    public isVirtualPaymentSucceeded: boolean;
    public isInvoicingSucceeded: boolean;
    public isInvoiceSentSuccessfully: boolean;
    public message: string;
    public paymentType: PaymentType;
}
