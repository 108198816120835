import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ComletedStartControl } from '../../dtos/comleted-start-control';
import { SupplierWorktimeControl } from '../../dtos/supplier-worktime-control';
import { HttpResponse } from '@angular/common/http';
export const SUPPLIER_WORKTIME_CONTROL_SERVICE_IMPL =
    new InjectionToken<ISupplierWorktimeControlService>(
        'supplierWorktimeControlServiceImpl'
    );

export interface ISupplierWorktimeControlService {
    GetStartControls(userID: string): Observable<SupplierWorktimeControl[]>;
    AddComletedStartControl(
        comletedStartControl: ComletedStartControl
    ): Observable<HttpResponse<SupplierWorktimeControl[]>>;
}
