import { Component, Input } from '@angular/core';
import { TaskListItem } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { TasksService } from 'dm-src/app/modules/tasks/tasks.service';
import { ListService } from 'shared';
import { DeliveryTaskState } from 'shared';
import { BagType } from 'shared';

@Component({
    selector: '[task-list-item]',
    templateUrl: './task-list-item.component.html',
    styleUrls: ['./task-list-item.component.scss'],
})
export class TaskListItemComponent {
    @Input() public index: number;
    @Input() public visibleColumnIndexes: number[];
    @Input() public task: TaskListItem;
    public DeliveryTaskState = DeliveryTaskState;

    public dateFormat: string;
    BagType = BagType;

    constructor(public tasksService: TasksService, public listService: ListService) {
        this.dateFormat = environment.deliveryDateFormat;
    }

    public isVisibleColumn(index: number): boolean {
        return this.visibleColumnIndexes.includes(index);
    }

    public openOrderDetailsModal() {
        this.tasksService.selectOrder(this.task.orderID);
        this.tasksService.setOrderDetailsModalVisibility(true);
    }
}
