import {
    AuthenticationService,
    LoggerClientService,
    AccountsService,
    ACCOUNTS_SERVICE_IMPL,
    AUTH_SERVICE_IMPL,
    LOGGER_CLIENT_SERVICE_IMPL,
    USER_SETTINGS_IMPL,
    API_CLIENT_SERVICE_IMPL,
    APIClientService,
    IAPIClientService,
    UserSettingsService,
    TASKS_SERVICE_IMPL,
    TasksService,
    ORDERS_SERVICE_IMPL,
    OrdersService,
    ORDER_RETURN_SERVICE_IMPL,
    OrderReturnService,
    IMAGE_COMPRESSION_SERVICE_IMPL,
    ImageCompressionService,
    HttpStatusInterceptor,
    JwtInterceptor,
    GEOLOCATION_SERVICE_IMPL,
    GeolocationService,
    OFFLINEPRODUCT_SERVICE_IMPL,
    OfflineProductService,
    REALTIME_CLIENT_IMPL,
    RealtimeClientService,
    ProviderShopBoxService,
    PROVIDER_SHOPBOX_SERVICE_IMPL,
    SUPPLIER_WORKTIME_CONTROL_SERVICE_IMPL,
    SupplierWorktimeControlService,
} from 'shared';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { SUPPLIERS_SERVICE_IMPL } from 'dm-src/services/suppliers/isuppliers-service';
import { SuppliersService } from 'dm-src/services/suppliers/suppliers-service';
import { SUPPLIER_RESOURCES_SERVICE_IMPL } from 'dm-src/services/suppliers/isupplier-resources-service';
import { SupplierResourcesService } from 'dm-src/services/suppliers/supplier-resources-service';
import { COLLECTING_SERVICE_IMPL } from 'dm-src/services/collecting/icollecting.service';
import { CollectingService } from 'dm-src/services/collecting/collecting.service';
import { SUPPLIER_REGIONS_SERVICE_IMPL } from 'dm-src/services/suppliers/isupplier-regions-service';
import { SupplierRegionsService } from 'dm-src/services/suppliers/supplier-regions-service';
import { TourPlannerService } from '../services/tourplanner/tourplanner.service';
import { TOURPLANNER_SERVICE_IMPL } from '../services/tourplanner/itourplanner.service';
import { QR_FACTORY_SERVICE_IMPL } from '../services/qr-factory/iqr-factory.service';
import { QRFactoryService } from '../services/qr-factory/qr-factory-service';
import { BARCODE_FACTORY_SERVICE_IMPL } from '../services/barcode-factory/ibarcode-factory.service';
import { BarcodeFactoryService } from '../services/barcode-factory/barcode-factory.service';

import { AppPolicyRoles } from './app.policies.config';
import { TranslateService } from '@ngx-translate/core';
import { PROVIDERSHOP_SERVICE_IMPL } from '../services/providershop/iprovidershop-service';
import { ProviderShopService } from '../services/providershop/providershop-service';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';
import { NATIVE_BARCODE_READER } from 'dm-src/services/native-barcode-reader/iprovider-native-barcode-reader.service';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { NATIVE_TAKE_PHOTO } from 'dm-src/services/native-take-photo/iprovider-native-take-photo.service';

export const serviceProviders = [
    TranslateService,
    {
        provide: API_CLIENT_SERVICE_IMPL,
        useClass: APIClientService,
    },
    {
        provide: AUTH_SERVICE_IMPL,
        useFactory: (apiClient: IAPIClientService) => {
            return new AuthenticationService(
                apiClient,
                AppPolicyRoles,
                'DeliveryManagerUser'
            );
        },
        deps: [API_CLIENT_SERVICE_IMPL],
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpStatusInterceptor,
        multi: true,
    },
    {
        provide: ACCOUNTS_SERVICE_IMPL,
        useClass: AccountsService,
    },
    {
        provide: TASKS_SERVICE_IMPL,
        useClass: TasksService,
    },
    {
        provide: TOURPLANNER_SERVICE_IMPL,
        useClass: TourPlannerService,
    },
    {
        provide: LOGGER_CLIENT_SERVICE_IMPL,
        useClass: LoggerClientService,
    },
    {
        provide: QR_FACTORY_SERVICE_IMPL,
        useClass: QRFactoryService,
    },
    {
        provide: BARCODE_FACTORY_SERVICE_IMPL,
        useClass: BarcodeFactoryService,
    },
    {
        provide: COLLECTING_SERVICE_IMPL,
        useClass: CollectingService,
    },
    {
        provide: ORDERS_SERVICE_IMPL,
        useClass: OrdersService,
    },
    {
        provide: IMAGE_COMPRESSION_SERVICE_IMPL,
        useClass: ImageCompressionService,
    },
    {
        provide: ORDER_RETURN_SERVICE_IMPL,
        useClass: OrderReturnService,
    },
    {
        provide: GEOLOCATION_SERVICE_IMPL,
        useClass: GeolocationService,
    },
    {
        provide: SUPPLIERS_SERVICE_IMPL,
        useClass: SuppliersService,
    },
    {
        provide: SUPPLIER_RESOURCES_SERVICE_IMPL,
        useClass: SupplierResourcesService,
    },
    {
        provide: SUPPLIER_REGIONS_SERVICE_IMPL,
        useClass: SupplierRegionsService,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpStatusInterceptor,
        multi: true,
    },
    {
        provide: GEOLOCATION_SERVICE_IMPL,
        useClass: GeolocationService,
    },
    {
        provide: OFFLINEPRODUCT_SERVICE_IMPL,
        useClass: OfflineProductService,
    },
    {
        provide: USER_SETTINGS_IMPL,
        useClass: UserSettingsService,
    },
    {
        provide: REALTIME_CLIENT_IMPL,
        useClass: RealtimeClientService,
    },

    {
        provide: PROVIDERSHOP_SERVICE_IMPL,
        useClass: ProviderShopService,
    },
    {
        provide: PROVIDER_SHOPBOX_SERVICE_IMPL,
        useClass: ProviderShopBoxService,
    },
    {
        provide: NATIVE_BARCODE_READER,
        useClass: NativeBarcodeReaderService,
    },
    {
        provide: NATIVE_TAKE_PHOTO,
        useClass: NativeTakePhotoService,
    },
    {
        provide: SUPPLIER_WORKTIME_CONTROL_SERVICE_IMPL,
        useClass: SupplierWorktimeControlService,
    },
];
