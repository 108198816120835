import { Component, OnInit } from '@angular/core';
import { SupplierTrackingService } from './supplier-tracking.service';
import * as mapboxgl from 'mapbox-gl';
import { Observable } from 'rxjs';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SupplierMarker } from '../../../dtos/supplier-marker';
import { SupplierTrack } from '../../../dtos/supplier-track';
import { SupplierTrackingFilter } from '../../../dtos/supplier-tracking-filter';

import { SupplierRegionsService } from 'dm-src/app/modules/supplier-regions/supplier-regions.service';

import { ISupplierRegionOption } from '../../../types/i-supplier-region-option';
import { TranslateService } from '@ngx-translate/core';
import { ColorClass, ISelectOption } from 'shared';
import { SupplierFilterDTO } from '../../../dtos/supplier-filter';
import { FlashMessageService } from 'shared';
import { AppSettings } from '../../../../../shared/src/appsettings';
import { SupplierWorker } from '../../../dtos/supplier-worker';
import { SupplierOrder } from '../../../dtos/supplier-order';

@Component({
    selector: 'app-supplier-tracking',
    templateUrl: './supplier-tracking.component.html',
    styleUrls: ['./supplier-tracking.component.scss'],
})
export class SupplierTrackingComponent implements OnInit {
    map: mapboxgl.Map;
    public supplierRegions: ISupplierRegionOption[];
    curWorkerList: Array<SupplierWorker> = [];
    curOrderList: Array<SupplierOrder> = [];
    filteredOrderList: Array<SupplierOrder> = [];
    curTrackList: Array<SupplierTrack> = [];
    public regionsSettings = {};
    public suppliersSettings = {};
    public regionsModel: ISelectOption[];
    supplierRegionID: string | null = null;
    date: string;
    lat0: number = 47.497913; //hu
    lon0: number = 19.040236;
    selectedWorker: SupplierWorker | null = null;
    selectedOrder: SupplierOrder | null = null;

    constructor(
        private supplierTrackinngService: SupplierTrackingService,
        public supplierRegionsService: SupplierRegionsService,
        private _translateService: TranslateService,
        private _flashMessageService: FlashMessageService
    ) {
        this.date = new Date().toISOString().slice(0, 10);

        var url = AppSettings.get('backendBaseURL');
        if (url.indexOf('.at') > 0) {
            this.lat0 = 47.40285850752797;
            this.lon0 = 13.687550915428035;
        }
    }

    ngOnInit() {
        this.regionsSettings = this.getRegionsSettings();
        this.suppliersSettings = this.getSuppliersSettings();

        this.supplierRegionsService.getSupplierRegionsData();

        this.supplierRegionsService.supplierRegionOptions.subscribe(
            (regions) => (this.supplierRegions = regions)
        );
    }

    loadMap(map: any) {
        this.map = map;

        this.initMap();
    }

    initMap() {
        this.map.addControl(new mapboxgl.NavigationControl());

        //this.setMarkers();
        this.refreshWorkers();
        setInterval(() => {
            this.refreshWorkers();
        }, 15000);
    }

    refreshWorkers(reset: boolean = false) {
        if (reset) {
            this.resetWorkers();
        }

        let f: SupplierTrackingFilter = new SupplierTrackingFilter();
        f.supplierRegionID = this.supplierRegionID;
        f.date = this.date;

        this.supplierTrackinngService.getWorkers(f).subscribe((workerList) => {
            let tList = workerList.body;
            tList.forEach((worker: SupplierWorker) => {
                this.updateWorker(worker);
            }); //foreach
        }); //gettracks
    }

    resetWorkers() {
        this.curWorkerList.forEach((_worker: SupplierWorker) => {
            if (_worker.marker) {
                _worker.marker.remove();
            }
        });
        this.curWorkerList = [];
    }

    getRandomColor() {
        let letters = '01234567890123456';
        let color = '#ff';
        for (let i = 0; i < 4; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    showTrack(track: SupplierTrack) {
        this.map.addSource(track.supplierTrackID, {
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: track.coords,
                },
            },
        });

        this.map.addLayer({
            id: track.supplierTrackID,
            type: 'line',
            source: track.supplierTrackID,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': '#0000ff',
                'line-width': 2,
            },
        });
    }

    setMarkerColor(marker, color) {
        //debugger;
        let markerElement = marker.getElement();
        markerElement
            .querySelectorAll('svg path[fill="' + marker._color + '"]')[0]
            .setAttribute('fill', color);
        marker._color = color;
    }

    showHideOrderMarker(order: SupplierOrder, showHide: boolean) {
        if (showHide == false) {
            if (order.deliveryMarker) order.deliveryMarker.remove();
            if (order.shopMarker) order.shopMarker.remove();
            return;
        }

        if (!order.deliveryMarker) {
            if (order.deliveryAddress && order.deliveryAddress.lat) {
                let m = new mapboxgl.Marker({
                    scale: 1.2,
                });
                m.setLngLat([
                    order.deliveryAddress.long,
                    order.deliveryAddress.lat,
                ]).addTo(this.map);

                //worker.popup = this.createPopup(worker);
                //m.getElement().addEventListener("click", x => {
                //  this.getWorkerDetails(worker, false)
                //});
                order.deliveryMarker = m;

                order.deliveryPopup = this.createDeliveryPopup(order);

                m.getElement().addEventListener('click', (x) => {
                    //m.setPopup(worker.popup);
                    order.deliveryMarker.setPopup(order.deliveryPopup);
                    order.deliveryPopup.addTo(this.map);
                });
            }
            //this.setMarkerColor(worker.marker, this.GetWorkerColor(worker));
        }

        if (!order.shopMarker) {
            if (order.shopAddress && order.shopAddress.lat) {
                let m = new mapboxgl.Marker({
                    scale: 1.2,
                });
                m.setLngLat([order.shopAddress.long, order.shopAddress.lat]);

                //worker.popup = this.createPopup(worker);
                //m.getElement().addEventListener("click", x => {
                //  this.getWorkerDetails(worker, false)
                //});
                order.shopMarker = m;
            }
            //this.setMarkerColor(worker.marker, this.GetWorkerColor(worker));
        }

        if (order.deliveryMarker) {
            order.deliveryMarker.addTo(this.map);
            this.setMarkerColor(order.deliveryMarker, '#0000ff');
        }
        if (order.shopMarker) {
            order.shopMarker.addTo(this.map);
            this.setMarkerColor(order.shopMarker, '#ffff00');
        }
    }

    updateWorkerMarker(worker: SupplierWorker) {
        if (!worker.lat) return;
        if (!worker.marker) {
            let m = new mapboxgl.Marker({
                //color: "#ff0000",
                scale: 0.75,
            });

            //let icon = mapboxgl.Marker.icon({
            //  'marker-size': 'large', // specify the size of the marker
            //  'marker-symbol': 'bus', // specify the symbol to use in the marker
            //  'marker-color': '#fa0' // specify the color of the marker
            //});
            //icon.

            m.setLngLat([worker.long, worker.lat]).addTo(this.map);

            m.addTo(this.map);
            worker.popup = this.createPopup(worker);

            m.getElement().addEventListener('click', (x) => {
                //m.setPopup(worker.popup);
                this.showWorkerDetails(worker, false);
            });
            worker.marker = m;
        } else worker.marker.setLngLat([worker.long, worker.lat]).addTo(this.map);

        this.setMarkerColor(worker.marker, this.GetWorkerColor(worker));
    }

    GetWorkerColor(worker: SupplierWorker) {
        let css = this.getDot(worker);
        if (css.indexOf('green') >= 0) {
            return '#008000';
        } else if (css.indexOf('red') >= 0) {
            return '#ff0000';
        } else if (css.indexOf('orange') >= 0) {
            return '#ffa500';
        } else {
            return '#d3d3d3';
        }
    }

    //new or modified
    updateWorker(_worker: SupplierWorker) {
        let wNDX = this.curWorkerList.findIndex((w) => w.userID == _worker.userID);

        if (wNDX < 0) {
            this.curWorkerList.push(_worker);
            this.updateWorkerMarker(_worker);
        } else {
            let worker = this.curWorkerList[wNDX];
            worker.lat = _worker.lat;
            worker.long = _worker.long;
            this.updateWorkerMarker(worker);
        }
    }

    createPopup(worker: SupplierWorker) {
        return new mapboxgl.Popup({ offset: 25 }).setText(worker.supplierName);
    }

    createDeliveryPopup(order: SupplierOrder) {
        let html =
            '<h6>' +
            order.deliveryAddress.addressName +
            '</h6>' +
            order.deliveryAddress.postalCode +
            ' ' +
            order.deliveryAddress.cityName +
            ' ' +
            order.deliveryAddress.addressLine;
        return new mapboxgl.Popup({ offset: 25 }).setHTML(html);
    }

    showHideOrderMarkers(showHide: boolean) {
        this.filteredOrderList.forEach((order) =>
            this.showHideOrderMarker(order, showHide)
        );
    }

    public getOrders(reset: boolean) {
        this.showHideOrderMarkers(false);
        this.curOrderList = [];
        this.filteredOrderList = [];
        this.supplierTrackinngService
            .getOrders(this.supplierRegionID, this.date)
            .subscribe((result) => {
                this.filteredOrderList = this.curOrderList = result.body;
            }); //gettracks
    }

    public onRegionSelect(supplierRegion: ISupplierRegionOption): void {
        this.supplierRegionID = supplierRegion.id;

        if (supplierRegion.lat > 0) {
            this.refreshWorkers(true);
            this.getOrders(true);

            this.map.flyTo({
                center: [supplierRegion.long, supplierRegion.lat],
                zoom: 12,
                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
        } else {
            this._flashMessageService.showMessage(
                this._translateService.instant('common.danger'),
                this._translateService.instant('error.lat-long-missing'),
                3000,
                ColorClass.Danger
            );
        }

        //this.isSelectedRegionSaved = supplierRegion.id !== undefined;
        //this.supplierRegionsService.changeSelectedRegion(supplierRegion.id);
        //this._lat = supplierRegion.lat;
        //this._long = supplierRegion.long;
    }

    private getRegionsSettings(): object {
        return {
            enableSearchFilter: true,
            addNewItemOnFilter: true,
            singleSelection: true,
            labelKey: 'label',
            primaryKey: 'id',
            searchBy: ['label'],
            text: this._translateService.instant('supplier-regions.choose-region'),
            searchPlaceholderText: this._translateService.instant('common.search'),
            noDataLabel: this._translateService.instant(
                'supplier-regions.no-region-found'
            ),
        };
    }

    private getSuppliersSettings(): object {
        return {
            enableSearchFilter: true,
            addNewItemOnFilter: false,
            singleSelection: true,
            labelKey: 'label',
            primaryKey: 'id',
            searchBy: ['label'],
            text: this._translateService.instant('supplier-tracking.choose-supplier'),
            searchPlaceholderText: this._translateService.instant('common.search'),
            noDataLabel: this._translateService.instant(
                'supplier-tracking.no-supplier-found'
            ),
        };
    }

    getDot(worker: SupplierWorker) {
        if (worker.isWorking && worker.hasOrders && worker.isTracking) {
            return 'dot-green';
        } else if (!worker.isWorking && worker.hasOrders && worker.isTracking) {
            return 'dot-orange';
        } else if (worker.isWorking && (!worker.hasOrders || !worker.isTracking)) {
            return 'dot-red';
        } else return 'dot-gray';
    }

    getOrderDot(order: SupplierOrder) {
        return 'dot-gray';
    }

    public selectOrder(order: SupplierOrder) {
        this.selectedOrder = order;

        this.showHideOrderMarker(order, true);

        if (this.selectedOrder.deliveryPopup) {
            this.selectedOrder.deliveryMarker.setPopup(this.selectedOrder.deliveryPopup);
            this.selectedOrder.deliveryPopup.addTo(this.map);
        }

        this.supplierTrackinngService
            .getOrderHistory(this.selectedOrder.orderID)
            .subscribe((data) => {
                this.selectedOrder.history = data.body;
            });

        if (order.deliveryAddress && order.deliveryAddress.lat)
            this.map.flyTo({
                center: [order.deliveryAddress.long, order.deliveryAddress.lat],
                zoom: 14,
                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
    }

    public showWorkerDetails(worker: SupplierWorker, zoomTo: boolean) {
        if (this.selectedWorker && this.selectedWorker.marker) {
            this.selectedWorker.popup.remove();
        }

        this.selectedOrder = null;
        this.showHideOrderMarkers(false);

        if (this.selectedWorker == worker) {
            this.filteredOrderList = this.curOrderList;
            this.selectedWorker = null;
            this.showTracks([]);
            return;
        } else {
            this.filteredOrderList = this.curOrderList.filter((order) =>
                order.supplierTaskList.some((task) => task.userID == worker.userID)
            );
            //debugger;
            this.showHideOrderMarkers(true);
            this.selectedWorker = worker;
        }

        if (worker.marker) {
            worker.marker.setPopup(worker.popup);
            worker.popup.addTo(this.map);

            if (zoomTo) {
                this.map.flyTo({
                    center: [
                        worker.marker.getLngLat().lng,
                        worker.marker.getLngLat().lat,
                    ],
                    zoom: 14,
                    essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                });
            }

            this.supplierTrackinngService
                .getWorkerDetails(worker.userID, this.date)
                .subscribe((workerDetail) => {
                    let w = workerDetail.body;
                    worker.trackList = w.trackList;
                    worker.workTimeList = w.workTimeList;
                    this.showTracks(worker.trackList);
                }); //gettracks
        }
    }

    showTracks(trackList: Array<SupplierTrack>) {
        this.curTrackList.forEach((tr) => {
            this.map.removeLayer(tr.supplierTrackID);
            this.map.removeSource(tr.supplierTrackID);
        });
        this.curTrackList = [];

        trackList.forEach((tr) => {
            this.showTrack(tr);
            this.curTrackList.push(tr);
        });
    }

    public onSupplierSelect(supplier: ISelectOption): void {
        //let lastMarker: mapboxgl.Marker = null;
        //const bounds = new mapboxgl.LngLatBounds();
        //this.curTrackList.forEach(tr => {
        //  if (tr.marker) {
        //    let popup = tr.marker.getPopup();
        //    if (tr.supplierID != supplier.id) {
        //      if (popup && popup.isOpen()) popup.remove();
        //    } else {
        //      popup = !popup ? this.createPopup(tr) : popup;
        //      if (!popup.isOpen()) tr.marker.setPopup(popup);
        //      else {
        //        popup.remove();
        //      }
        //      popup.addTo(this.map);
        //      bounds.extend([tr.marker.getLngLat().lng, tr.marker.getLngLat().lat]);
        //    }
        //  }
        //});
        //if (!bounds.isEmpty()) {
        //  this.map.fitBounds(bounds);
        //}
    }
}
