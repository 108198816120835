<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-4">
                <img
                    *ngIf="orderItem.itemThumbnailSrc !== null; else noImage"
                    [src]="orderItem.itemThumbnailSrc"
                    [alt]="orderItem.orderItemName"
                />
                <ng-template #noImage>
                    <div class="placeholder">
                        <span>{{ 'common.no-photo' | translate }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="col-8">
                <app-order-item-details
                    [orderItem]="orderItem"
                    [isMainPageCollecting]="false"
                ></app-order-item-details>
            </div>
        </div>
    </div>

    <div class="btn-toolbar justify-content-between" *ngIf="canChoose">
        <button class="btn btn-success btn-lg btn-block mt-2" (click)="onProductClick()">
            <span class="font-weight-bold text-smaller">{{
                'collecting.add-cart' | translate
            }}</span>
        </button>
    </div>
</div>
