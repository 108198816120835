import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

export type httpGenericMethod = <T>(
    httpRequest: Observable<HttpResponse<T>>,
    mappableType: T
) => Observable<HttpResponse<T>>;

export const httpMappedRequest: httpGenericMethod = <T>(
    httpRequest: Observable<HttpResponse<T>>,
    mappableType: T
): Observable<HttpResponse<T>> => {
    return httpRequest.pipe(
        map((response) => {
            if (response.status !== undefined && response.status === 200) {
                response.clone({ body: Object.assign(mappableType, response.body) });
            }
            return response;
        })
    );
};
