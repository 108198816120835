import { Inject, Injectable } from '@angular/core';
import { IOrderReturnService } from './iorder-return.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderReturnItemDto } from '../../dtos/order-return-item-dto';
import { OrderReturnItem } from '../../models/order-return-item';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { StartOrderReturn } from '../../dtos/start-order-return';

@Injectable()
export class OrderReturnService implements IOrderReturnService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    startReturnOrderItems(
        startOrderReturn: StartOrderReturn
    ): Observable<HttpResponse<OrderReturnItem[]>> {
        return this._apiClient.postWithResponse<OrderReturnItem[]>(
            'orderreturn/start-return-order-items',
            startOrderReturn
        );
    }

    returnOrderItems(
        orderID: number,
        OrderReturnItemDtoList: OrderReturnItemDto[],
        Comment: string,
        DeliveryTaskID: string
    ): Observable<HttpResponse<OrderReturnItem[]>> {
        return this._apiClient.postWithResponse<OrderReturnItem[]>(
            'orderreturn/return-order-items',
            { orderID, OrderReturnItemDtoList, Comment, DeliveryTaskID }
        );
    }

    refundOrderItems(
        orderID: number,
        DeliveryTaskID: string
    ): Observable<HttpResponse<OrderReturnItem[]>> {
        return this._apiClient.postWithResponse<OrderReturnItem[]>(
            'orderreturn/refund-order-items',
            { orderID, DeliveryTaskID }
        );
    }

    public getReturnPhotoUrl(userID: string): Observable<string> {
        return this._apiClient.get<string>('orderreturn/return-photo-url/' + userID, {
            responseType: 'text',
        });
    }
}
