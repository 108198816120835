import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
    BarcodeReaderState,
    ColorClass,
    FlashMessageService,
    ModalService,
    OfflineProduct,
} from 'shared';
import { CollectingStatesService } from '../../collecting-states.service';
import { skip, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'app-barcode-reader-modal',
    templateUrl: './barcode-reader-modal.component.html',
    styleUrls: ['./barcode-reader-modal.component.scss'],
})
export class BarcodeReaderModalComponent implements OnInit, OnDestroy {
    @Output() onBarcodeRead = new EventEmitter<string>();
    private _destroy$: ReplaySubject<boolean>;
    public prod: OfflineProduct;
    public ColorClass = ColorClass;
    public barcodeReaderState: BarcodeReaderState;

    constructor(
        private _flashMessageService: FlashMessageService,
        private _states: CollectingStatesService,
        private _modalService: ModalService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
        this.barcodeReaderState = BarcodeReaderState.StandBy;
    }

    ngOnInit(): void {
        this._states.isBarcodeReaderModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                this.setBarcodeReaderState(isVisible);
                this._modalService.setModalVisibility(isVisible, 'barcode-reader-modal');
            });
    }

    public hideModal(): void {
        this._modalService.setModalVisibility(false, 'barcode-reader-modal');
    }

    public showErrorMessage(message: string): void {
        this._flashMessageService.showMessage(
            'Error',
            message,
            3000,
            ColorClass.Danger,
            'barcode-modal-messages'
        );
    }

    public showMessage(message: string): void {
        this._flashMessageService.showMessage(
            null,
            message,
            3000,
            ColorClass.Warning,
            'barcode-modal-messages'
        );
    }

    public onBarcodeFound(gtin: string): void {
        this.onBarcodeRead.emit(gtin);
    }

    public onClose(): void {
        this.setBarcodeReaderState(false);
    }

    private setBarcodeReaderState(isVisible: boolean): void {
        if (isVisible) {
            this.barcodeReaderState = BarcodeReaderState.Reading;
        } else {
            this.barcodeReaderState = BarcodeReaderState.StandBy;
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
