<lib-modal
    [title]="'collecting.product-identify-title'"
    [componentID]="'product-identify-modal'"
    [bgColorClass]="ColorClass.Success"
>
    <p>{{ 'collecting.product-identify-text' | translate }}</p>
    <div class="btn-toolbar justify-content-between">
        <div class="btn-image-design">
            <img src="assets/images/barcode.png" (click)="showBarcodeReaderModal()" />
            <div class="text-center">
                {{ 'common.barcode' | translate }}
            </div>
        </div>
        <div class="btn-image-design">
            <img
                src="assets/images/example_product_barcode.png"
                (click)="showManualSKUCheckModal()"
            />
            <div class="text-center">
                {{ 'collecting.type-sku' | translate }}
            </div>
        </div>
    </div>
</lib-modal>
