import { OrderItemDepositProduct } from '../models/order-item-deposit-product';
import { OrderItemState } from '../types/order-item-state';

export interface ICreateOrderItem {
    orderID: number;
    productProviderID: number;
    name: string;
    quantity: number;
    isCollecting: boolean;
    unit: string;
    unitPrice: number;
    vat: number;
    sku: string;
    orderItemStateID?: OrderItemState;
    offlineProductID?: number;
    providerID?: number;
    isWerwProduct: boolean;
    isBulk: boolean;
    optionalDepositProductQuantity?: number;
    orderItemDepositProductList: OrderItemDepositProduct[];
}
