<h1>Tasks</h1>
<div [ngClass]="{ 'list-container': true, visible: true }">
    <lib-visible-column-dropdown
        [columns]="columns"
        [section]="'tasks'"
        (onColumnSelected)="setColumnsVisibility($event)"
        [class]="'float-left'"
    >
    </lib-visible-column-dropdown>
    <lib-set-user-setting-btn
        [btnClass]="'btn btn-sm btn-secondary float-left ml-2'"
        [section]="'tasks'"
        [key]="'visibleColumns'"
        [value]="selectedColumnKeys"
    >
        {{ 'common.set-as-default' | translate }}
    </lib-set-user-setting-btn>
    <button class="btn btn-sm btn-primary ml-2" (click)="handleRefresh()">
        {{ 'common.refresh-tasks' | translate }}
    </button>
    <button class="btn btn-sm btn-info ml-2" (click)="expiredCollecting()">
        {{ 'common.expired-collecting' | translate }}
    </button>
    <button class="btn btn-sm btn-success ml-2" (click)="expiredDelivering()">
        {{ 'common.expired-delivering' | translate }}
    </button>
    <table class="table table-hover table-responsive">
        <thead
            *ngIf="areColumnsReady"
            (onFilter)="listService.onColumnFilter($event)"
            (onSort)="listService.onColumnSort($event)"
            [hasBatchColumn]="true"
            (onCheckAll)="checkAllTask($event)"
            [columns]="columns"
            [ngClass]="{ 'thead-light': true, visible: tasksService.tasks | async }"
            interactive-table-header
        ></thead>
        <tbody *ngIf="(tasksService.tasks | async).length > 0; else noresults">
            <tr class="batch-action-bar" app-batch-task-actions></tr>
            <ng-container *ngFor="let task of tasksService.tasks | async; index as idx">
                <tr
                    task-list-item
                    [visibleColumnIndexes]="visibleColumnIndexes"
                    [task]="task"
                    [index]="idx"
                ></tr>
            </ng-container>
        </tbody>
        <ng-template #noresults>
            <tbody>
                <tr>
                    <td colspan="16">
                        No results found with the given filter parameters.
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
    <div class="d-flex justify-content-center">
        <lib-page-size-dropdown
            class="page-size-selector"
            (onPageSizeChanged)="listService.setPageSize($event)"
        ></lib-page-size-dropdown>
        <ngb-pagination
            [(page)]="listService.currentPaginatorPage"
            [pageSize]="(listService.listProperties | async).pageSize"
            [collectionSize]="(listService.listProperties | async).rowCount"
            [boundaryLinks]="true"
            [rotate]="true"
            [maxSize]="20"
            (pageChange)="listService.onPageChange()"
        >
        </ngb-pagination>
    </div>
</div>
<app-user-modal></app-user-modal>
<app-order-details-modal></app-order-details-modal>
<app-task-state-modal></app-task-state-modal>
