import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [RegistrationComponent],
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class RegistrationModule {}
