<div class="form-group quantity-container">
    <div class="d-flex align-items-center mr-1">
        <h5>{{ 'collecting.quantity-2' | translate | uppercase }}:</h5>
    </div>
    <div class="d-flex">
        <button type="button" class="btn btn btn-secondary" (click)="onRemove()">
            <i class="fa fa-minus"></i>
        </button>
        <div class="input-group quantity-textbox">
            <input
                [(ngModel)]="quantity"
                inputmode="decimal"
                id="quantity"
                class="form-control"
                placeholder="Quantity"
                (change)="onChange(quantity)"
                required
                autofocus
            />
            <div class="input-group-append">
                <div class="input-group-text">
                    {{ 'collecting.' + orderItem.unit | translate }}
                </div>
            </div>
        </div>
        <button type="button" class="btn btn btn-secondary plus-button" (click)="onAdd()">
            <i class="fa fa-plus"></i>
        </button>
    </div>
</div>
