import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, skip, takeUntil } from 'rxjs/operators';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../services/authentication/iauthentication.service';
import { HttpErrorResponse } from '@angular/common/http';

import { LoginComponent } from './login.component';
import { ProgressLineService } from '../progress-line/progress-line.service';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessageService } from '../flash-message/flash-message.service';
import { ColorClass } from '../../types/color-class';
import { IonToasterService } from '../../services/ionic/ion.toaster.service';
import { IonLoaderService } from '../../services/ionic/ion.loader.service';
import { ReplaySubject } from 'rxjs';
import { StringHelper } from '../../helpers/string.helper';

@Component({
    selector: 'lib-login-ionic',
    templateUrl: './login.ionic.component.html',
    styleUrls: ['./login.ionic.component.scss'],
})
export class LoginIonicComponent extends LoginComponent implements OnInit, OnDestroy {
    private _destroy$ = new ReplaySubject<boolean>(1);
    public loginName: string;
    public passwordName: string;

    constructor(
        @Inject(AUTH_SERVICE_IMPL) _authService: IAuthenticationService,
        _formBuilder: FormBuilder,
        _route: ActivatedRoute,
        _router: Router,
        _progressLineService: ProgressLineService,
        public flashMessageService: FlashMessageService,
        private _toasterService: IonToasterService
    ) {
        super(
            _authService,
            _formBuilder,
            _route,
            _router,
            _progressLineService,
            flashMessageService
        );

        this.loginName = 'new-' + StringHelper.getTicks();
        this.passwordName = this.loginName;
        super.rememberMe = true;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.flashMessageService.type
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((type) => {
                if (type === ColorClass.Danger) {
                    this._toasterService.error(this.flashMessageService.lastMessage());
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
