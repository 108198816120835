<div class="container" *appHasRoles="['administrator']">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
            <div class="card">
                <div class="card-header bg-secondary">
                    Order Invoice and Stock Correction
                </div>
                <div class="card-body order-delivery-details">
                    <ng-container *ngIf="order">
                        <p>
                            <span>Order Date: </span>
                            <strong>{{
                                order.created | amDateFormat : deliveryDateFormat
                            }}</strong>
                            <br />
                            <span><strong>Invoice Date:</strong></span>
                        </p>
                        <div class="row">
                            <div class="col-8">
                                <input
                                    class="form-control"
                                    type="datetime-local"
                                    [value]="invoiceDate"
                                    (change)="invoiceDate = $event.target.value"
                                />
                                &nbsp;
                            </div>
                        </div>
                        <p>
                            <span><strong>Invoice Number:</strong></span
                            ><br />
                        </p>
                        <div class="row">
                            <div class="col-8">
                                <input
                                    class="form-control"
                                    type="text"
                                    [value]="invoiceNumber"
                                    (change)="invoiceNumber = $event.target.value"
                                />
                                &nbsp;
                            </div>
                        </div>
                    </ng-container>
                    <hr />
                    <ng-container *ngIf="order">
                        <button class="btn btn-danger w-100" (click)="correctStock()">
                            <div
                                *ngIf="isLoading; else stockCorrectionButton"
                                class="w-100 p-6 text-center"
                            >
                                <i
                                    class="fa fa-spinner fa-spin loading-icon-custom"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <ng-template #stockCorrectionButton>
                                <i class="fa fa-edit"></i> Correct Stock</ng-template
                            >
                        </button>
                        <button
                            class="btn btn-danger w-100 mt-1"
                            (click)="correctInvoiceAndStock()"
                        >
                            <div
                                *ngIf="isLoading; else invoiceAndStockCorrectionButton"
                                class="w-100 p-6 text-center"
                            >
                                <i
                                    class="fa fa-spinner fa-spin loading-icon-custom"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <ng-template #invoiceAndStockCorrectionButton>
                                <i class="fa fa-edit"></i>
                                Correct Collecting Invoice & Stock</ng-template
                            >
                        </button>
                        <button
                            class="btn btn-danger w-100 mt-1"
                            (click)="generateInvoiceJpl()"
                        >
                            <div
                                *ngIf="isLoading; else generateJplButton"
                                class="w-100 p-6 text-center"
                            >
                                <i
                                    class="fa fa-spinner fa-spin loading-icon-custom"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <ng-template #generateJplButton>
                                <i class="fa fa-edit"></i>
                                Generate Invoice Jpl</ng-template
                            >
                        </button>
                        <button
                            class="btn btn-danger w-100 mt-1"
                            (click)="createCorrectionInvoice()"
                        >
                            <div
                                *ngIf="isLoading; else createCorrectionInvoiceButton"
                                class="w-100 p-6 text-center"
                            >
                                <i
                                    class="fa fa-spinner fa-spin loading-icon-custom"
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <ng-template #createCorrectionInvoiceButton>
                                <i class="fa fa-edit"></i>
                                Create Delivery Correction Invoice</ng-template
                            >
                        </button>
                        <div
                            *ngIf="apiError"
                            class="w-100 p-6 text-center"
                            style="color: red; font-size: large; font-weight: 800"
                        >
                            {{ apiError }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
