import { AppEnvironment } from './lib/types/app-environment';
import { ServiceBaseService } from './lib/services/service-base.service';

export class AppSettings {
    public static settings = {
        default: {
            backendBaseURL: 'https://roksh-backend-master.roksh.com/api',
            // Local test: localhost:7237 | Staging test: weshop-logservice-test.azurewebsites.net | Production: logservice.roksh.com
            loggerBaseURL: ServiceBaseService.getLoggerBaseUrl,
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
            realtimeUrl: 'https://roksh-backend-master.roksh.com',
            defaultLocale: 'hu',
            defaultCulture: 'hu-HU',
            currencyRoundDecimalNumber: '0',
            currency: 'HUF',
            decimalDelimiter: '.',
            sumUpAffiliateKey: '71a60f22-2e5e-484e-bf70-0b693062bfc2', // HU sumup
        },
        providertest: {
            backendBaseURL: 'https://provider-roksh-backend.roksh.com/api',
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
            realtimeUrl: 'https://provider-roksh-backend.roksh.com',
        },
        humaster: {
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
        },
        atmaster: {
            backendBaseURL: 'https://at-roksh-backend-master.roksh.at/api',
            mediaServiceUrl:
                'https://atproductimagesmaster.blob.core.windows.net/interface',
            realtimeUrl: 'https://at-roksh-backend-master.roksh.at',
            defaultLocale: 'de',
            defaultCulture: 'de',
            currencyRoundDecimalNumber: '2',
            currency: 'EUR',
            decimalDelimiter: ',',
            sumUpAffiliateKey: '1abe8ee9-fa11-4c08-b84e-1e9c3db9db6d', // AT sumup
        },
        hustaging: {
            backendBaseURL: 'https://roksh-backend-staging.roksh.com/api',
            realtimeUrl: 'https://roksh-backend-staging.roksh.com',
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
        },
        atstaging: {
            backendBaseURL: 'https://roksh-backend-staging.roksh.at/api',
            realtimeUrl: 'https://roksh-backend-staging.roksh.at',
            mediaServiceUrl:
                'https://atproductimagesmaster.blob.core.windows.net/interface',
            defaultLocale: 'de',
            defaultCulture: 'de',
            currencyRoundDecimalNumber: '2',
            currency: 'EUR',
            decimalDelimiter: ',',
            sumUpAffiliateKey: '1abe8ee9-fa11-4c08-b84e-1e9c3db9db6d', // AT sumup
        },
        huproduction: {
            backendBaseURL: 'https://roksh-backend.roksh.com/api',
            realtimeUrl: 'https://roksh-backend.roksh.com',
            mediaServiceUrl: 'https://huproductimages.blob.core.windows.net/interface',
        },
        huproductionb2b: {
            backendBaseURL: 'https://roksh-backend-b2b.roksh.com/api',
            realtimeUrl: 'https://roksh-backend-b2b.roksh.com',
            mediaServiceUrl: 'https://huproductimagesb2b.blob.core.windows.net/interface',
        },
        atproduction: {
            mediaServiceUrl: 'https://atproductimages.blob.core.windows.net/interface',
            currencyRoundDecimalNumber: '2',
            currency: 'EUR',
            backendBaseURL: 'https://roksh-backend.roksh.at/api',
            realtimeUrl: 'https://roksh-backend.roksh.at',
            defaultLocale: 'de',
            defaultCulture: 'de',
            decimalDelimiter: ',',
            sumUpAffiliateKey: '1abe8ee9-fa11-4c08-b84e-1e9c3db9db6d', // AT sumup
        },
        local: {
            backendBaseURL: 'http://localhost:5009/api',
            realtimeUrl: 'http://localhost:5009',
            mediaServiceUrl:
                'https://atproductimagesmaster.blob.core.windows.net/interface',
            defaultLocale: 'hu',
            defaultCulture: 'hu-HU',
        },
        atlocal: {
            backendBaseURL: 'http://localhost:5009/api',
            realtimeUrl: 'http://localhost:5009',
            mediaServiceUrl:
                'https://atproductimagesmaster.blob.core.windows.net/interface',
            defaultLocale: 'de',
            defaultCulture: 'de',
            currencyRoundDecimalNumber: '2',
            currency: 'EUR',
            decimalDelimiter: ',',
        },
        intranet: {
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
            backendBaseURL: AppSettings.getCurrentBaseURL(5009) + '/api',
            realtimeUrl: AppSettings.getCurrentBaseURL(5009),
        },
        atintranet: {
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
            backendBaseURL: AppSettings.getCurrentBaseURL(5008) + '/api',
            realtimeUrl: AppSettings.getCurrentBaseURL(5008),
            defaultLocale: 'de',
            defaultCulture: 'de',
            currencyRoundDecimalNumber: '2',
            currency: 'EUR',
            decimalDelimiter: ',',
        },
        mobildevstaging: {
            backendBaseURL: 'https://delivery-manager-roksh01-api.applications.hu/api',
            realtimeUrl: 'https://delivery-manager-roksh01-api.applications.hu',
            mediaServiceUrl:
                'https://productimagesmaster.blob.core.windows.net/interface',
        },
    };

    public static get(key: string): string {
        const envSettings =
            AppSettings.settings[
                AppEnvironment[AppSettings.getEnvironment()]?.toLowerCase()
            ];
        if (envSettings !== undefined && envSettings[key] !== undefined) {
            return envSettings[key];
        } else if (AppSettings.settings.default[key] !== undefined) {
            return AppSettings.settings.default[key];
        }
        return null;
    }

    public static getEnvironment(): AppEnvironment {
        let env;
        switch (true) {
            case window.location.href.includes('localhost'):
                switch (window.location.port) {
                    case '4200':
                    case '5010':
                    case '8100':
                        env = AppEnvironment.Local;
                        break;
                    case '4201':
                    case '5011':
                    case '5012':
                        env = AppEnvironment.AtLocal;
                        break;
                }
                break;
            case window.location.href.includes('manager-master.roksh.com'):
                env = AppEnvironment.HuMaster;
                break;
            case window.location.href.includes('manager-master.roksh.at') ||
                window.location.href.includes('managermasterat'):
                env = AppEnvironment.AtMaster;
                break;
            case window.location.href.includes('manager-staging.roksh.com'):
                env = AppEnvironment.HuStaging;
                break;
            case window.location.href.includes('manager-staging.roksh.at'):
                env = AppEnvironment.AtStaging;
                break;
            case window.location.href.includes('manager-edu'):
                env = AppEnvironment.ProviderTest;
                break;
            case window.location.href.includes('192.168.'):
                switch (window.location.port) {
                    case '4200':
                    case '5010':
                        env = AppEnvironment.Intranet;
                        break;
                    case '4201':
                    case '5011':
                        env = AppEnvironment.AtIntranet;
                        break;
                }
                break;
            case window.location.href.includes('manager.roksh.com'):
                env = AppEnvironment.HuProduction;
                break;
            case window.location.href.includes('manager.roksh.at'):
                env = AppEnvironment.AtProduction;
                break;
            case window.location.href.includes('b2b.roksh.com'):
                env = AppEnvironment.HuProductionB2B;
                break;
            case window.location.href.includes('https://delivery-manager-roksh01.applications.hu'):
                env = AppEnvironment.MobilDevStaging;
                break;
            default:
                env = AppEnvironment.HuProduction;
                break;
        }
        return env;
    }

    public static getCurrentBaseURL(port: number): string {
        return location.protocol + '//' + location.hostname + ':' + port;
    }
}
