import { Component, Inject, Input, OnInit } from '@angular/core';
import { DeliveryTaskState } from 'shared';
import { ITasksService, TASKS_SERVICE_IMPL } from 'shared';
import { Router } from '@angular/router';
import {
    CollectingTaskListItem,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import { GEOLOCATION_SERVICE_IMPL, IGeolocationService } from 'shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { CheckProviderShop } from 'shared';
import { LatLng } from 'shared';
import { first, skip, take } from 'rxjs/operators';
import { ModalService } from 'shared';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';

@Component({
    selector: 'app-collecting-task-button',
    templateUrl: './collecting-task-button.component.html',
    styleUrls: ['./collecting-task-button.component.scss'],
})
export class CollectingTaskButtonComponent implements OnInit {
    @Input() task: CollectingTaskListItem;
    public buttonClasses = 'btn btn-sm ';
    public buttonText: string;
    public onClick: Function;
    public isWaitingForAccept: boolean;
    private _isSupplierInProviderShop: BehaviorSubject<boolean>;
    private lastLatLonData = new LatLng(0, 0);
    private get isSupplierAtProviderShop(): Observable<boolean> {
        return this._isSupplierInProviderShop.asObservable();
    }

    constructor(
        @Inject(TASKS_SERVICE_IMPL) private _tasksService: ITasksService,
        @Inject(GEOLOCATION_SERVICE_IMPL)
        private _geolocationService: IGeolocationService,
        private _translateService: TranslateService,
        private _myTasksService: MyTasksService,
        private _modalService: ModalService,
        private snackBar: MatSnackBar,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _router: Router,
        private _IosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        private isWrapping: IsWrappingService
    ) {
        this.buttonClasses = 'btn btn btn-block btn-warning';
        this.isWaitingForAccept = false;
        this._isSupplierInProviderShop = new BehaviorSubject<boolean>(false);
    }

    ngOnInit() {
        if (this.task.state === DeliveryTaskState[DeliveryTaskState.WaitingForAccept]) {
            this.isWaitingForAccept = true;
        } else {
            this.isWaitingForAccept = false;
            this.initCollectingButton();
        }
    }

    public acceptAssignment() {
        this._tasksService
            .checkIfTheUserIsTheSame(this.task.deliveryTaskID)
            .subscribe((isTheSameUser) => {
                if (isTheSameUser) {
                    this._tasksService
                        .acceptCollectingTask(this.task.deliveryTaskID)
                        .subscribe((response) => {
                            if (response.status === 200) {
                                this.isWaitingForAccept = false;
                                this.task.state =
                                    DeliveryTaskState[DeliveryTaskState.ToDo];
                                this.initCollectingButton();
                            }
                        });
                } else {
                    this.snackBar.open(
                        this._translateService.instant(
                            'messages.the-user-is-not-the-same'
                        ),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 10000,
                            panelClass: ['error-snackbar'],
                        }
                    );
                }
            });
    }

    public rejectAssignment() {
        this._tasksService
            .checkIfTheUserIsTheSame(this.task.deliveryTaskID)
            .subscribe((isTheSameUser) => {
                if (isTheSameUser) {
                    this._tasksService
                        .rejectCollectingTask(this.task.deliveryTaskID)
                        .subscribe((response) => {
                            if (response.status === 200) {
                                this._myTasksService.removeTask(this.task.deliveryTaskID);
                                this._myTasksService.getMyTasks();
                            }
                        });
                } else {
                    this.snackBar.open(
                        this._translateService.instant(
                            'messages.the-user-is-not-the-same'
                        ),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 10000,
                            panelClass: ['error-snackbar'],
                        }
                    );
                }
            });
    }

    public startCollecting() {
        this.checkSupplierPosition();
        if (window.ReactNativeWebView) {
            this._IosAndAndroidNatvieFunctionsService.currentLatLonData
                .pipe(take(2))
                .subscribe((response) => {
                    if (response.taskID === this.task.deliveryTaskID) {
                        const latLonData = response.message;
                        this.lastLatLonData = {
                            lat: latLonData?.coords?.latitude,
                            lng: latLonData?.coords?.longitude,
                        };
                        setTimeout(() => {
                            this.checkProviderShopDistance(this.lastLatLonData).subscribe(
                                (response) => {
                                    if (response.status === 200) {
                                        this.isSupplierAtProviderShop
                                            .pipe(skip(1))
                                            .subscribe((isAtShop) => {
                                                if (isAtShop) {
                                                    this._tasksService
                                                        .startCollectingTask(
                                                            this.task.deliveryTaskID
                                                        )
                                                        .subscribe((response) => {
                                                            if (response.status === 200) {
                                                                this.goToCollecting();
                                                            }
                                                        });
                                                } else {
                                                    this._modalService.setModalVisibility(
                                                        true,
                                                        'out-of-shop-location-modal'
                                                    );
                                                }
                                            });
                                        this._isSupplierInProviderShop.next(
                                            response.body
                                        );
                                    }
                                }
                            );
                        }, 500);
                    }
                });
        } else {
            this.isSupplierAtProviderShop.pipe(skip(1)).subscribe((isAtShop) => {
                if (isAtShop) {
                    this._tasksService
                        .startCollectingTask(this.task.deliveryTaskID)
                        .subscribe((response) => {
                            if (response.status === 200) {
                                this.goToCollecting();
                            }
                        });
                } else {
                    this._modalService.setModalVisibility(
                        true,
                        'out-of-shop-location-modal'
                    );
                }
            });
        }
    }

    public retryCollecting() {
        this.checkSupplierPosition();
        if (window.ReactNativeWebView) {
            this._IosAndAndroidNatvieFunctionsService.currentLatLonData
                .pipe(take(2))
                .subscribe((response) => {
                    if (response.taskID === this.task.deliveryTaskID) {
                        const latLonData = response.message;
                        this.lastLatLonData = {
                            lat: latLonData?.coords?.latitude,
                            lng: latLonData?.coords?.longitude,
                        };
                        setTimeout(() => {
                            this.checkProviderShopDistance(this.lastLatLonData).subscribe(
                                (response) => {
                                    if (response.status === 200) {
                                        this.isSupplierAtProviderShop
                                            .pipe(skip(1))
                                            .subscribe((isAtShop) => {
                                                if (isAtShop) {
                                                    this._tasksService
                                                        .startCollectingTask(
                                                            this.task.deliveryTaskID
                                                        )
                                                        .subscribe((response) => {
                                                            if (response.status === 200) {
                                                                this.goToCollecting();
                                                            }
                                                        });
                                                } else {
                                                    this._modalService.setModalVisibility(
                                                        true,
                                                        'out-of-shop-location-modal'
                                                    );
                                                }
                                            });
                                        this._isSupplierInProviderShop.next(
                                            response.body
                                        );
                                    }
                                }
                            );
                        }, 500);
                    }
                });
        } else {
            this.isSupplierAtProviderShop.pipe(skip(1)).subscribe((isAtShop) => {
                if (isAtShop) {
                    this._tasksService
                        .retryCollectingTask(this.task.deliveryTaskID)
                        .subscribe((response) => {
                            if (response.status === 200) {
                                this.goToCollecting();
                            }
                        });
                } else {
                    this._modalService.setModalVisibility(
                        true,
                        'out-of-shop-location-modal'
                    );
                }
            });
        }
    }

    private initCollectingButton() {
        let progress: string;
        switch (this.task.state) {
            case DeliveryTaskState[DeliveryTaskState.ToDo]:
                progress = 'start';
                this.onClick = this.startCollecting;
                break;
            case DeliveryTaskState[DeliveryTaskState.InProgress]:
                progress = 'continue';
                this.onClick = () => this.goToCollecting();
                break;
            case DeliveryTaskState[DeliveryTaskState.Failed]:
                progress = 'retry';
                this.onClick = this.retryCollecting;
                break;
        }
        this.buttonText = progress + '-collecting';
    }

    private checkSupplierPosition(): void {
        let supplierPosition = new LatLng(0, 0);
        if (window.ReactNativeWebView) {
            this._IosAndAndroidNatvieFunctionsService.getCurrentLocation(
                this.task.deliveryTaskID,
                'CollectingTaskButtonComponent'
            );
        } else if (
            this._IosAndAndroidNatvieFunctionsService.osType === 'Android' &&
            this.isWrapping.isWrapping()
        ) {
            const wrapperLastLocationData = JSON.parse(
                this._IosAndAndroidNatvieFunctionsService.getCurrentLocation()
            );

            supplierPosition.lng = wrapperLastLocationData?.lon;
            supplierPosition.lat = wrapperLastLocationData?.lat;
            setTimeout(() => {
                this.checkProviderShopDistance(supplierPosition).subscribe((response) => {
                    if (response.status === 200) {
                        this._isSupplierInProviderShop.next(response.body);
                    }
                });
            }, 500);
        } else {
            this._geolocationService
                .getCurrentPosition()
                .subscribe((positionResponse) => {
                    if (positionResponse.coords !== undefined) {
                        supplierPosition = new LatLng(
                            positionResponse.coords.latitude,
                            positionResponse.coords.longitude
                        );
                        this.checkProviderShopDistance(supplierPosition).subscribe(
                            (response) => {
                                if (response.status === 200) {
                                    this._isSupplierInProviderShop.next(response.body);
                                }
                            }
                        );
                    }
                });
        }
    }

    private checkProviderShopDistance(
        supplierPosition: LatLng
    ): Observable<HttpResponse<boolean>> {
        const requestBody = new CheckProviderShop({
            position: supplierPosition,
            orderID: this.task.orderID,
        } as CheckProviderShop);
        return this._geolocationService.checkProviderShop(requestBody);
    }

    private goToCollecting(): void {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.MyTasksContinueCollectingButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.task);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.task.orderID;
        orderStateEventLog.orderCode = this.task.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._router.navigate([
            `collecting/${this.task.deliveryTaskID}/${this.task.orderID}`,
        ]);
    }
}
