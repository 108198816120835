import { Component, Input, OnInit } from '@angular/core';
import { ColorClass } from 'shared';

@Component({
    selector: 'app-dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit {
    constructor() {}

    @Input() title: string;
    @Input() text: string;
    @Input() icon: string;
    @Input() background: ColorClass;
    @Input() textColor = 'white';
    @Input() linkTo: string;

    ngOnInit() {}

    public navigateTo(path: string) {}
}
