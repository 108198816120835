import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TourPlannerConfiguration } from '../../dtos/tour-planner-configuration';
import { ITourPlannerService } from './itourplanner.service';
import { TourPlannerDailyPlan } from '../../dtos/tour-planner-daily-plan';
import { timeout } from 'rxjs/operators';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';

@Injectable()
export class TourPlannerService implements ITourPlannerService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getSavedDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerDailyPlan> {
        return this._apiClient
            .get<TourPlannerDailyPlan>('tourplanner/get-saved-daily-plan', {
                params: this.createHttpParams(tourPlannerConfiguration),
            })
            .pipe(timeout(2000000));
    }

    public corrigateSavedDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerDailyPlan> {
        return this._apiClient
            .get<TourPlannerDailyPlan>('tourplanner/corrigate-saved-daily-plan', {
                params: this.createHttpParams(tourPlannerConfiguration),
            })
            .pipe(timeout(2000000));
    }

    public planDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerDailyPlan> {
        return this._apiClient
            .get<TourPlannerDailyPlan>('tourplanner/plan-daily-plan', {
                params: this.createHttpParams(tourPlannerConfiguration),
            })
            .pipe(timeout(2000000));
    }

    public acceptSavedDailyPlan(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<HttpResponse<void>> {
        return this._apiClient
            .postWithResponse<void>(
                'tourplanner/accept-saved-daily-plan',
                tourPlannerConfiguration
            )
            .pipe(timeout(2000000));
    }

    public saveSupplierRegionTourPlannerParameters(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<HttpResponse<void>> {
        return this._apiClient
            .postWithResponse<void>(
                'tourplanner/save-supplier-region-tour-planner-parameters',
                tourPlannerConfiguration
            )
            .pipe(timeout(2000000));
    }

    public getSupplierRegionTourPlannerParameters(
        tourPlannerConfiguration: TourPlannerConfiguration
    ): Observable<TourPlannerConfiguration> {
        return this._apiClient
            .get<TourPlannerConfiguration>(
                'tourplanner/get-supplier-region-tour-planner-parameters',
                {
                    params: this.createHttpParams(tourPlannerConfiguration),
                }
            )
            .pipe(timeout(2000000));
    }

    private createHttpParams(configuration: TourPlannerConfiguration): HttpParams {
        let httpParams = new HttpParams();
        Object.keys(configuration).forEach((key) => {
            httpParams = httpParams.append(key, configuration[key]);
        });
        return httpParams;
    }
}
