import { OrderItemDepositProduct } from '../models/order-item-deposit-product';
import { OrderReplacementItemDepositProduct } from '../models/order-replacement-item-deposit-product';

export interface ICreateReplacementItem {
    orderItemID: number;
    replacementItemName: string;
    replacementItemQuantity: number;
    replacementItemUnitPrice: number;
    vat?: number;
    gtin?: string;
    sku?: string;
    offlineProductID?: number;
    unit: string;
    providerID?: number;
    isWerwProduct: boolean;
    isBulk: boolean;
    isCollecting: boolean;
    optionalDepositProductQuantity: number;
    orderItemDepositProductList: OrderItemDepositProduct[];
    orderReplacementItemDepositProductList: OrderReplacementItemDepositProduct[];
}
