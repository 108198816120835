import { AppSettings } from '../../appsettings';
import { Pipe, PipeTransform } from '@angular/core';
import { convertDecimalDelimiter } from '../helpers/decimal.helper';

@Pipe({
    name: 'decimal',
})
export class DecimalPipe implements PipeTransform {
    public transform(value: number): string {
        return convertDecimalDelimiter(value);
    }
}
