<div [class]="getItemClass()">
    <div class="card-header">
        <app-state-badge [state]="getTaskLabel() | humancase"></app-state-badge>
    </div>
    <div class="card-body order-details">
        <table>
            <tr>
                <td>Assigned to:</td>
                <td>
                    <strong>{{ taskInfo.username || 'Not assigned' }}</strong>
                </td>
            </tr>
            <tr>
                <td>Notes:</td>
                <td>
                    {{ taskInfo.notes }}
                </td>
            </tr>
        </table>
    </div>
</div>
