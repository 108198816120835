import { Component, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { OrderItemListService } from 'dm-src/app/modules/shared/list/order-items-list/order-item-list.service';
import {
    OrderItemState,
    OrderReturnControlService,
    ColorClass,
    FlashMessageService,
    StartOrderReturn,
} from 'shared';
import { OrderReturnItemDto } from 'shared';
import { ORDER_RETURN_SERVICE_IMPL, IOrderReturnService } from 'shared';
import { OrderReturnItem } from 'shared';

@Component({
    selector: 'app-order-items-list',
    templateUrl: './order-items-list.component.html',
    styleUrls: ['./order-items-list.component.scss'],
})
export class OrderItemsListComponent implements OnChanges {
    @Input() taskID: string;
    @Input() orderID: number;
    public OrderItemState = OrderItemState;

    public startOrderReturn: StartOrderReturn = {} as StartOrderReturn;
    public orderReturnItemDtoList: OrderReturnItemDto[];
    public orderReturnItemList: OrderReturnItem[];
    public orderReturnComment = '';

    public returnImgSrc: string;
    public photoLoaded: boolean;
    public returnPhotoImageContent: string;
    public returnPhotoImageExtension: string;

    constructor(
        public orderItemListService: OrderItemListService,
        public orderReturnControlService: OrderReturnControlService,
        private _flashMessageService: FlashMessageService,
        @Inject(ORDER_RETURN_SERVICE_IMPL)
        private _orderReturnService: IOrderReturnService
    ) {
        this.photoLoaded = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.orderItemListService.getOrderItems(this.taskID, this.orderID);
        this.orderItemListService.getOriginalOrderWeight(this.orderID);
        this.orderItemListService.setReturnInProgress(false);
        this.orderReturnControlService.OrderReturnItemList.subscribe(
            (OrderReturnItemList) => (this.orderReturnItemDtoList = OrderReturnItemList)
        );
    }

    startReturnProgress(): void {
        this.orderItemListService.setReturnInProgress(true);
    }

    addReturnRequest(): void {
        if (this.orderReturnItemDtoList.length == 0) {
            this._flashMessageService.showMessage(
                'common.error',
                'order-return.select-return-items-message',
                5000,
                ColorClass.Danger,
                'order-details-modal'
            );
            return;
        }

        this.startOrderReturn.comment = this.orderReturnComment;
        this.startOrderReturn.orderID = this.orderID;
        this.startOrderReturn.orderReturnItemDtoList = this.orderReturnItemDtoList;
        this.startOrderReturn.orderReturnImageContent = this.returnPhotoImageContent;
        this.startOrderReturn.orderReturnImageExtension = this.returnPhotoImageExtension;

        this._orderReturnService
            .startReturnOrderItems(this.startOrderReturn)
            .subscribe((startReturn) => {
                this.orderReturnItemList = startReturn.body;
                this.refreshOrderItemList();

                this._flashMessageService.showMessage(
                    'common.success',
                    'order-return.return-started-message',
                    5000,
                    ColorClass.Success,
                    'order-details-modal'
                );
            });
    }

    refreshOrderItemList(): void {
        this.orderItemListService.getOrderItems(this.taskID, this.orderID);
        this.orderItemListService.setReturnInProgress(false);
        this.orderItemListService.toggleOrderItemVisibility(null);
        this.orderReturnControlService.emptyOrderReturnItemList();
    }

    interruptReturnProgress(): void {
        this.orderItemListService.setReturnInProgress(false);
    }

    public onPhotoInputChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.returnImgSrc = e.target['result'] as string;
                this.photoLoaded = true;
            };

            reader.onloadend = (e) => {
                const imageExtension = fileInput.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();
                this.setReturnPhotoImageContent(reader.result.toString(), imageExtension);
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    public setReturnPhotoImageContent(
        imageContent: string,
        imageExtension: string
    ): void {
        this.returnPhotoImageContent = imageContent;
        this.returnPhotoImageExtension = imageExtension;
        this.photoLoaded = true;
    }
}
