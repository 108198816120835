<ion-menu side="end" content-id="main-content" type="overlay">
    <ion-header>
        <ion-toolbar translucent>
            <ion-title>Menu</ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <ion-list>
            <ng-container *ngFor="let item of menuItems">
                <ion-item
                    *ngIf="isVisible(item)"
                    [replaceUrl]="true"
                    [routerLink]="[item.path]"
                    (click)="close()"
                >
                    <ion-icon name="{{ item.icon }}" slot="start"></ion-icon>
                    <ion-label>{{ item.label | translate }}</ion-label>
                </ion-item>
            </ng-container>
            <ion-item button (click)="close()">
                <ion-icon name="close-outline" slot="start"></ion-icon>
                <ion-label>{{ 'common.close' | translate }}</ion-label>
            </ion-item>
        </ion-list>
    </ion-content>
</ion-menu>
