import { Component, Input } from '@angular/core';
import { ProgressLineService } from './progress-line.service';

@Component({
    selector: 'lib-progress-line',
    templateUrl: './progress-line.component.html',
    styleUrls: ['./progress-line.component.scss'],
})
export class ProgressLineComponent {
    @Input() public id = 'main';
    @Input() public classes;
    public internalClasses = '';

    constructor(public progressLineService: ProgressLineService) {
        this.progressLineService.visibleProgressLine.subscribe((elementID) => {
            if (elementID !== this.id) {
                this.internalClasses = 'hidden';
            } else {
                this.internalClasses = '';
            }
        });
    }
}
