import {
    Directive,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../services/authentication/iauthentication.service';

@Directive({
    selector: '[appIsAuthenticated]',
})
export class IsAuthenticatedDirective implements OnInit, OnDestroy {
    @Input() appIsAuthenticated: boolean;

    stop$ = new Subject();
    isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    ngOnInit() {
        this.handleVisibility(this._authService.currentUser);
        this._authService.applicationUser.subscribe((user) =>
            this.handleVisibility(user)
        );
    }

    private handleVisibility(user) {
        if ((!user && this.appIsAuthenticated) || (user && !this.appIsAuthenticated)) {
            this.isVisible = false;
            this.viewContainerRef.clear();
        } else if (
            !this.isVisible &&
            ((user && this.appIsAuthenticated) || (!user && !this.appIsAuthenticated))
        ) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

    ngOnDestroy() {
        this.stop$.next();
    }
}
