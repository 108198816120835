import { EventType } from '../types/event-type';
import { ActionType } from '../types/action-type';
import { ActorType } from '../types/actor-type';
import { EnvironmentName } from '../types/environment-name';

export class Common {
    public environmentName: EnvironmentName;
    public eventType: EventType;
    public actionType: ActionType;
    public actorType: ActorType;
    public ip: string;
    public userAgent: string;
    public userName: string;
    public fromStateJson: string;
    public toStateJson: string;
}
