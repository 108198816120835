import { Component, Input, OnInit } from '@angular/core';
import { ContainerPlacingTaskListItem, DeliveryTaskState } from 'shared';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';

@Component({
    selector: '[container-placing-task-item]',
    templateUrl: './container-placing-task-item.component.html',
    styleUrls: ['./container-placing-task-item.component.scss'],
})
export class ContainerPlacingTaskItemComponent implements OnInit {
    @Input() task: ContainerPlacingTaskListItem;
    public isVisible: boolean;
    public isCollapsed = true;

    constructor(private _myTasksService: MyTasksService) {}

    ngOnInit(): void {
        this.isVisible = this.checkTaskVisibility();
        this._myTasksService.taskGroups.subscribe(() => {
            this.checkTaskVisibility();
        });
    }

    private checkTaskVisibility() {
        return !(this.task.state === DeliveryTaskState[DeliveryTaskState.Done]);
    }
}
