import { HttpResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderShopBox } from 'projects/shared/src/lib/models/provider-shop-box';
import { FinalizeOrderDto } from '../../dtos/finalize-order-dto';
import { SetBoxesStatusDto } from '../../dtos/set-boxes-status-dto';

export const PROVIDER_SHOPBOX_SERVICE_IMPL = new InjectionToken<IProviderShopBoxService>(
    'providerShopBoxServiceImpl'
);

export interface IProviderShopBoxService {
    IsBoxValid(boxText: string): Observable<boolean>;
    IsSlotValid(slotText: string): Observable<boolean>;
    finalizeOrder(dto: FinalizeOrderDto): Observable<HttpResponse<void>>;
    SetBoxStatusUnderUpload(boxText: string): Observable<HttpResponse<ProviderShopBox>>;
    SetBoxesStatusDelivering(dto: SetBoxesStatusDto): Observable<HttpResponse<void>>;
    SetBoxesStatusEmpty(dto: SetBoxesStatusDto): Observable<HttpResponse<void>>;
}
