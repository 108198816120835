import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProviderShopBoxService } from './iprovider-shopbox.service';
import { HttpResponse } from '@angular/common/http';
import { ProviderShopBox } from '../../models/provider-shop-box';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { FinalizeOrderDto } from '../../dtos/finalize-order-dto';
import { SetBoxesStatusDto } from '../../dtos/set-boxes-status-dto';

@Injectable()
export class ProviderShopBoxService implements IProviderShopBoxService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public finalizeOrder(dto: FinalizeOrderDto): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>('providershopbox/finalize', dto);
    }

    public IsBoxValid(boxText: string): Observable<boolean> {
        return this._apiClient.get<boolean>('providershopbox/box/' + boxText);
    }
    public IsSlotValid(slotText: string): Observable<boolean> {
        return this._apiClient.get<boolean>('providershopbox/slot/' + slotText);
    }

    public SetBoxStatusUnderUpload(
        boxText: string
    ): Observable<HttpResponse<ProviderShopBox>> {
        return this._apiClient.postWithResponse<ProviderShopBox>(
            `providershopbox/box/under-upload?boxText=${boxText}`
        );
    }
    public SetBoxesStatusDelivering(
        dto: SetBoxesStatusDto
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'providershopbox/boxes/delivering',
            dto
        );
    }

    public SetBoxesStatusEmpty(dto: SetBoxesStatusDto): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>('providershopbox/boxes/empty', dto);
    }
}
