import { OrderItemState } from '../types/order-item-state';
import { OrderReplacementItem } from './order-replacement-item';
import { OrderReturnItem } from './order-return-item';
import { BaseOrderItem } from './base-order-item';
import { AppSettings } from '../../appsettings';

export class OrderReplacementItemDepositProduct {
    orderReplacementItemDepositProductID: string;
    orderReplacementItemID: string;
    name: string;
    price: number;
    netPrice: number;
    description: string;
    unitName: string;

    /// The amount of the not potional deposit wich this deposit can contains
    /// e.g. a crate can contains MAX 6 bottles
    maxQuantity: number;
    depositUnitTypeName: string;
    depositUnitTypeIsOptional: boolean;

    /// It must be the same amount as the ordered ordertiems
    unitValue: number;

    /// If there is any MaxQuantity and the DepositProdut is optional
    /// we have to calculate that how many items do we need to collect
    /// the not optional deposit products
    /// e.g. we need 2 crate to collect 14 bottle of beers if a crate can contains 6 beer, the last two beers sont require crates
    maxQuantityByUnitValue: number;

    /// How many items did the Picker collected
    /// If it is not optional it should be equal with amount of the connected orderitems quantity
    collectedQuantity: number;
}
