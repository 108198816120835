import { InjectionToken } from '@angular/core';
export const REALTIME_CLIENT_IMPL = new InjectionToken<IRealtimeClientService>(
    'realtimeClientServiceImpl'
);

export interface IRealtimeClientService {
    connectionId: string;
    initHubConnection(hubName: string, baseUrl?: string): void;
    connect(connId: string): Promise<void>;
    disconnect(connId: string): Promise<void>;
    invoke<T>(methodName: string, connId: string, ...args: any): Promise<T>;
    on(methodName: string, connId: string, callback): void;
}
