<ngb-alert
    [type]="flashMessageService.type | async"
    *ngIf="
        flashMessageService.isVisible && (flashMessageService.componentID | async) === id
    "
    (close)="flashMessageService.close()"
>
    <strong *ngIf="flashMessageService.title">{{
        flashMessageService.title | async
    }}</strong>
    {{ flashMessageService.message | async }}
</ngb-alert>
