import { Injectable } from '@angular/core';
import { OrderItemImage } from 'dm-src/types/order-item-image';
import { BehaviorSubject } from 'rxjs';
import { ProductIdentificationSource } from '../../../types/product-identification-source';
import { ProductIdentificationMode } from '../../../types/product-identification-mode';
import { OrderItem } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class CollectingStatesService {
    public orderID: number;
    public orderCode: string;
    public orderQrCode: string;
    public taskID: string;
    public providerID: number;
    public replaceableOrderItem: OrderItem = null;
    public originalTotalPrice: number;
    public hasMarkupPayment = false;
    public isWerwPriceForced = false;
    public isBarcodeReadingEnabled = false;
    public productIdentificationSource: ProductIdentificationSource;
    public productIdentificationMode: ProductIdentificationMode;
    public isCollectionReady = false;
    public isPhotoReady = false;
    public isBillApprovalNeeded = false;
    public isPaymentReserved = false;
    public isPaymentSucceeded = false;
    public isPaymentFinalizationDisabled = false;
    public isInvoicingSucceeded = false;
    public isStartPaymentInProgress = false;
    public isReleasePaymentInProgress = false;
    public isFinishPaymentInProgress = false;
    public isVirtualPaymentWaiting = false;
    public isOrderItemIdentificationInProgress = false;
    public usePaperBag = false;
    public showPhoneButton = false;
    public onlineConsultation = false;
    public existPreferenceSettings = false;
    public existReplacementProvision = false;
    public customerCalled = false;
    public sendApprovalRequest = false;
    public customerPhone: string;
    public customerName: string;
    public customerFirstName: string;
    public customerLastName: string;
    public acceptsEverything = false;
    public noAskForReplacementItems = false;
    public collectionDate = null;
    public allowCheckout = false;
    public shownOrderItemImage: OrderItemImage;
    private _isCreateItemModalVisible = new BehaviorSubject<boolean>(false);
    private _isTotalPriceApprovalModalVisible = new BehaviorSubject<boolean>(false);
    private _isCollectingDoneFailedModalVisible = new BehaviorSubject<boolean>(false);
    private _isBarcodeReaderModalVisible = new BehaviorSubject<boolean>(false);
    private _isProductIdentifyModalVisible = new BehaviorSubject<boolean>(false);
    private _isInspectingInProgress = false;

    get isButtonClickForbidden(): boolean {
        return !this.isCollectionReady || this.isCollectingForbidden;
    }

    get isCollectingForbidden(): boolean {
        return (
            this.isStartPaymentInProgress ||
            this.isReleasePaymentInProgress ||
            this.isFinishPaymentInProgress ||
            this.isVirtualPaymentWaiting ||
            this.isInspectingInProgress ||
            this.isPaymentSucceeded
        );
    }

    get areCollectingButtonsVisible(): boolean {
        return (
            (this.isCollectionReady || this.isPaymentReserved) &&
            !this.isPaymentFinalizationDisabled
        );
    }

    get isCheckoutButtonVisible(): boolean {
        return !this.isPaymentReserved && !this.isPaymentSucceeded;
    }

    get isPhotoButtonVisible(): boolean {
        return this.isPaymentReserved || this.isPaymentSucceeded;
    }

    get isTotalPriceApprovalVisible() {
        return this._isTotalPriceApprovalModalVisible.asObservable();
    }

    get isCreateItemModalVisible() {
        return this._isCreateItemModalVisible.asObservable();
    }

    get isCollectingDoneFailedModalVisible() {
        return this._isCollectingDoneFailedModalVisible.asObservable();
    }

    get isProductIdentifyModalVisible() {
        return this._isProductIdentifyModalVisible.asObservable();
    }

    get isBarcodeReaderModalVisible() {
        return this._isBarcodeReaderModalVisible.asObservable();
    }

    get isInspectingInProgress(): boolean {
        return this._isInspectingInProgress;
    }

    set isInspectingInProgress(isInProgress: boolean) {
        this._isInspectingInProgress = isInProgress;
    }

    constructor() {}

    public setTotalPriceApprovalModalVisible(value: boolean) {
        this._isTotalPriceApprovalModalVisible.next(value);
    }

    public setCreateItemModalVisible(value: boolean) {
        this._isCreateItemModalVisible.next(value);
    }

    public setCollectingDoneFailedModalVisible(value: boolean) {
        this._isCollectingDoneFailedModalVisible.next(value);
    }

    public setBarcodeReaderModalVisible(value: boolean) {
        this._isBarcodeReaderModalVisible.next(value);
    }

    public setProductIdentifyModalVisible(value: boolean) {
        this._isProductIdentifyModalVisible.next(value);
    }

    public resetStates(): void {
        this.isInvoicingSucceeded = false;
        this.isBillApprovalNeeded = false;
        this.isPaymentReserved = false;
        this.isPaymentSucceeded = false;
        this.isCollectionReady = false;
        this.isPhotoReady = false;
        this.isPaymentFinalizationDisabled = false;
        this.isInspectingInProgress = false;
    }

    public resetCheckoutButtonStates(): void {
        this.isPaymentReserved = false;
        this.isPhotoReady = false;
    }
}
