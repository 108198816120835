<button
    *ngIf="canCollectManually"
    class="btn btn-success"
    (click)="approveItem()"
    [disabled]="states.isCollectingForbidden"
>
    <i class="fa fa-cart-arrow-down"></i>{{ 'collecting.collect' | translate }}
</button>
<button
    *ngIf="!states.noAskForReplacementItems"
    class="btn btn-warning"
    [disabled]="states.isCollectingForbidden"
    (click)="replaceItem()"
>
    <i class="fa fa-refresh"></i>{{ 'collecting.replace' | translate }}
</button>
<button
    class="btn btn-danger"
    [disabled]="states.isCollectingForbidden"
    (click)="itemNotFound()"
>
    <i class="fa fa-exclamation-triangle"></i>{{ 'collecting.not-found' | translate }}
</button>

<ng-template #missingReplacementReasonModal let-modal>
    <div class="modal-header modal-fullscreen bg-danger text-white">
        <h4 class="modal-title" id="modal-basic-title">{{ title | translate }}</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="closeModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div *ngFor="let item of orderRemovedItemReasons">
                <label
                    class="btn btn-outline-primary w-100 mt-3"
                    [ngClass]="{ selected: selectedRemovedReasonID == item.id }"
                >
                    <input
                        type="radio"
                        [value]="item.id"
                        name="options"
                        id="item.label"
                        class="d-none"
                        (change)="handleReasonTypeChange($event)"
                        [(ngModel)]="selectedRemovedReasonID"
                        (ngModelChange)="reset()"
                    />
                    {{ item.label }}
                </label>
            </div>
        </div>

        <div *ngIf="isRequiredComment" class="w-100 mt-4 mb-4">
            <input
                type="text"
                id="comment"
                [(ngModel)]="comment"
                class="form-control"
                placeholder="{{ 'common.notes' | translate }}"
                autofocus
            />
            <div *ngIf="isCommentInvalid" class="text-danger">
                <div>
                    {{ 'common.notes' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="photoLoaded" class="photo-thumbnail-container">
            <img
                class="img-thumbnail d-block mr-auto ml-auto"
                [src]="imgResultAfterCompression"
            />
        </div>
        <div class="file-input" *ngIf="!photoLoaded && isRequiredPhoto">
            <input
                *ngIf="!isNewWrapper"
                type="file"
                accept="image/*"
                capture="environment"
                id="photo-input"
                onclick="this.value = null"
                (change)="onPhotoInputChanged($event)"
                hidden
            />
            <label
                class="photo-btn btn btn-block btn-info"
                for="photo-input"
                (click)="handleTakeAPhoto()"
            >
                <i class="fa fa-camera"></i> {{ 'collecting.photo-upload' | translate }}
            </label>
            <div *ngIf="isPhotoInvalid" class="text-danger">
                <div>
                    {{ 'collecting.photo-upload' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>
        <div *ngIf="photoLoaded" class="photo-thumbnail-container">
            <button class="btn btn-light btn-block mt-2" (click)="deletePhoto()">
                <span class="font-weight-bold text-smaller">{{
                    'collecting.delete-photo' | translate
                }}</span>
            </button>
        </div>
        <div>
            <button class="btn btn-success btn-lg btn-block mt-5" (click)="onSubmit()">
                <span class="font-weight-bold text-smaller">{{
                    'collecting.next' | translate
                }}</span>
            </button>
        </div>

        <div>
            <button
                type="button"
                class="btn btn-block btn-light mt-4"
                (click)="closeModal()"
            >
                <span>{{ 'common.cancel' | translate }}</span>
            </button>
        </div>
    </div>
</ng-template>
