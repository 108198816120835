import { Component, Input, OnInit } from '@angular/core';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';
import { DeliveryTaskType } from 'shared';
import { DeliveryTaskState } from 'shared';
import { FlashMessageService } from 'shared';
import { PaymentState } from 'shared';
import { ColorClass } from 'shared';

@Component({
    selector: 'app-order-payment-data',
    templateUrl: './order-payment-data.component.html',
    styleUrls: ['./order-payment-data.component.scss'],
})
export class OrderPaymentDataComponent implements OnInit {
    public isPaymentRefundActive: boolean;
    public isRefundConfirmationVisible: boolean;
    public orderDetails = this._ordersService.orderDetails;
    public selectedOrder = this._ordersService.selectedOrder;
    @Input() public orderCode: string;

    constructor(
        private _ordersService: OrdersService,
        private _flashMessageService: FlashMessageService
    ) {}

    ngOnInit(): void {
        this.isRefundConfirmationVisible = false;
        this._ordersService.orderDetails.subscribe((orderDetails) => {
            if (orderDetails) {
                this.isPaymentRefundActive =
                    orderDetails.hasReservedPayment &&
                    orderDetails.deliveryTasks.some(
                        (x) =>
                            x.taskType === DeliveryTaskType.Collecting &&
                            x.taskState === DeliveryTaskState.Failed
                    );
            }
        });
    }

    public refundPayment(): void {
        this._ordersService.refundPayment().subscribe((response) => {
            if (response.status === 200) {
                let title = null;
                let message = null;
                let type: ColorClass;
                if (response.body.paymentState === PaymentState.Refunded) {
                    title = 'orders.payment-refunded-successfully';
                    message = null;
                    type = ColorClass.Success;
                    this.isPaymentRefundActive = false;
                } else {
                    title = 'orders.payment-refund-failed';
                    message = response.body.errorText;
                    type = ColorClass.Danger;
                }

                this._flashMessageService.showMessage(
                    title,
                    message,
                    5000,
                    type,
                    'order-details-modal'
                );
                this.isRefundConfirmationVisible = false;
            }
        });
    }
}
