import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SupplierRegionsData } from 'dm-src/models/supplier-regions-data';
import { SupplierRegion } from 'dm-src/models/supplier-region';
import { ISelectOption } from 'shared';

export const SUPPLIER_REGIONS_SERVICE_IMPL = new InjectionToken<ISupplierRegionsService>(
    'supplierRegionsServiceImpl'
);

export interface ISupplierRegionsService {
    getSupplierRegionsData(): Observable<HttpResponse<SupplierRegionsData>>;
    getRegionFilterOptions(): Observable<HttpResponse<ISelectOption[]>>;
    saveSupplierRegionsData(
        supplierRegionsData: SupplierRegion[]
    ): Observable<HttpResponse<SupplierRegion[]>>;
    deleteSupplierRegion(supplierRegionID: string): Observable<HttpResponse<void>>;
}
