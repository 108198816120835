import { DeliveringTaskListItem, ContainerPlacingTaskListItem } from 'shared';
import { CollectingTaskListItem } from 'shared';
import { RefundingTaskListItem } from 'shared';
import { TaskGroupItemType } from 'dm-src/types/task-group-item-type';

export class TaskGroupItem {
    public taskType: TaskGroupItemType;
    public task:
        | CollectingTaskListItem
        | DeliveringTaskListItem
        | RefundingTaskListItem
        | ContainerPlacingTaskListItem;
}
