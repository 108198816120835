import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { OrderItemState } from 'shared';
import { AppSettings } from 'shared';
import { OrderItemListService } from '../order-item-list.service';

@Component({
    selector: '[order-item]',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent {
    @Input() index: number;
    @Input() orderItem: OrderItem;
    @Input() hasReplacementItem = false;
    @Input() hasState = true;
    @Input() isReturnInProgress = false;
    public OrderItemState = OrderItemState;

    constructor(public orderItemListService: OrderItemListService) {}

    ngOnInit(): void {}

    public openImage(src: string): void {
        this.orderItemListService.openImage(src);
    }
}
