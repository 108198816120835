import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderItem } from '../../models/order-item';
import { OrderReturnItemDto } from '../../dtos/order-return-item-dto';
import { OrderItemDepositProduct } from '../../models/order-item-deposit-product';
import { OrderReplacementItemDepositProduct } from '../../models/order-replacement-item-deposit-product';

@Injectable({
    providedIn: 'root',
})
export class OrderReturnControlService {
    private _orderReturnItemList: BehaviorSubject<OrderReturnItemDto[]>;

    public get OrderReturnItemList() {
        return this._orderReturnItemList.asObservable();
    }

    constructor() {
        this._orderReturnItemList = new BehaviorSubject<OrderReturnItemDto[]>([]);
    }

    public emptyOrderReturnItemList() {
        this._orderReturnItemList.next([]);
    }

    public getMaxRefundableQunatity(
        orderItem: OrderItem,
        orderItemDepositProduct: OrderItemDepositProduct,
        orderReplacementItemDepositProduct: OrderReplacementItemDepositProduct
    ): number {
        //When we already refunded some of the items we cant refund more
        if (orderItem.orderReturnItemList.length > 0) {
            if (orderItemDepositProduct) {
                return orderItem.orderReturnItemList
                    .filter(
                        (x) =>
                            x.orderItemDepositProduct &&
                            x.orderItemDepositProduct.orderItemDepositProductID ==
                                orderItemDepositProduct.orderItemDepositProductID
                    )
                    ?.map((x) => x.quantity)
                    .reduce(
                        (previousQuantity, nextQuantity) =>
                            previousQuantity + nextQuantity,
                        0
                    );
            }

            if (orderReplacementItemDepositProduct) {
                return orderItem.orderReturnItemList
                    .filter(
                        (x) =>
                            x.orderReplacementItemDepositProduct &&
                            x.orderReplacementItemDepositProduct
                                .orderReplacementItemDepositProductID ==
                                orderReplacementItemDepositProduct.orderReplacementItemDepositProductID
                    )
                    ?.map((x) => x.quantity)
                    .reduce(
                        (previousQuantity, nextQuantity) =>
                            previousQuantity + nextQuantity,
                        0
                    );
            }

            let alreadyReturnedQuantity = orderItem.orderReturnItemList
                ?.map((x) => x.quantity)
                .reduce(
                    (previousQuantity, nextQuantity) => previousQuantity + nextQuantity,
                    0
                );
            return (
                (orderItem.orderReplacementItem
                    ? orderItem.orderReplacementItem.quantity
                    : orderItem.quantity) - alreadyReturnedQuantity
            );
        } else {
            //When we already refunded some of the items we cant refund more
            if (orderItemDepositProduct) {
                return orderItemDepositProduct.collectedQuantity;
            }

            if (orderReplacementItemDepositProduct) {
                return orderReplacementItemDepositProduct.collectedQuantity;
            }

            return orderItem.orderReplacementItem
                ? orderItem.orderReplacementItem.quantity
                : orderItem.quantity;
        }
    }

    public setOrderReturnItemList(
        orderItems: OrderItem[],
        orderItemID: number,
        quantity: number,
        orderItemDepositProductID: string,
        orderReplacementItemDepositProductID: string
    ): void {
        let currentOrderReturnItemList = this._orderReturnItemList.value;

        //DEPOSITREPLACEMENTITEM Return
        if (orderReplacementItemDepositProductID) {
            if (quantity <= 0) {
                //Remove
                this._orderReturnItemList.next(
                    currentOrderReturnItemList.filter(
                        (x) =>
                            x.orderReplacementItemDepositProductID !==
                            orderReplacementItemDepositProductID
                    )
                );
                return;
            }

            //update
            if (
                currentOrderReturnItemList.find(
                    (x) =>
                        x.orderReplacementItemDepositProductID ===
                        orderReplacementItemDepositProductID
                )
            ) {
                currentOrderReturnItemList.find(
                    (x) =>
                        x.orderReplacementItemDepositProductID ===
                        orderReplacementItemDepositProductID
                ).quantity = quantity;
                this._orderReturnItemList.next(currentOrderReturnItemList);
                return;
            }
            //DEPOSITITEM Return
        } else if (orderItemDepositProductID) {
            if (quantity <= 0) {
                //Remove
                this._orderReturnItemList.next(
                    currentOrderReturnItemList.filter(
                        (x) => x.orderItemDepositProductID !== orderItemDepositProductID
                    )
                );
                return;
            }

            //update
            if (
                currentOrderReturnItemList.find(
                    (x) => x.orderItemDepositProductID === orderItemDepositProductID
                )
            ) {
                currentOrderReturnItemList.find(
                    (x) => x.orderItemDepositProductID === orderItemDepositProductID
                ).quantity = quantity;
                this._orderReturnItemList.next(currentOrderReturnItemList);
                return;
            }
            //NORMAL OrderITEM Return
        } else {
            //Validate
            let alreadyReturnedQuantity =
                orderItems
                    .find((x) => x.orderItemID === orderItemID)
                    .orderReturnItemList?.map((x) => x.quantity)
                    .reduce(
                        (previousQuantity, nextQuantity) =>
                            previousQuantity + nextQuantity,
                        0
                    ) || 0;

            // ha van az orderitem replacementje akkor onnan
            let orderItem = orderItems.find((x) => x.orderItemID === orderItemID);
            const isBulk = orderItem.orderReplacementItem
                ? orderItem.orderReplacementItem.isBulk
                : orderItem.isBulk;

            if (isBulk === false && quantity % 1 != 0) {
                throw new RangeError('order-return.is-bulk-violation-message');
            }

            let maxReturnableQuantity = orderItem.orderReplacementItem
                ? orderItem.orderReplacementItem.quantity
                : orderItem.quantity;

            if (quantity > maxReturnableQuantity - alreadyReturnedQuantity) {
                throw new RangeError('order-return.quantity-violation-message');
            }

            if (quantity <= 0) {
                //Remove
                this._orderReturnItemList.next(
                    currentOrderReturnItemList.filter(
                        (x) => x.orderItemID !== orderItemID
                    )
                );
                return;
            }

            //update
            if (currentOrderReturnItemList.find((x) => x.orderItemID === orderItemID)) {
                currentOrderReturnItemList.find(
                    (x) => x.orderItemID === orderItemID
                ).quantity = quantity;
                this._orderReturnItemList.next(currentOrderReturnItemList);
                return;
            }
        }

        //add
        this._orderReturnItemList.next(
            currentOrderReturnItemList.concat({
                orderItemID: orderItemID,
                quantity: quantity,
                orderItemDepositProductID: orderItemDepositProductID,
                orderReplacementItemDepositProductID:
                    orderReplacementItemDepositProductID,
            })
        );

        return;
    }
}
