import { Injectable } from '@angular/core';
import { ColorClass } from 'shared';
import { Router } from '@angular/router';
import { FlashMessageService } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class CollectingSharedService {
    constructor(
        private _router: Router,
        private _flashMessageService: FlashMessageService
    ) {}

    public redirectToMyTasks(): void {
        this._flashMessageService.showMessage(
            'common.success',
            'collecting.collecting-task-finished-successfully',
            5000,
            ColorClass.Success
        );

        this._router.navigate(['my-tasks']);
    }

    public redirectToMyTasksBecauseOrderModificationIsBlocked(): void {
        this._flashMessageService.showMessage(
            'common.decline',
            'collecting.order-modification-blocked',
            5000,
            ColorClass.Warning
        );

        this._router.navigate(['my-tasks']);
    }
}
