<lib-modal
    [title]="'my-tasks.package-verification'"
    [bgColorClass]="ColorClass.Success"
    [componentID]="'package-verify-modal'"
>
    <div *ngIf="task" class="text-center">
        <p class="h3">
            <strong>{{ 'my-tasks.order-code' | translate }}:</strong>
            {{ task.orderCode }}
        </p>
        <p class="h4">
            <strong>{{ 'my-tasks.elapsed-time' | translate }}:</strong>
            {{ elapsedTime }}
        </p>
        <img [src]="qrImage" *ngIf="!isInProgress; else inProgress" />
        <ng-template #inProgress>
            <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span>{{ 'common.working' | translate }}...</span>
        </ng-template>
        <app-order-items-view-modal
            [orderItems]="orderItems"
        ></app-order-items-view-modal>
    </div>
</lib-modal>
