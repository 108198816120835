import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { IonLoaderService } from './ion.loader.service';
import { IonToasterService } from './ion.toaster.service';

@Injectable({
    providedIn: 'root',
})
export class IonApiClientService {
    private _httpInprogress: BehaviorSubject<boolean>;

    public get httpInprogress() {
        return this._httpInprogress.asObservable();
    }

    constructor(
        @Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService,
        private _loaderService: IonLoaderService,
        private _toasterService: IonToasterService
    ) {
        this._httpInprogress = new BehaviorSubject<boolean>(false);
    }

    public post<T>(
        endpoint: string,
        requestBody: object | string,
        headers?: HttpHeaders,
        loadingText?: string,
        doNotHandleError?: boolean,
        successText?: string
    ): Observable<HttpResponse<T>> {
        this._loaderService.showLoader(loadingText);

        this._httpInprogress.next(true);

        return this._apiClient.postWithResponse<T>(endpoint, requestBody, headers).pipe(
            finalize(() => this._loaderService.hideLoader()),
            tap((result) => {
                this._httpInprogress.next(false);
                if (successText) this._toasterService.success(successText);
            }),
            catchError((err) => {
                this._httpInprogress.next(false);
                return throwError(err);
            })
        );
    }

    public get<T>(
        endpoint: string,
        loadingText?: string,
        doNotHandleError?: boolean
    ): Observable<T> {
        this._loaderService.showLoader(loadingText);

        this._httpInprogress.next(true);

        return this._apiClient.get<T>(endpoint).pipe(
            finalize(() => this._loaderService.hideLoader()),
            tap((result) => {
                this._httpInprogress.next(false);
            }),
            catchError((err) => {
                this._httpInprogress.next(false);
                return throwError(err);
            })
        );
    }

    public put<T>(
        endpoint: string,
        requestBody: object | string,
        headers?: HttpHeaders,
        loadingText?: string,
        doNotHandleError?: boolean,
        successText?: string
    ): Observable<HttpResponse<T>> {
        this._loaderService.showLoader(loadingText);

        this._httpInprogress.next(true);

        return this._apiClient.putWithResponse<T>(endpoint, requestBody, headers).pipe(
            finalize(() => this._loaderService.hideLoader()),
            tap((result) => {
                this._httpInprogress.next(false);
                if (successText) this._toasterService.success(successText);
            }),
            catchError((err) => {
                this._httpInprogress.next(false);
                return throwError(err);
            })
        );
    }
}
