import { Component, OnInit } from '@angular/core';
import { CollectingStatesService } from '../collecting-states.service';
import { CollectingActionsService } from '../collecting-actions.service';
import { TaskFailedModalService } from '../../shared/task-failed-modal/task-failed-modal.service';

@Component({
    selector: 'app-collecting-buttons',
    templateUrl: './collecting-buttons.component.html',
    styleUrls: ['./collecting-buttons.component.scss'],
})
export class CollectingButtonsComponent implements OnInit {
    constructor(
        public states: CollectingStatesService,
        public actions: CollectingActionsService,
        public taskFailedModalService: TaskFailedModalService
    ) {}

    ngOnInit(): void {}
}
