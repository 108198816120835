<ng-template #collectingDoneFailedModal let-modal>
    <div class="modal-header modal-fullscreen bg-danger text-white">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'collecting.task-failed' | translate }}
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center">
        <h3>{{ event }} {{ 'common.failed' | translate }}!</h3>
        <a class="btn btn-warning" href="tel:{{ supportPhone }}" (click)="closeModal()"
            ><i class="fa fa-phone"></i>Call Support</a
        >
    </div>
</ng-template>
