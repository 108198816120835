import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskGeneratorComponent } from './task-generator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';

@NgModule({
    declarations: [TaskGeneratorComponent],
    imports: [CommonModule, ReactiveFormsModule, PreloaderModule],
})
export class TaskGeneratorModule {}
