import { Component, OnInit, Inject } from '@angular/core';
import { DailyReviewService } from 'dm-src/app/modules/daily-review/daily-review.service';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';

@Component({
    selector: 'app-financial-information',
    templateUrl: './financial-information.component.html',
    styleUrls: ['./financial-information.component.scss'],
})
export class FinancialInformationComponent implements OnInit {
    public dailyCashAmount: number;

    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _dailyReviewService: DailyReviewService
    ) {}

    ngOnInit(): void {
        const userID = this._authService.currentUser.userID;

        this._dailyReviewService.getFinancialInformation(userID).subscribe((result) => {
            this.dailyCashAmount = result.dailyCashAmount;
        });
    }
}
