import { AfterViewInit, Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { CollectingStatesService } from '../../collecting-states.service';
import { ModalService } from 'shared';
import {
    ColorClass,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';
import { ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { AppComponent } from 'dm-src/app/app.component';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { OfflineProductsService } from 'dm-src/app/modules/collecting/offline-products.service';

@Component({
    selector: 'app-product-identify-modal',
    templateUrl: './product-identify-modal.component.html',
    styleUrls: ['./product-identify-modal.component.scss'],
})
export class ProductIdentifyModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    public ColorClass = ColorClass;
    public barcode = '';

    constructor(
        private _states: CollectingStatesService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _modalService: ModalService,
        private _isWrappingService: IsWrappingService,
        private _nativeBarcodeReaderService: NativeBarcodeReaderService,
        private iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        private _offlieProductsService: OfflineProductsService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit(): void {
        this._states.isProductIdentifyModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) =>
                this._modalService.setModalVisibility(isVisible, 'product-identify-modal')
            );
        if (window.ReactNativeWebView) {
            this.iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'BARCODE_READING_START') {
                        this._offlieProductsService.processGtin(response.result);
                    }
                });
        }
    }

    public showBarcodeReaderModal(): void {
        this.cancel();
        /******************* */
        if (window.ReactNativeWebView) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader();
        } else if (this._isWrappingService.isWrapping()) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader();
            this._nativeBarcodeReaderService.initBarcodeReading();
        } else {
            this._states.setBarcodeReaderModalVisible(true);
        }
        /******************* */

        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType =
            this._states.replaceableOrderItem != null
                ? ActionType.SearchOfflineProductForReplacedOrderItemByGTINButtonClicked
                : ActionType.SearchOfflineProductForOrderItemByGTINButtonClicked;
        orderStateEventLog.fromStateJson = null;
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this._states.orderID;
        orderStateEventLog.orderCode = this._states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();
    }

    public showManualSKUCheckModal(): void {
        this.cancel();
        this._modalService.setModalVisibility(true, 'manual-sku-check-modal');

        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType =
            this._states.replaceableOrderItem != null
                ? ActionType.SearchOfflineProductForReplacedOrderItemBySKUButtonClicked
                : ActionType.SearchOfflineProductForOrderItemBySKUButtonClicked;
        orderStateEventLog.fromStateJson = null;
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this._states.orderID;
        orderStateEventLog.orderCode = this._states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();
    }

    public cancel(): void {
        this._states.setProductIdentifyModalVisible(false);
    }

    ngOnDestroy(): void {}
}
