import { Pipe, PipeTransform } from '@angular/core';
import { priceRound } from '../helpers/math.helper';
import { convertDecimalDelimiter } from '../helpers/decimal.helper';

@Pipe({
    name: 'priceround',
})
export class PriceRoundPipe implements PipeTransform {
    public transform(price: number): string {
        const priceRoundDecimal = priceRound(price);
        return convertDecimalDelimiter(priceRoundDecimal);
    }
}
