<div class="d-flex flex-column w-100 h-100">
    <div class="d-flex" *ngIf="isScannerVisible">
        <button
            class="btn btn-block text-white bg-danger cancel-btn"
            (click)="isScannerVisible = false"
        >
            {{ 'common.cancel' | translate }}
        </button>
        <lib-qr-reader (onQRScanned)="onQRCodeFound($event)"></lib-qr-reader>
    </div>

    <div class="card-header">
        <span
            ><strong>{{ 'my-tasks.read-boxes' | translate }}</strong></span
        >
    </div>
    <button
        class="btn btn-block bg-success text-white d-flex justify-content-around"
        *ngFor="let boxText of boxTexts"
    >
        {{ boxText }}
        <fa-icon class="fa-lg" [icon]="faTrash" (click)="removeBox(boxText)"></fa-icon>
    </button>
    <button
        *ngIf="!isScannerVisible"
        class="btn btn-block text-dark bg-secondary mt-3"
        expand="block"
        (click)="openQRModal('box')"
    >
        <fa-icon class="fa-lg" [icon]="faSearch"></fa-icon>
        {{ 'my-tasks.scan-new-box' | translate }}
    </button>

    <div class="card-header mt-2">
        <span>
            <strong>{{ 'my-tasks.scanned-slots' | translate }}</strong></span
        >
    </div>
    <button
        class="btn btn-block bg-success text-white d-flex justify-content-around"
        *ngFor="let slotText of slotTexts"
    >
        {{ slotText }}
        <fa-icon class="fa-lg" [icon]="faTrash" (click)="removeSlot(slotText)"></fa-icon>
    </button>
    <button
        *ngIf="!isScannerVisible"
        class="btn btn-block text-dark bg-secondary mt-3"
        expand="block"
        (click)="openQRModal('slot')"
    >
        <fa-icon class="fa-lg" [icon]="faSearch"></fa-icon>
        {{ 'my-tasks.scan-new-slot' | translate }}
    </button>

    <button
        [disabled]="slotTexts.length === 0 || boxTexts.length === 0 || isLoading"
        class="btn btn-block bg-warning text-white mt-4"
        (click)="finalizeOrder()"
    >
        {{ 'my-tasks.close-task' | translate }}
    </button>
</div>
