import { Component, Input, OnInit } from '@angular/core';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';
import { Order } from 'dm-src/models/order';
import { take } from 'rxjs/operators';
import { OrderHistory } from 'shared';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent implements OnInit {
    @Input() order: Order;
    public selectedOrder: Order;
    public orderHistory: OrderHistory[];

    constructor(private _ordersService: OrdersService) {}

    ngOnInit(): void {
        this._ordersService.selectedOrder.pipe(take(1)).subscribe((selectedOrder) => {
            this.selectedOrder = selectedOrder;
        });

        this._ordersService
            .getOrderHistory(this.order.orderID)
            .pipe(take(1))
            .subscribe((history) => {
                this.orderHistory = history;
            });
    }
}
