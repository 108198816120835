<input
    placeholder="Select date"
    [value]="initialValue"
    type="text"
    class="form-control"
    [(bsValue)]="initialDateRange"
    [bsConfig]="defaultConfig"
    (bsValueChange)="onValueChange($event)"
    (keyup)="onInputChanged($event)"
    bsDaterangepicker
    #dpr="bsDaterangepicker"
/>
