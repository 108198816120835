import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierRegionChooserComponent } from './supplier-region-chooser.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [SupplierRegionChooserComponent],
    exports: [SupplierRegionChooserComponent],
    imports: [CommonModule, NgxSelectModule, FormsModule, ReactiveFormsModule],
})
export class SupplierRegionChooserModule {}
