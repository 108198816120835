import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'dm-src/environments/environment';

@Component({
    selector: 'app-call-support-btn',
    templateUrl: './call-support-btn.component.html',
    styleUrls: ['./call-support-btn.component.scss'],
})
export class CallSupportBtnComponent implements OnInit {
    public supportPhone: string;
    @Input() public btnClass = 'btn-warning btn-block';
    @Output() public click = new EventEmitter<void>();

    constructor() {}

    ngOnInit(): void {
        this.supportPhone = environment.supportPhone;
    }

    public onClick(): void {
        this.click.emit();
    }
}
