import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { OrderItemState } from 'shared';
import { OrderItemImage } from 'dm-src/types/order-item-image';
import { CollectingStatesService } from '../../collecting-states.service';
import { OrderRemovedItemReasons } from 'projects/shared/src/lib/types/order-removed-item-reasons';

@Component({
    selector: '[app-order-item]',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent {
    @Input() orderItem: OrderItem;
    @Input() orderRemovedItemReasons: OrderRemovedItemReasons[];
    public orderItemState = OrderItemState;

    constructor(
        public states: CollectingStatesService,
        private _orderItemsService: CollectingItemsService
    ) {}
    public onImageClick(): void {
        const image = new OrderItemImage();
        image.url = this.orderItem.itemImageSrc;
        image.orderItemName = this.orderItem.orderItemName;
        this._orderItemsService.showOrderItemImage(image);
    }
}
