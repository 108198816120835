import {
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SetDeliveryTaskFailed } from 'shared';
import { TaskFailedModalService } from 'dm-src/app/modules/shared/task-failed-modal/task-failed-modal.service';
import {
    COLLECTING_SERVICE_IMPL,
    ICollectingService,
} from 'dm-src/services/collecting/icollecting.service';
import { DeliveryTaskType } from 'shared';
import { ITasksService, TASKS_SERVICE_IMPL } from 'shared';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-task-failed-modal',
    templateUrl: './task-failed-modal.component.html',
    styleUrls: ['./task-failed-modal.component.scss'],
})
export class TaskFailedModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    public taskFailureForm: FormGroup;
    public isSubmitted: boolean;
    @Output() public onTaskFailSet = new EventEmitter<number>();

    @ViewChild('taskFailedModal', { static: false })
    private _taskFailedModal: TemplateRef<any>;

    public get fields() {
        return this.taskFailureForm.controls;
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _orderItemsService: CollectingItemsService,
        private _modalService: NgbModal,
        private _taskFailedModalService: TaskFailedModalService,
        @Inject(COLLECTING_SERVICE_IMPL)
        private _collectingService: ICollectingService,
        @Inject(TASKS_SERVICE_IMPL)
        private _tasksService: ITasksService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this.isSubmitted = false;
        this.initFailureForm();
        this._taskFailedModalService.isFailModalVisible
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isVisible) => {
                if (isVisible.valueOf()) {
                    this._modalService.open(this._taskFailedModal, {
                        windowClass: 'modal-holder',
                        centered: true,
                        size: 'sm',
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });
    }

    public onSubmit() {
        this.isSubmitted = true;

        if (this.taskFailureForm.invalid) {
            return;
        }

        this.failDeliveryTask();
        this._taskFailedModalService.setFailModalVisible(false);
    }

    private initFailureForm(): void {
        this.taskFailureForm = this._formBuilder.group({
            reason: [null, Validators.required],
        });
    }

    private failDeliveryTask() {
        const requestBody = new SetDeliveryTaskFailed();
        requestBody.reason = this.fields.reason.value;
        requestBody.orderID = this._taskFailedModalService.orderID;
        requestBody.taskID = this._taskFailedModalService.taskID;

        let apiCallback: Observable<HttpResponse<void>>;

        switch (this._taskFailedModalService.failedTaskType) {
            case DeliveryTaskType.Collecting:
                apiCallback = this._collectingService.failed(requestBody);
                break;
            case DeliveryTaskType.Delivering:
                apiCallback = this._tasksService.failDeliveryTask(requestBody);
        }

        apiCallback.subscribe((response) => {
            if (this.onTaskFailSet != null) {
                this.onTaskFailSet.emit(response.status);
            }
        });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }
}
