<div class="fullheight xc">
    <div class="container-login">
        <div *ngIf="showSuccess" class="alert alert-success" role="alert">
            <ion-badge color="success">{{
                'login.password-changed' | translate
            }}</ion-badge>
        </div>
        <a [routerLink]="['/login']">{{ 'common.login' | translate }}</a>
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmitChangePassword()">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <h1>{{ 'login.change-password' | translate }}</h1>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-item>
                                <ion-icon
                                    name="lock-closed-outline"
                                    class="icon-login"
                                ></ion-icon>
                                <ion-input
                                    name="password"
                                    formControlName="password"
                                    type="password"
                                    autofocus
                                    required
                                    placeholder="{{
                                        'userform-common.enter-password' | translate
                                    }}"
                                ></ion-input>
                            </ion-item>

                            <span
                                *ngIf="isSubmitted && fields.password.errors"
                                class="field-error ion-padding"
                            >
                                <span *ngIf="fields.password.errors.required">
                                    {{ 'userform-common.enter-password' | translate }}
                                </span>
                            </span>
                            <br />
                            <ion-item>
                                <ion-icon
                                    name="lock-closed-outline"
                                    class="icon-login"
                                ></ion-icon>
                                <ion-input
                                    name="confirmPassword"
                                    type="password"
                                    formControlName="confirmPassword"
                                    required
                                    placeholder="{{
                                        'userform-common.confirm-password' | translate
                                    }}"
                                >
                                </ion-input>
                            </ion-item>

                            <span
                                *ngIf="isSubmitted && fields.confirmPassword.errors"
                                class="field-error ion-padding"
                            >
                                <span *ngIf="fields.confirmPassword.errors.required">
                                    {{
                                        'userform-common.password-confirmation'
                                            | translate
                                    }}
                                    {{ 'common.is-required' | translate }}
                                </span>
                                <span
                                    *ngIf="fields.confirmPassword.errors.mustMatch"
                                    class="field-error ion-padding"
                                >
                                    {{
                                        'userform-common.passwords-must-match' | translate
                                    }}
                                </span>
                            </span>
                        </ion-list>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button type="submit" expand="block">
                            {{ 'common.submit' | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>
</div>
