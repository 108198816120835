import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanFilterComponent } from './boolean-filter/boolean-filter.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BooleanIconComponent } from './boolean-icon/boolean-icon.component';
import { IonBooleanIconComponent } from './boolean-icon/ion-boolean-icon.component';
import { IonicModule, IonIcon } from '@ionic/angular';

@NgModule({
    declarations: [
        BooleanFilterComponent,
        DatePickerComponent,
        BooleanIconComponent,
        IonBooleanIconComponent,
    ],
    imports: [CommonModule, BsDatepickerModule.forRoot(), IonicModule],
    exports: [
        BooleanFilterComponent,
        DatePickerComponent,
        BooleanIconComponent,
        IonBooleanIconComponent,
    ],
})
export class LibCommonModule {}
