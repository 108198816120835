<div class="action-toolbar bg-secondary">
    <div class="row" *ngIf="states.isBarcodeReadingEnabled; else noBarcode">
        <div class="col col-6 text-center"></div>
        <div class="col col-6 text-right">
            <app-btn-action
                class="mr-1"
                [btnClass]="'btn btn-primary btn-circle btn-lg btn-lateral'"
                [iconClass]="'fa fa-search'"
                (click)="showProductIdentifyModal()"
                [disabled]="states.isCollectingForbidden"
            >
            </app-btn-action>
        </div>
    </div>
    <ng-template #noBarcode>
        <div class="row">
            <div class="col col-6 text-center"></div>
            <div class="col col-6 text-right">
                <app-btn-action
                    [btnClass]="'btn btn-warning btn-circle btn-lg btn-lateral'"
                    [iconClass]="'fa fa-plus'"
                    (click)="showCreateItemModal()"
                    [disabled]="states.isCollectingForbidden"
                >
                </app-btn-action>
            </div>
        </div>
    </ng-template>
</div>
