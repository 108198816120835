<body>
    <pull-to-refresh></pull-to-refresh>
    <div class="bootstrap-fs-modal">
        <app-nav-menu></app-nav-menu>
        <lib-progress-line [classes]="'position-absolute'"></lib-progress-line>
        <div class="mt-3 p-3">
            <lib-flash-message></lib-flash-message>
            <router-outlet></router-outlet>
        </div>
    </div>
</body>
