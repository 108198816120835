import { Component, Inject, Injector, OnInit } from '@angular/core';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';
import { PaymentType } from 'projects/shared/src/public-api';
import { DeliveryTaskType } from 'shared';
import { DeliveryTaskState } from 'shared';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { UserRole } from 'shared';

@Component({
    selector: 'app-order-data',
    templateUrl: './order-data.component.html',
    styleUrls: ['./order-data.component.scss'],
})
export class OrderDataComponent implements OnInit {
    public isEditMode = false;
    public orderDetails = this._ordersService.orderDetails;
    public hasCollectingTask = false;
    public hasDeliveringTask = false;
    public hasContainerPlacingTask = false;
    public collectingTaskCreationInProgress = false;
    public deliveringTaskCreationInProgress = false;
    public containerPlacingTaskCreationInProgress = false;
    public canCreateDeliveryTask = false;
    public DeliveryTaskType = DeliveryTaskType;
    public DeliveryTaskState = DeliveryTaskState;
    public PaymentType = PaymentType;

    public get areDeliveryTaskButtonsVisible(): boolean {
        return (
            this.canCreateDeliveryTask &&
            (!this.hasCollectingTask || !this.hasDeliveringTask)
        );
    }

    constructor(
        private _ordersService: OrdersService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _serviceProvider: Injector
    ) {}

    ngOnInit(): void {
        this.orderDetails.subscribe((details) => {
            this.canCreateDeliveryTask = this._authService.currentUser.hasRoles([
                UserRole.Administrator,
            ]);
            this.hasCollectingTask = details.deliveryTasks.some(
                (x) => x.taskType === DeliveryTaskType.Collecting
            );
            this.hasDeliveringTask = details.deliveryTasks.some(
                (x) => x.taskType === DeliveryTaskType.Delivering
            );
            this.hasContainerPlacingTask = details.deliveryTasks.some(
                (x) => x.taskType === DeliveryTaskType.ContainerPlacing
            );
        });
    }

    public createCollectingTask(): void {
        this.collectingTaskCreationInProgress = true;
        this._ordersService.createCollectingTask().subscribe(() => {
            this.collectingTaskCreationInProgress = false;
        });
    }

    public createContainerPlacingTask(): void {
        this.containerPlacingTaskCreationInProgress = true;
        this._ordersService.createContainerPlacingTask().subscribe(() => {
            this.containerPlacingTaskCreationInProgress = false;
        });
    }

    public createDeliveringTask(): void {
        this.deliveringTaskCreationInProgress = true;
        this._ordersService.createDeliveringTask().subscribe(() => {
            this.deliveringTaskCreationInProgress = false;
        });
    }
}
