import { Injectable } from '@angular/core';
import { EnvironmentName } from '../types/environment-name';

@Injectable({ providedIn: 'root' })
export class ServiceBaseService {
    public static appInsightsAPIKey = '582dd3ed-7db6-48cf-8ef1-a27ea49acf53'; // Developer insights

    constructor() {}

    public static get getEnvironment(): string {
        // Develop env

        const developEnv = 'dev';
        const developUrlParts: string[] = ['localhost'];

        if (developUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return developEnv;
        }

        var countrycode = location.origin.includes('.at') ? 'at' : 'hu';

        // Master env

        const masterEnv = 'master';
        const masterUrlParts: string[] = ['master'];

        if (masterUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return `${masterEnv}_${countrycode}`;
        }

        // Staging env

        const stagingEnv = 'staging';
        const stagingUrlParts: string[] = ['staging', 'shopalditest.roksh.com', 'draft'];

        if (stagingUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return `${stagingEnv}_${countrycode}`;
        }

        // Production env

        const productionEnv = 'prod';
        const productionUrlParts: string[] = ['roksh', 'shop.aldi.hu'];

        if (productionUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return `${productionEnv}_${countrycode}`;
        }

        return developEnv;
    }

    public static get getEnvironmentName(): EnvironmentName {
        var environment = ServiceBaseService.getEnvironment;

        var environmentName = environment.toUpperCase();

        return EnvironmentName[environmentName as keyof typeof EnvironmentName];
    }

    public static get getLoggerBaseUrl(): string {
        var environmentName = this.getEnvironmentName;

        const productionLoggerBaseUrl = 'https://logservice.roksh.com/api/logger';
        const stagingLoggerBaseUrl =
            'https://weshop-logservice-test.azurewebsites.net/api/logger';
        const devLoggerBaseUrl = 'https://localhost:7237/api/logger';

        if (
            environmentName === EnvironmentName.PROD_HU ||
            environmentName === EnvironmentName.PROD_AT
        ) {
            return productionLoggerBaseUrl;
        } else if (environmentName === EnvironmentName.DEV) {
            return devLoggerBaseUrl;
        }

        return stagingLoggerBaseUrl;
    }

    public static getAppInsightKey(): string {
        // Just the production environments

        if (
            location.origin.includes('https://delivery-manager.roksh') ||
            location.origin.includes('https://www.delivery-manager.roksh')
        ) {
            this.appInsightsAPIKey = 'badb9c90-6849-4d44-9ba9-6edc3ee9173c';
        } else if (
            location.origin.includes('https://provider-manager.roksh') ||
            location.origin.includes('https://www.provider-manager.roksh')
        ) {
            this.appInsightsAPIKey = '3c77a28f-585c-4694-aa47-5f90e6cf0242';
        } else if (location.origin.includes('localhost')) {
            // Developer insights

            this.appInsightsAPIKey = '582dd3ed-7db6-48cf-8ef1-a27ea49acf53';
        }

        return this.appInsightsAPIKey;
    }
}
