import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICreateReplacementItem, OrderItem, realRound } from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IOrdersService, ORDERS_SERVICE_IMPL } from 'shared';
import { skip, takeUntil } from 'rxjs/operators';
import { CollectingStatesService } from '../../collecting-states.service';
import {
    QuantityComponent,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';

@Component({
    selector: 'app-replacement-editor-modal',
    templateUrl: './replacement-editor-modal.component.html',
    styleUrls: ['./replacement-editor-modal.component.scss'],
})
export class ReplacementEditorModalComponent implements OnInit, OnDestroy {
    private _destroy$: ReplaySubject<boolean>;
    public replacementItemEditorForm: FormGroup;
    public orderItem: OrderItem;
    public isSubmitted: boolean;
    public optionalDepositProductQuantity = 0;

    public get fields() {
        return this.replacementItemEditorForm.controls;
    }

    public get quantityStep(): string {
        return this.orderItem.isBulk ? '.001' : '1';
    }

    public get quantityDecimalNumber(): number {
        return this.orderItem.isBulk ? 3 : 0;
    }

    public get quantityInputMode(): string {
        return this.orderItem.isBulk ? 'decimal' : 'numeric';
    }

    public get validatorPattern(): string {
        return this.orderItem.isBulk
            ? '^((\\d+[\\,\\.]?|[\\,\\.](?=\\d))?\\d{0,3})$'
            : '^([1-9][0-9]*)?$';
    }

    @ViewChild('replacementEditorModal', { static: false })
    private _replacementEditorModal: TemplateRef<any>;

    @ViewChildren('quantityComponent')
    quantity: QuantityComponent;

    constructor(
        private _collectingItemsService: CollectingItemsService,
        private _modalService: NgbModal,
        private _formBuilder: FormBuilder,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        public states: CollectingStatesService
    ) {
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit(): void {
        this._collectingItemsService.replacementItemToEdit
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((orderItem) => {
                this.orderItem = orderItem;

                this.initEditorForm();
                this._modalService.open(this._replacementEditorModal, {
                    windowClass: 'modal-holder',
                    centered: true,
                    size: 'sm',
                });
                if (this.orderItem.isBulk) {
                    this.setInputFilter(document.getElementById('quantity'), (value) => {
                        const isValid = /^((\d+[\,\.]?|[\,\.](?=\d))?\d{0,3})$/.test(
                            value
                        );
                        if (value && isValid) {
                            const newValue = (<HTMLInputElement>(
                                document.getElementById('quantity')
                            )).value;
                            this.fields.quantity.setValue(newValue);
                        }
                        return isValid;
                    });
                } else {
                    //only positive int
                    this.setInputFilter(document.getElementById('quantity'), (value) => {
                        const isValid = /^([1-9][0-9]*)?$/.test(value);
                        if (value && isValid) {
                            const newValue = (<HTMLInputElement>(
                                document.getElementById('quantity')
                            )).value;
                            this.fields.quantity.setValue(newValue);
                        }
                        return isValid;
                    });
                }
            });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    changeDepositValue(event: any): void {
        this.optionalDepositProductQuantity = event;
    }

    public onSubmit(): void {
        if (this.orderItem.isBulk) {
            if (isNaN(this.fields.quantity.value)) {
                this.fields.quantity.setValue(
                    parseFloat(
                        this.fields.quantity.value.replace(',', '.').replace(' ', '')
                    )
                );
            } else {
                this.fields.quantity.setValue(parseFloat(this.fields.quantity.value));
            }
            if (this.fields.quantity.value <= 0)
                this.fields.quantity.setErrors({ pattern: true });
        } else {
            this.fields.quantity.setValue(parseInt(this.fields.quantity.value));
        }

        this.isSubmitted = true;

        if (this.replacementItemEditorForm.invalid) {
            this.isSubmitted = false;
            return;
        }

        const replacementItemData: ICreateReplacementItem = {
            orderItemID: this.orderItem.orderItemID,
            replacementItemName: this.fields.name.value,
            replacementItemQuantity: this.fields.quantity.value,
            replacementItemUnitPrice: this.fields.unitPrice.value,
            vat: this.orderItem.vat,
            sku: this.orderItem.sku,
            unit: this.orderItem.unit,
            offlineProductID: this.orderItem.providerOfflineProductID,
            isWerwProduct: this.orderItem.isWerwProduct,
            isBulk: this.orderItem.isBulk,
            isCollecting: true,
            optionalDepositProductQuantity: this.optionalDepositProductQuantity,
            orderReplacementItemDepositProductList: null,
            orderItemDepositProductList: null,
        };

        this._collectingItemsService.createReplacementOrderItem(
            this.orderItem,
            replacementItemData
        );

        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.AddReplacedOrderItemToBasket;
        orderStateEventLog.fromStateJson = JSON.stringify({
            originalOrderItem: this.states?.replaceableOrderItem,
        });
        orderStateEventLog.toStateJson = JSON.stringify({
            replacedOrderItem: replacementItemData,
        });
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._modalService.dismissAll();
    }

    private initEditorForm(): void {
        this.replacementItemEditorForm = this._formBuilder.group({
            name: [this.orderItem.orderItemName, Validators.required],
            quantity: [0, [Validators.pattern(this.validatorPattern)]],
            unitPrice: [this.orderItem.unitPrice, Validators.required],
            orderedQuantity: this.orderItem.orderedQuantity,
        });
    }

    setInputFilter(textbox: Element, inputFilter: (value: string) => boolean): void {
        [
            'input',
            'keydown',
            'keyup',
            'mousedown',
            'mouseup',
            'select',
            'contextmenu',
            'drop',
        ].forEach(function (event) {
            textbox.addEventListener(
                event,
                function (
                    this: (HTMLInputElement | HTMLTextAreaElement) & {
                        oldValue: string;
                        oldSelectionStart: number | null;
                        oldSelectionEnd: number | null;
                    }
                ) {
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                    } else if (Object.prototype.hasOwnProperty.call(this, 'oldValue')) {
                        this.value = this.oldValue;
                        if (
                            this.oldSelectionStart !== null &&
                            this.oldSelectionEnd !== null
                        ) {
                            this.setSelectionRange(
                                this.oldSelectionStart,
                                this.oldSelectionEnd
                            );
                        }
                    } else {
                        this.value = '';
                    }
                }
            );
        });
    }
}
