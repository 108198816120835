import { PaymentType } from '../types/payment-type';
import { DeliveryTaskInfo } from './delivery-task-info';

export class OrderDetails {
    public orderNotes: string;
    public deliveryTasks: DeliveryTaskInfo[];
    public hasReservedPayment: boolean;
    public deliveryFee: number;
    public totalPrice: number;
    public finishedTotalPrice: number;
    public customerTotalPrice: number;
    public paidTotalPrice: number;
    public totalPriceDiscount: number;
    public customerPaidTotalPrice: number;
    public isTotalPriceDiscounted: boolean;
    public superOrderCode: string | undefined;
    public orderCodeList: string[] | undefined;
    public isSuperOrder: boolean;
    public usePaperBag: boolean;
    public paymentType: PaymentType;
    public isRokshDiscounted: boolean;
    public rokshDiscountTotalPrice: number;
    public rokshTipAmount: number;
    public systemUsageFee: number;
    public smallOrderFee: number;
    public hasRokshTip: boolean;
    public hasSystemUsageFee: boolean;
    public consultationType: string;
    public supportComment: string;
}
