import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[libNumeric]',
})
export class NumericDirective {
    @Input('decimals') decimals = 0;
    @Input('negative') negative = 0;
    @Input('separator') separator = '.';

    private lastValidValue = '';

    private checkAllowNegative(value: string): boolean {
        if (this.decimals <= 0) {
            return new RegExp(/^\d+$/).test(value);
        } else {
            const regExpString =
                '^-?s*((\\d+(\\' +
                this.separator +
                '\\d{0,' +
                this.decimals +
                '})?)|((\\d*(\\' +
                this.separator +
                '\\d{0,' +
                this.decimals +
                '}))))\\s*$';
            const regexp = new RegExp(regExpString);
            return regexp.test(value);
        }
    }

    private check(value: string): boolean {
        if (this.decimals <= 0) {
            return new RegExp(/^\d+$/).test(value);
        } else {
            const regExpString =
                '^\\s*((\\d+(\\' +
                this.separator +
                '\\d{0,' +
                this.decimals +
                '})?)|((\\d*(\\' +
                this.separator +
                '\\d{0,' +
                this.decimals +
                '}))))\\s*$';
            const regexp = new RegExp(regExpString);
            return regexp.test(value);
        }
    }

    private run(clearIfNotValid = false): void {
        setTimeout(() => {
            const currentValue: string = this.el.nativeElement.value;
            const allowNegative = this.negative > 0;

            if (
                allowNegative &&
                !['', '-'].includes(currentValue) &&
                !this.checkAllowNegative(currentValue)
            ) {
                this.el.nativeElement.value = clearIfNotValid ? '' : this.lastValidValue;
            } else if (currentValue.length > 0 && !this.check(currentValue)) {
                this.el.nativeElement.value = clearIfNotValid ? '' : this.lastValidValue;
            } else {
                this.lastValidValue = currentValue;
            }
        }, 300);
    }

    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        this.run();
    }

    @HostListener('paste', ['$event'])
    onPaste(): void {
        this.run(true);
    }
}
