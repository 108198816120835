<span
    *ngIf="!isReturnInProgress && orderItem.orderReturnItemList.length == 0"
    class="badge badge-info"
    >No return</span
>
<span
    *ngIf="!isReturnInProgress && orderItem.orderReturnItemList.length > 0"
    class="badge badge-warning"
    >Return</span
>

<div *ngIf="isReturnInProgress">
    <div *ngIf="orderItem.orderReturnItemList.length > 0">
        <span>{{ 'order-return.return-history' | translate }}:</span><br />
        <ng-container *ngFor="let orderReturnItem of orderItem.orderReturnItemList">
            <div
                *ngIf="
                    orderReturnItem.orderItemDepositProductID == null &&
                    orderReturnItem.orderReplacementItemDepositProductID == null &&
                    !orderItemDepositProduct &&
                    !orderReplacementItemDepositProduct
                "
            >
                {{ orderReturnItem.quantity }} {{ orderItem.unit }}-
                <app-state-badge
                    [state]="OrderReturnItemState[orderReturnItem.orderReturnItemStateID]"
                ></app-state-badge>
            </div>

            <div
                *ngIf="
                    orderReturnItem.orderItemDepositProductID != null &&
                    orderReturnItem.orderItemDepositProductID ==
                        orderItemDepositProduct?.orderItemDepositProductID
                "
            >
                {{ orderReturnItem.quantity }} {{ orderItem.unit }}-
                <app-state-badge
                    [state]="OrderReturnItemState[orderReturnItem.orderReturnItemStateID]"
                ></app-state-badge>
            </div>
            <div
                *ngIf="
                    orderReturnItem.orderReplacementItemDepositProductID != null &&
                    orderReturnItem.orderReplacementItemDepositProductID ==
                        orderReplacementItemDepositProduct?.orderReplacementItemDepositProductID
                "
            >
                {{ orderReturnItem.quantity }} {{ orderItem.unit }}-
                <app-state-badge
                    [state]="OrderReturnItemState[orderReturnItem.orderReturnItemStateID]"
                ></app-state-badge>
            </div>
        </ng-container>
        <hr />
    </div>

    <br />
    <input
        class="form-control"
        type="number"
        inputmode="decimal"
        min="0"
        max="{{ maxRefundableQunatity }}"
        placeholder="Refund quantity"
        [(ngModel)]="returningQuantity"
        (input)="
            setOrderReturnItemList(
                orderItem.orderItemID,
                orderItemDepositProduct?.orderItemDepositProductID,
                orderReplacementItemDepositProduct?.orderReplacementItemDepositProductID,
                $event.target.value
            )
        "
    />
</div>
