import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SchedulerEvent } from 'dm-src/models/scheduler-event';
import {
    ISuppliersService,
    SUPPLIERS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isuppliers-service';
import { SupplierWorktime } from 'dm-src/models/supplier-worktime';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { HttpResponse } from '@angular/common/http';
import {
    ISupplierResourcesService,
    SUPPLIER_RESOURCES_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isupplier-resources-service';
import { SchedulerTimeslot } from 'dm-src/types/scheduler-timeslot';
import { CreateSupplierWorktimeResponse } from 'dm-src/dtos/create-supplier-worktime-response';
import { UserRole } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class MyWorktimesService {
    private _currentSupplierID: string;
    private _schedulerEvents: BehaviorSubject<SchedulerEvent[]>;
    private _availableTimeslots: BehaviorSubject<SchedulerTimeslot[]>;

    public get schedulerEvents(): Observable<SchedulerEvent[]> {
        return this._schedulerEvents.asObservable();
    }

    public get availableTimeslots(): Observable<SchedulerTimeslot[]> {
        return this._availableTimeslots.asObservable();
    }

    public get currentAvailableTimeslots(): SchedulerTimeslot[] {
        return this._availableTimeslots.getValue();
    }

    public canUserRemoveEveryWorktime: boolean;

    constructor(
        @Inject(SUPPLIERS_SERVICE_IMPL)
        private _suppliersService: ISuppliersService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        @Inject(SUPPLIER_RESOURCES_SERVICE_IMPL)
        private _supplierResourcesService: ISupplierResourcesService
    ) {
        this._schedulerEvents = new BehaviorSubject<SchedulerEvent[]>([]);
        this._availableTimeslots = new BehaviorSubject<SchedulerTimeslot[]>([]);
        this.canUserRemoveEveryWorktime = this._authService.currentUser.hasRoles([
            UserRole.Administrator,
        ]);
    }

    public getSechedulerEvents() {
        this._suppliersService
            .getSupplierWorktimes(
                this._currentSupplierID ?? this._authService.currentUser.userID
            )
            .subscribe((response) => {
                if (response.status === 200) {
                    response.body.map((event, index) => {
                        event.Id = index;
                        event.StartTime = new Date(event.StartTime);
                        event.EndTime = new Date(event.EndTime);
                        return event;
                    });
                    this._schedulerEvents.next(response.body);
                }
            });
    }

    public createSchedulerEvent(
        event: SchedulerEvent
    ): Observable<HttpResponse<CreateSupplierWorktimeResponse>> {
        const supplierWorktime = this.getSupplierWorktime(event);
        return this._suppliersService.createSupplierWorktime(supplierWorktime);
    }

    public updateSchedulerEvent(event: SchedulerEvent) {
        const supplierWorktime = this.getSupplierWorktime(event);

        return this._suppliersService.updateSupplierWorktime(supplierWorktime);
    }

    public deleteSchedulerEvent(
        supplierWorktimeID: string
    ): Observable<HttpResponse<void>> {
        return this._suppliersService.deleteSupplierWorktime(supplierWorktimeID);
    }

    public getAvailableTimeSlots(): void {
        this._supplierResourcesService
            .getAvailableTimeSlots(
                this._currentSupplierID ?? this._authService.currentUser.userID
            )
            .subscribe((response) => {
                if (response.status === 200 && response.body !== null) {
                    const timeslots = response.body.map((timeslot) => {
                        timeslot.slotStart = new Date(timeslot.slotStart);
                        timeslot.slotEnd = new Date(timeslot.slotEnd);
                        return timeslot;
                    });
                    this._availableTimeslots.next(timeslots);
                }
            });
    }

    public onSupplierFilterChanged(value: string) {
        this._currentSupplierID = value;
        this.getAvailableTimeSlots();
        this.getSechedulerEvents();
    }

    public reset() {
        this._currentSupplierID = null;
    }

    private getSupplierWorktime(event: SchedulerEvent): SupplierWorktime {
        const supplierWorktime = new SupplierWorktime();
        supplierWorktime.supplierID =
            this._currentSupplierID ?? this._authService.currentUser.userID;
        if (event.EventID) {
            supplierWorktime.supplierWorktimeID = event.EventID;
        }
        supplierWorktime.worktimeStart = event.StartTime;
        supplierWorktime.worktimeEnd = event.EndTime;

        return supplierWorktime;
    }
}
