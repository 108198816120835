import { Component, OnInit } from '@angular/core';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    isApplication: boolean;
    osType: string;

    constructor(
        private isWrappingService: IsWrappingService,
        private iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        if (window.ReactNativeWebView) {
            this.isApplication = true;
        } else if (this.isWrappingService.isWrapping()) {
            this.isApplication = true;
        } else {
            this.isApplication = false;
        }
        this.osType = this.iosAndAndroidNatvieFunctionsService.getMobileOperatingSystem();
    }

    ngOnInit(): void {}

    handleLoginClick(email: string) {
        const loggedIn = new Date();
        this.iosAndAndroidNatvieFunctionsService.oneSignalSetEmail(email);
        this.iosAndAndroidNatvieFunctionsService.oneSignalSetTag('UserType', 'Delivery');
        this.iosAndAndroidNatvieFunctionsService.oneSignalSetTag(
            'LastLogin',
            this.formatDate(loggedIn)
        );
    }

    private formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    }
}
