import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class IsWrappingService {
    constructor() {}
    isWrapping(): boolean {
        return (
            localStorage.getItem('is_wrapping') == '1' &&
            !window.location.origin.includes('localhost')
        );
    }
}
