import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-worktime-entry-editor',
    templateUrl: './worktime-entry-editor.component.html',
    styleUrls: ['./worktime-entry-editor.component.scss'],
})
export class WorktimeEntryEditorComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
