import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SupplierRegionsData } from 'dm-src/models/supplier-regions-data';
import { SupplierRegion } from 'dm-src/models/supplier-region';
import { ISelectOption } from 'shared';
import { ProviderShop } from '../../models/provider-shop';

export const PROVIDERSHOP_SERVICE_IMPL = new InjectionToken<IProviderShopService>(
    'providerShopServiceImpl'
);

export interface IProviderShopService {
    getProviderShopOptions(): Observable<HttpResponse<ISelectOption[]>>;
}
