<span *ngIf="!isSuperOrder">{{ 'orders.delivery-fee' | translate }}: </span>
<span *ngIf="isSuperOrder">{{ 'orders.multi-order-delivery-fee' | translate }}: </span>

<form [formGroup]="deliveryFeeForm" *ngIf="isEditMode; else labelTemplate">
    <div class="form-inline">
        <input
            type="number"
            class="form-control"
            placeholder="Enter delivery fee"
            formControlName="deliveryFee"
            name="deliveryFee"
            maxlength="5"
        />
        <div *ngIf="isSubmitted && fields.deliveryFee.errors" class="invalid-feedback">
            <div *ngIf="fields.notes.errors.required">DeliveryFee is required</div>
        </div>
    </div>
</form>

<ng-template #labelTemplate>
    <strong>
        {{
            orderDetails?.deliveryFee?.toLocaleString(undefined, {
                minimumFractionDigits: 2
            })
        }}
        {{ 'common.currency' | translate }}
    </strong>
</ng-template>

<div class="btn-group mt-1" role="group">
    <button
        *ngIf="!isEditMode"
        type="button"
        class="btn btn-warning"
        (click)="isEditMode = !isEditMode"
        [disabled]="!orderDetails?.hasReservedPayment"
    >
        Edit
    </button>
    <button
        *ngIf="isEditMode"
        type="button"
        class="btn btn-primary"
        (click)="updateDeliveryFee()"
    >
        Save
    </button>
    <button
        *ngIf="isEditMode"
        type="button"
        class="btn btn-danger"
        (click)="isEditMode = !isEditMode"
    >
        Cancel
    </button>
</div>
