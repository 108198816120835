import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class WishListService {
    constructor(
        @Inject(API_CLIENT_SERVICE_IMPL)
        private _apiClient: IAPIClientService
    ) {}

    public updateWishListOrder(
        wishListOrderID: string,
        totalPrice: number,
        invoicePhotoContent: string,
        invoicePhotoExtension: string
    ): Observable<HttpResponse<boolean>> {
        return this._apiClient.postWithResponse<boolean>(
            'wishlist/upload-invoice-photo',
            {
                WishListOrderID: wishListOrderID,
                InvoicePhotoContent: invoicePhotoContent,
                InvoicePhotoExtension: invoicePhotoExtension,
                TotalPrice: totalPrice,
            }
        );
    }
}
