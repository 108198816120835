import { Component, Input, Inject } from '@angular/core';
import {
    OrderItem,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    EventType,
    ActionType,
    OrderStateEventLog,
} from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { CollectingStatesService } from '../../../collecting-states.service';

@Component({
    selector: '[app-items-not-found-buttons]',
    templateUrl: './items-not-found-buttons.component.html',
    styleUrls: ['./items-not-found-buttons.component.scss'],
})
export class ItemsNotFoundButtonsComponent {
    @Input() orderItem: OrderItem;

    constructor(
        public states: CollectingStatesService,
        private _orderItemsService: CollectingItemsService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService
    ) {}

    public cancel() {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.NotFoundOrderItemCancelButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._orderItemsService.removeOrderItemFromNotFound(this.orderItem);
    }
}
