import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISelectOption } from 'shared';

@Component({
    selector: 'app-zip-badge',
    templateUrl: './zip-badge.component.html',
    styleUrls: ['./zip-badge.component.scss'],
})
export class ZipBadgeComponent implements OnInit {
    @Input() public zipCode: ISelectOption;
    @Output() public onDelete = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {}

    public deleteZip(zipID: string) {
        this.onDelete.emit(zipID);
    }
}
