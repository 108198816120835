<div class="container">
    <div class="card card-container">
        <h2 class="card-title">{{ 'login.change-password' | translate }}</h2>
        <div *ngIf="showSuccess" class="alert alert-success" role="alert">
            {{ 'login.password-changed' | translate }}
            <a [routerLink]="['/login']">{{ 'common.login' | translate }}</a>
        </div>
        <form
            [formGroup]="changePasswordForm"
            (ngSubmit)="onSubmitChangePassword()"
            class="form-signin"
        >
            <input
                type="password"
                id="inputPassword"
                formControlName="password"
                class="form-control"
                placeholder="{{ 'userform-common.enter-password' | translate }}"
                required
                [ngClass]="{ 'is-invalid': isSubmitted && fields.password.errors }"
            />
            <div *ngIf="isSubmitted && fields.password.errors" class="invalid-feedback">
                <div *ngIf="fields.password.errors.required">
                    {{ 'userform-common.enter-password' | translate }}
                </div>
            </div>
            <input
                type="password"
                id="confirmPassword"
                formControlName="confirmPassword"
                class="form-control"
                placeholder="{{ 'userform-common.confirm-password' | translate }}"
                required
                [ngClass]="{
                    'is-invalid': isSubmitted && fields.confirmPassword.errors
                }"
            />
            <div
                *ngIf="isSubmitted && fields.confirmPassword.errors"
                class="invalid-feedback"
            >
                <div *ngIf="fields.confirmPassword.errors.required">
                    {{ 'userform-common.password-confirmation' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
                <div *ngIf="fields.confirmPassword.errors.mustMatch">
                    {{ 'userform-common.passwords-must-match' | translate }}
                </div>
            </div>
            <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                {{ 'common.submit' | translate }}
            </button>
        </form>
    </div>
</div>
