import { Component, Input, OnInit } from '@angular/core';
import { Order } from 'dm-src/models/order';
import { MarkupCorrectionDto } from 'projects/shared/src/lib/dtos/markup-correction';
import { ColorClass, FlashMessageService } from 'projects/shared/src/public-api';
import { finalize } from 'rxjs/operators';
import { OrdersService } from '../../orders/orders.service';
import { saveAs } from 'file-saver';
import { environment } from 'dm-src/environments/environment';

@Component({
    selector: 'app-order-correction',
    templateUrl: './order-correction.component.html',
    styleUrls: ['./order-correction.component.scss'],
})
export class OrderCorrectionComponent implements OnInit {
    @Input() order: Order;
    public deliveryDateFormat: string = environment.deliveryDateFormat;
    public invoiceDate: Date;
    public invoiceNumber: string = '';
    public isLoading = false;
    public apiError = '';

    constructor(
        private _ordersService: OrdersService,
        private _flashMessageService: FlashMessageService
    ) {}

    ngOnInit(): void {}

    correctStock(): void {
        const correctionDto: MarkupCorrectionDto = {
            orderID: this.order.orderID,
            orderCode: this.order.orderCode,
            date: this.invoiceDate,
            invoiceNumber: this.invoiceNumber,
        };
        this.apiError = '';
        this.isLoading = true;
        this._ordersService
            .correctStockForOrder(correctionDto)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (result) => {
                    this._flashMessageService.showMessage(
                        'Stock Correction',
                        'Correction succeeded.',
                        null,
                        ColorClass.Success
                    );
                },
                (err) => {
                    this.apiError = 'ERROR ' + err.error;
                }
            );
    }

    correctInvoiceAndStock(): void {
        const correctionDto: MarkupCorrectionDto = {
            orderID: this.order.orderID,
            orderCode: this.order.orderCode,
            date: this.invoiceDate,
            invoiceNumber: this.invoiceNumber,
        };
        this.apiError = '';
        this.isLoading = true;
        this._ordersService
            .correctInvoiceAndStockForOrder(correctionDto)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (result) => {
                    this._flashMessageService.showMessage(
                        'Invoice & Stock Correction',
                        'Correction succeeded.',
                        null,
                        ColorClass.Success
                    );
                },
                (err) => {
                    this.apiError = 'ERROR ' + err.error;
                }
            );
    }

    generateInvoiceJpl(): void {
        this.apiError = '';
        this.isLoading = true;
        this._ordersService
            .generateInvoiceJpl(this.order?.orderID)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (res) => {
                    if (res?.ok && res?.status !== 204) {
                        const fileName = res?.headers?.get('x-file-name');
                        saveAs(res?.body, fileName);
                    }
                },
                (err) => {
                    this.apiError = 'ERROR ' + err.error;
                }
            );
    }

    createCorrectionInvoice(): void {
        const correctionDto: MarkupCorrectionDto = {
            orderID: this.order.orderID,
            orderCode: this.order.orderCode,
            date: this.invoiceDate,
            invoiceNumber: this.invoiceNumber,
        };
        this.apiError = '';
        this.isLoading = true;
        this._ordersService
            .correctInvoiceForOrder(correctionDto)
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                (result) => {
                    this._flashMessageService.showMessage(
                        'Invoice Correction',
                        'Correction succeeded.',
                        null,
                        ColorClass.Success
                    );
                },
                (err) => {
                    this.apiError = 'ERROR ' + err.error;
                }
            );
    }
}
