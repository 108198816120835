<h1>{{ 'supplier-tracking.title' | translate }}</h1>

<div class="sideleft p-2">
    <input
        class="form-control"
        type="date"
        [(ngModel)]="date"
        (change)="refreshWorkers(true)"
    />

    <br />

    <angular2-multiselect
        id="supplier-regions"
        name="supplierRegions"
        [(ngModel)]="regionsModel"
        [data]="supplierRegions"
        [settings]="regionsSettings"
        (onSelect)="onRegionSelect($event)"
    >
    </angular2-multiselect>

    <hr />
    <h5>{{ 'supplier-tracking.supplier-list-title' | translate }}</h5>

    <ul class="list-group w-100">
        <li
            *ngFor="let worker of curWorkerList; let i = index"
            [ngClass]="{ selected: worker == selectedWorker }"
            class="list-group-item d-flex justify-content-between align-items-center"
            (click)="showWorkerDetails(worker, true)"
        >
            {{ worker.supplierName }}

            <div>
                <!--<img style="width:13px" src="assets/svg/location.svg" />&nbsp;-->
                <img src="assets/svg/role_{{ worker.roleID }}.svg" />&nbsp;
                <div [ngClass]="getDot(worker)"></div>
                <span></span>
            </div>
        </li>
    </ul>

    <!--
  <div class="card mt-3">
    <div class="card-header">
      {{ "supplier-tracking.region-select-supplier" | translate }}
    </div>
    <div class="card-body">
      <angular2-multiselect
        id="supplier-names"
        name="supplierNames"
        [(ngModel)]="suppliersModel"
        [data]="suppliers"
        [settings]="suppliersSettings"
        (onSelect)="onSupplierSelect($event)"
      >
      </angular2-multiselect>
    </div>
  </div>
    -->
</div>

<div class="middle">
    <div class="map-container">
        <mgl-map
            [style]="'mapbox://styles/mapbox/streets-v9'"
            [zoom]="[6]"
            [center]="[lon0, lat0]"
            (load)="loadMap($event)"
        >
        </mgl-map>
    </div>
</div>
<div class="sideright">
    <ng-container *ngIf="!selectedOrder">
        <ng-container *ngIf="selectedWorker">
            <h5 class="text-center">{{ selectedWorker.supplierName }}</h5>
            <table class="table table-borderless table-condensed">
                <tr>
                    <td align="right">Jármű típusa:</td>
                    <td>{{ selectedWorker.vehicleTypeName }}</td>
                </tr>
                <tr>
                    <td align="right">Munkaidő:</td>
                    <td>
                        <div *ngFor="let time of selectedWorker.workTimeList">
                            {{ time }}
                        </div>
                    </td>
                </tr>
            </table>
        </ng-container>

        <hr />
        <h5>{{ 'supplier-tracking.supplier-orders-title' | translate }}</h5>

        <ul class="list-group w-100">
            <li
                (click)="selectOrder(order)"
                *ngFor="let order of filteredOrderList; let i = index"
                [ngClass]="{ selected: order == selectedOrder }"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                {{ order.orderCode }}
                <div>
                    <span
                        >{{ order.orderStateName }}&nbsp;&nbsp;&nbsp;{{
                            order.timeSlot
                        }}&nbsp;&nbsp;</span
                    >
                    <div [ngClass]="getOrderDot(order)"></div>
                </div>
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="selectedOrder">
        <div>
            <a class="btn btn-md btn-light" (click)="selectedOrder = null"
                ><i class="fa fa-arrow-left"></i>&nbsp;{{ 'common.back' | translate }}</a
            >
            {{ selectedOrder.orderCode }} &nbsp;&nbsp;&nbsp;{{
                selectedOrder.totalPrice
            }}
            Ft. ({{ selectedOrder.deliveryFee }} Ft.)
        </div>

        <div>
            {{ selectedOrder.deliveryAddress.addressName }}<br />
            {{ selectedOrder.deliveryAddress.postalCode }}
            {{ selectedOrder.deliveryAddress.cityName }}
            {{ selectedOrder.deliveryAddress.addressLine }}
        </div>

        <ul class="list-group w-100">
            <li
                *ngFor="let h of selectedOrder.history; let i = index"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                {{ h.orderStateName }}
                <div>
                    <span>{{ h.created | date : 'yyyy-MM-dd HH:mm' }} </span>
                </div>
            </li>
        </ul>
    </ng-container>
</div>
