<lib-modal
    [title]="'collecting.no-offline-product-found-title'"
    [componentID]="'unidentified-item-modal'"
>
    <p>{{ 'collecting.no-offline-product-found-text' | translate }}</p>
    <p *ngIf="canOpenManualSKUChecker">
        {{ 'collecting.you-can-give-sku-manually' | translate }}
    </p>
    <div class="btn-toolbar justify-content-between">
        <div class="btn-group">
            <button
                *ngIf="canOpenManualSKUChecker"
                class="btn btn-primary"
                (click)="showManualSKUCheckModal()"
            >
                <i class="fa fa-info"></i>{{ 'collecting.type-sku' | translate }}
            </button>
            <button class="btn btn-danger" (click)="closeModal()">
                <i class="fa fa-undo"></i>{{ 'common.cancel' | translate }}
            </button>
        </div>
    </div>
</lib-modal>
