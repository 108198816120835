<div class="container">
    <div class="row justify-content-center">
        <div class="col-12" [ngClass]="{ 'col-lg-6': !isEditMode }">
            <div class="card">
                <div class="card-header bg-secondary">Customer details</div>
                <div class="card-body" *ngIf="isEditMode">
                    <form class="form-horizontal" [formGroup]="contactDataForm">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="last-name" class="control-label"
                                    >Lastname</label
                                >
                                <div class="input-group is-invalid">
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="lastName"
                                        id="last-name"
                                        placeholder="Enter Customer Lastname"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.lastName.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.lastName.errors.required">
                                        Customer's last name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="first-name" class="control-label"
                                    >Firstname</label
                                >
                                <div class="input-group is-invalid">
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="firstName"
                                        id="first-name"
                                        placeholder="Enter Customer Firstname"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.firstName.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.firstName.errors.required">
                                        Customer's first name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label for="email" class="control-label">Email</label>
                                <div class="input-group is-invalid">
                                    <input
                                        type="email"
                                        class="form-control"
                                        formControlName="email"
                                        id="email"
                                        placeholder="Enter Customer Email"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.email.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.email.errors.required">
                                        Customer's email is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="wrapper mt-4 mb-0">
                                    <mat-label class="custom-label w-100">
                                        Phone Number *</mat-label
                                    >
                                    <ngx-intl-tel-input
                                        [cssClass]="
                                            ' w-100 p-2 form-control tel-input ' +
                                            (fields.phone.errors
                                                ? 'tel-input-invalid'
                                                : '')
                                        "
                                        id="phone"
                                        #ngTelInput
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[
                                            SearchCountryField.Iso2,
                                            SearchCountryField.Name
                                        ]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="preferredCountries[0]"
                                        [maxLength]="15"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        [numberFormat]="PhoneNumberFormat.National"
                                        name="phone"
                                        formControlName="phone"
                                        (ngModelChange)="onPhoneChanges()"
                                    >
                                    </ngx-intl-tel-input>
                                    <small
                                        class="text-danger mt-1 ps-2"
                                        *ngIf="
                                            fields.phone.errors && fields.phone.touched
                                        "
                                    >
                                        Customer's phone number is required
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div formGroupName="deliveryAddress">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label for="postal-code" class="control-label"
                                        >Postal Code</label
                                    >
                                    <div class="input-group is-invalid">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="postalCode"
                                            id="postal-code"
                                            placeholder="Enter Postal Code"
                                        />
                                    </div>
                                    <div
                                        *ngIf="
                                            isSubmitted &&
                                            getAddressField('postalCode').errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                getAddressField('postalCode').errors
                                                    .required
                                            "
                                        >
                                            Delivery postal code is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="county-name" class="control-label"
                                        >County Name</label
                                    >
                                    <div class="input-group is-invalid">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="countyName"
                                            id="county-name"
                                            placeholder="Enter County Name (Budapest)"
                                        />
                                    </div>
                                    <div
                                        *ngIf="
                                            isSubmitted &&
                                            getAddressField('countyName').errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                getAddressField('countyName').errors
                                                    .required
                                            "
                                        >
                                            DeliveryAddress county name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label for="city-name" class="control-label"
                                        >City Name</label
                                    >
                                    <div class="input-group is-invalid">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="cityName"
                                            id="city-name"
                                            placeholder="Enter Address Line (district)"
                                        />
                                    </div>
                                    <div
                                        *ngIf="
                                            isSubmitted &&
                                            getAddressField('cityName').errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                getAddressField('cityName').errors
                                                    .required
                                            "
                                        >
                                            Delivery address city name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="address-line" class="control-label"
                                        >Address Line</label
                                    >
                                    <div class="input-group is-invalid">
                                        <input
                                            type="text"
                                            class="form-control"
                                            formControlName="addressLine"
                                            id="address-line"
                                            placeholder="Enter Address Line (street..)"
                                        />
                                    </div>
                                    <div
                                        *ngIf="
                                            isSubmitted &&
                                            getAddressField('addressLine').errors
                                        "
                                        class="invalid-feedback"
                                    >
                                        <div
                                            *ngIf="
                                                getAddressField('addressLine').errors
                                                    .required
                                            "
                                        >
                                            Delivery address line is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div
                    class="card-body customer-contact-data"
                    *ngIf="!isEditMode && customerData | async"
                >
                    <table>
                        <tr>
                            <td>Name:</td>
                            <td>
                                {{ (customerData | async).lastName }}
                                {{ (customerData | async).firstName }}
                            </td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>
                                {{ (customerData | async).email }}
                            </td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>
                                {{ (customerData | async).phone }}
                            </td>
                        </tr>
                        <tr>
                            <td>Delivery Address:</td>
                            <td>
                                {{ (customerData | async).deliveryAddress.postalCode }}
                                {{ (customerData | async).deliveryAddress.countyName }},
                                {{ (customerData | async).deliveryAddress.cityName }},
                                {{ (customerData | async).deliveryAddress.addressLine }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="card-footer bg-secondary">
                    <div class="btn-group" role="group">
                        <button
                            *ngIf="!isEditMode"
                            type="button"
                            class="btn btn-warning"
                            (click)="isEditMode = !isEditMode"
                        >
                            Edit
                        </button>
                        <button
                            *ngIf="isEditMode"
                            type="button"
                            class="btn btn-primary"
                            (click)="updateContactData()"
                        >
                            Save
                        </button>
                        <button
                            *ngIf="isEditMode"
                            type="button"
                            class="btn btn-danger"
                            (click)="isEditMode = !isEditMode"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
