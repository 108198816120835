<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">Add new task</div>
                <div class="card-body">
                    <div *ngIf="errorMessage">
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                            <strong>Error: </strong>{{ errorMessage }}
                        </div>
                    </div>
                    <form
                        class="form-horizontal"
                        [formGroup]="taskForm"
                        (ngSubmit)="onSubmit()"
                    >
                        <div class="form-group">
                            <label for="name" class="cols-sm-2 control-label"
                                >Assigned Order</label
                            >
                            <div class="cols-sm-10">
                                <div class="input-group">
                                    <span class="input-group-addon"
                                        ><i class="fa fa-user fa" aria-hidden="true"></i
                                    ></span>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="firstName"
                                        id="first-name"
                                        placeholder="Assign a user"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="cols-sm-2 control-label"
                                >Assigned User</label
                            >
                            <div class="cols-sm-10">
                                <div class="input-group">
                                    <span class="input-group-addon"
                                        ><i class="fa fa-user fa" aria-hidden="true"></i
                                    ></span>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="lastName"
                                        id="name"
                                        placeholder="Enter order code"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email" class="cols-sm-2 control-label"
                                >Name</label
                            >
                            <div class="cols-sm-10">
                                <div class="input-group">
                                    <span class="input-group-addon"
                                        ><i
                                            class="fa fa-envelope fa"
                                            aria-hidden="true"
                                        ></i
                                    ></span>
                                    <input
                                        type="email"
                                        class="form-control"
                                        formControlName="email"
                                        id="email"
                                        placeholder="Enter task name"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="username" class="cols-sm-2 control-label"
                                >Notes</label
                            >
                            <div class="cols-sm-10">
                                <div class="input-group">
                                    <span class="input-group-addon"
                                        ><i class="fa fa-users fa" aria-hidden="true"></i
                                    ></span>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="username"
                                        id="username"
                                        placeholder="Give some notes"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button
                                type="submit"
                                [disabled]="!taskForm.valid"
                                class="btn btn-primary btn-lg btn-block login-button"
                            >
                                Create Task
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
