import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'lib-btn-progress',
    templateUrl: './btn-progress.component.html',
    styleUrls: ['./btn-progress.component.scss'],
})
export class BtnProgressComponent {
    @Input() public btnClass = 'btn';
    @Input() public iconClass;
    @Input() public inProgress = false;
    @Input() public disabled = false;
    @Input() public type = 'button';
    @Output() public onClick = new EventEmitter<Event>();

    constructor() {}

    public clickHandler($event: Event): void {
        this.onClick.emit($event);
    }
}
