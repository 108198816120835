import { ISuppliersService } from 'dm-src/services/suppliers/isuppliers-service';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Supplier } from 'dm-src/models/supplier';
import { Inject, Injectable } from '@angular/core';
import { SupplierAddress } from 'dm-src/models/supplier-address';
import { SuppilerAddressResponse } from 'dm-src/dtos/supplier-address-response';
import { GetSupplierData } from 'dm-src/dtos/get-supplier-data';
import { SupplierWorktime } from 'dm-src/models/supplier-worktime';
import { SchedulerEvent } from 'dm-src/models/scheduler-event';
import { CreateSupplierWorktimeResponse } from 'dm-src/dtos/create-supplier-worktime-response';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService, ISelectOption } from 'shared';
import { ResetSupplierAccount } from 'dm-src/dtos/reset-supplier-account';
import { InstantDeliveryWorktime } from '../../models/instant-delivery-worktime';
import { SupplierWorktimeActivity } from 'dm-src/models/supplier-worktime-activity';
import { SumUpUserData } from 'dm-src/models/sumup-user-data';
import { InstantDeliveryArea } from '../../models/instant-delivery-area';
import { GetSupplierRevolutData } from 'dm-src/dtos/get-supplier-revolut-data';
import { SupplierRevolutData } from 'dm-src/models/supplier-revolut-data';
import { SetSupplierRevolutBalance } from 'dm-src/dtos/set-supplier-revolut-balance';

@Injectable()
export class SuppliersService implements ISuppliersService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getSupplierData(
        supplierID: string
    ): Observable<HttpResponse<GetSupplierData>> {
        return this._apiClient.getWithResponse<GetSupplierData>(
            `suppliers/${supplierID}`
        );
    }

    public getFilterOptions(): Observable<HttpResponse<ISelectOption[]>> {
        return this._apiClient.getWithResponse<ISelectOption[]>(
            `suppliers/filteroptions`
        );
    }

    public checkSupplierAddress(
        address: SupplierAddress
    ): Observable<HttpResponse<SuppilerAddressResponse>> {
        return this._apiClient.postWithResponse<SuppilerAddressResponse>(
            `suppliers/check-address`,
            address
        );
    }

    public updateSupplier(supplier: Supplier): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(`suppliers/create`, supplier);
    }

    public createSupplierWorktime(
        supplierWorktime: SupplierWorktime
    ): Observable<HttpResponse<CreateSupplierWorktimeResponse>> {
        return this._apiClient.postWithResponse<CreateSupplierWorktimeResponse>(
            `supplierWorktimes`,
            supplierWorktime
        );
    }

    public createInstantDeliveryWorktime(
        instantDeliveryWorktime: InstantDeliveryWorktime
    ): Observable<HttpResponse<InstantDeliveryWorktime>> {
        return this._apiClient.postWithResponse<InstantDeliveryWorktime>(
            `instantdelivery/CreateInstantDeliveryWorktime`,
            instantDeliveryWorktime
        );
    }

    public getInstantSupplierWorktime(
        supplierID: string
    ): Observable<HttpResponse<InstantDeliveryWorktime>> {
        return this._apiClient.getWithResponse<InstantDeliveryWorktime>(
            `instantdelivery/${supplierID}`
        );
    }

    public getInstantDeliveryAreas(): Observable<HttpResponse<string[]>> {
        return this._apiClient.getWithResponse<string[]>(`instantdelivery/getareas`);
    }
    public getAllAreas(): Observable<HttpResponse<InstantDeliveryArea[]>> {
        return this._apiClient.getWithResponse<InstantDeliveryArea[]>(
            `instantdelivery/getallareas`
        );
    }
    public changeInstantDeliveryAreaStatus(
        id: string
    ): Observable<HttpResponse<InstantDeliveryArea[]>> {
        return this._apiClient.postWithResponse<InstantDeliveryArea[]>(
            `instantdelivery/change-status/` + id
        );
    }

    public getSupplierWorktimes(
        supplierID: string
    ): Observable<HttpResponse<SchedulerEvent[]>> {
        return this._apiClient.getWithResponse<SchedulerEvent[]>(
            `supplierWorktimes/${supplierID}`
        );
    }

    public updateSupplierWorktime(
        supplierWorktime: SupplierWorktime
    ): Observable<HttpResponse<CreateSupplierWorktimeResponse>> {
        return this._apiClient.putWithResponse<CreateSupplierWorktimeResponse>(
            `supplierWorktimes`,
            supplierWorktime
        );
    }
    public updateInstantSupplierWorktime(
        instantDeliveryWorktime: InstantDeliveryWorktime
    ): Observable<HttpResponse<InstantDeliveryWorktime>> {
        return this._apiClient.putWithResponse<InstantDeliveryWorktime>(
            `instantdelivery`,
            instantDeliveryWorktime
        );
    }

    deleteSupplierWorktime(supplierWorktimeID: string): Observable<HttpResponse<void>> {
        return this._apiClient.deleteWithResponse<void>(
            `supplierWorktimes/${supplierWorktimeID}`
        );
    }

    deleteInstantSupplierWorktime(
        supplierWorktimeID: string
    ): Observable<HttpResponse<void>> {
        return this._apiClient.deleteWithResponse<void>(
            `instantdelivery/${supplierWorktimeID}`
        );
    }

    public resetSupplierAccount(
        requestBody: ResetSupplierAccount
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `suppliers/reset-account`,
            requestBody
        );
    }

    public getSupplierRevolutData(
        requestBody: GetSupplierRevolutData
    ): Observable<HttpResponse<SupplierRevolutData>> {
        return this._apiClient.postWithResponse<SupplierRevolutData>(
            `suppliers/get-revolut-data`,
            requestBody
        );
    }

    public setSupplierRevolutBalance(
        requestBody: SetSupplierRevolutBalance
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `suppliers/set-revolut-balance`,
            requestBody
        );
    }

    public setWorkTimeActivity(
        supplierWorktimeActivity: SupplierWorktimeActivity
    ): Observable<HttpResponse<SupplierWorktimeActivity>> {
        return this._apiClient.putWithResponse<SupplierWorktimeActivity>(
            `SupplierWorktimes/set-worktime-activity`,
            supplierWorktimeActivity
        );
    }

    public setSumUpUserData(
        sumUpUserData: SumUpUserData
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            `suppliers/reset-sumup-data`,
            sumUpUserData
        );
    }
}
