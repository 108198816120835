<table
    class="table table-hover table-responsive-md"
    *ngIf="orderItemListService.orderItems; else loading"
>
    <thead class="thead-light">
        <tr>
            <th scope="col">#</th>
            <th scope="col">Product name</th>
            <th scope="col">Product photo</th>
            <th scope="col">State</th>
            <th scope="col">Unit price</th>
            <th scope="col">Ordered QTY</th>
            <th scope="col">Collected QTY</th>
            <th scope="col">QTY</th>
            <th scope="col">Price</th>
            <th scope="col" class="custom-return-width">Return</th>
            <th scope="col">Comment</th>
            <th scope="col">Image</th>
        </tr>
    </thead>
    <tbody>
        <ng-container
            *ngFor="
                let orderItem of orderItemListService.orderItems | async;
                index as idx
            "
        >
            <tr
                order-item
                [index]="idx"
                [orderItem]="orderItem"
                [hasReplacementItem]="false"
                [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                *ngIf="orderItem.isReady && orderItem.orderReplacementItem === null"
            ></tr>
            <tr
                order-replacement-item
                [orderItemID]="orderItem.orderItemID"
                [orderItem]="orderItem"
                [replacementItem]="orderItem.orderReplacementItem"
                [index]="idx"
                [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                *ngIf="
                    orderItem.state === OrderItemState.Replaced ||
                    (orderItem.isReady && orderItem.orderReplacementItem !== null)
                "
            ></tr>
            <tr
                [ngClass]="{
                    hidden: !orderItem.isVisible,
                    'bg-warning expandable': true
                }"
                order-item
                [orderItem]="orderItem"
                [hasReplacementItem]="true"
                [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                *ngIf="orderItem.isReady && orderItem.orderReplacementItem !== null"
            ></tr>
            <tr
                [index]="idx"
                class="bg-danger"
                order-missing-item
                [orderItem]="orderItem"
                [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                *ngIf="
                    orderItem.state == OrderItemState.NotFound &&
                    !(orderItemListService.isReturnInProgress | async)
                "
            ></tr>
            <tr
                [index]="idx"
                class="bg-warning"
                order-removed-item
                [orderItem]="orderItem"
                [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                *ngIf="
                    orderItem.state === OrderItemState.RemovedByCustomer &&
                    !(orderItemListService.isReturnInProgress | async)
                "
            ></tr>
            <tr
                [index]="idx"
                order-item
                [orderItem]="orderItem"
                [hasState]="false"
                [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                *ngIf="!orderItem.state || orderItem.state === null"
            ></tr>

            <tr
                *ngFor="
                    let orderItemDepositProduct of orderItem.orderItemDepositProductList
                "
                [ngClass]="
                    orderItem.state == OrderItemState.NotFound &&
                    !(orderItemListService.isReturnInProgress | async)
                        ? 'bg-danger'
                        : 'bg-light-yellow'
                "
            >
                <td>+</td>
                <td>
                    <strong>{{ orderItemDepositProduct.depositUnitTypeName }}</strong>
                </td>
                <td>-</td>
                <td>-</td>
                <td>
                    {{ orderItemDepositProduct.price | priceround }}
                    {{ 'common.currency' | translate }}
                </td>
                <td>
                    {{ orderItemDepositProduct.unitValue }}
                    {{ 'collecting.piece(s)' | translate }}
                </td>
                <td>
                    {{ orderItemDepositProduct.collectedQuantity }}
                    {{ 'collecting.piece(s)' | translate }}
                </td>
                <td>
                    {{ orderItemDepositProduct.collectedQuantity }}
                    {{ 'collecting.piece(s)' | translate }}
                </td>
                <td>
                    {{
                        orderItemDepositProduct.collectedQuantity *
                            orderItemDepositProduct.price | priceround
                    }}
                    {{ 'common.currency' | translate }}
                </td>
                <td
                    order-return-control
                    [orderItem]="orderItem"
                    [orderItemDepositProduct]="orderItemDepositProduct"
                    [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
                ></td>
                <td>-</td>
                <td>-</td>
            </tr>

            <ng-container
                *ngIf="
                    orderItem.orderReplacementItem &&
                    orderItem.orderReplacementItem.orderReplacementItemDepositProductList
                "
            >
                <tr
                    class="bg-yellow-green"
                    *ngFor="
                        let orderReplacementItemDepositProduct of orderItem
                            .orderReplacementItem.orderReplacementItemDepositProductList
                    "
                >
                    <td>+</td>
                    <td>
                        <strong>{{
                            orderReplacementItemDepositProduct.depositUnitTypeName
                        }}</strong>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                        {{ orderReplacementItemDepositProduct.price | priceround }}
                        {{ 'common.currency' | translate }}
                    </td>
                    <td></td>
                    <td>
                        {{ orderReplacementItemDepositProduct.collectedQuantity }}
                        {{ 'collecting.piece(s)' | translate }}
                    </td>
                    <td>
                        {{ orderReplacementItemDepositProduct.collectedQuantity }}
                        {{ 'collecting.piece(s)' | translate }}
                    </td>
                    <td>
                        {{
                            orderReplacementItemDepositProduct.collectedQuantity *
                                orderReplacementItemDepositProduct.price | priceround
                        }}
                        {{ 'common.currency' | translate }}
                    </td>
                    <td
                        order-return-control
                        [orderItem]="orderItem"
                        [orderReplacementItemDepositProduct]="
                            orderReplacementItemDepositProduct
                        "
                        [isReturnInProgress]="
                            orderItemListService.isReturnInProgress | async
                        "
                    ></td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
    <tfoot>
        <tr class="bg-secondary">
            <td colspan="4"></td>
            <th>Total amount:</th>
            <th>{{ orderItemListService.totalCount }}</th>
            <th>Original total weight:</th>
            <th>{{ orderItemListService.originalTotalWeight | number }}</th>
            <th>Total price:</th>
            <th>{{ orderItemListService.totalPrice | number }}</th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <ng-container *ngIf="!(orderItemListService.isReturnInProgress | async)">
                <td colspan="7"></td>
                <th>
                    <button class="btn btn-success w-100" (click)="startReturnProgress()">
                        Start Return Progress
                    </button>
                </th>
            </ng-container>
            <ng-container *ngIf="orderItemListService.isReturnInProgress | async">
                <td colspan="4"></td>
                <th>
                    <div *ngIf="photoLoaded" class="bill-thumbnail-container">
                        <img [src]="returnImgSrc" />
                    </div>
                    <div class="file-input" *ngIf="!photoLoaded">
                        <input
                            type="file"
                            accept="image/*"
                            capture="environment"
                            id="photo-input"
                            onclick="this.value = null"
                            (change)="onPhotoInputChanged($event)"
                            hidden
                        />
                        <label
                            class="photo-btn btn btn-block btn-primary"
                            for="photo-input"
                        >
                            <i class="fa fa-camera"></i> Return photo upload
                        </label>
                    </div>
                </th>
                <th>
                    <textarea
                        class="form-control"
                        placeholder="Order return comment"
                        [(ngModel)]="orderReturnComment"
                    >
                    </textarea>
                </th>
                <th>
                    <button
                        class="btn btn-success w-100 mb-1"
                        appPreventDoubleClick
                        (throttledClick)="addReturnRequest()"
                    >
                        Add Return Request
                    </button>
                </th>
                <th>
                    <button
                        class="btn btn-info w-100 mb-1"
                        (click)="interruptReturnProgress()"
                    >
                        Interrupt Return Progress
                    </button>
                </th>
                <th></th>
                <th></th>
            </ng-container>
        </tr>
    </tfoot>
</table>
<ng-template #loading>
    <app-preloader></app-preloader>
</ng-template>
