<div *ngIf="data.elementType == 'cell' || data.elementType == 'event'">
    <div class="e-popup-header">
        <div class="e-header-icon-wrapper">
            <div *ngIf="data.elementType == 'event'" class="subject">
                {{ data.Subject }}
            </div>
            <button
                class="e-close e-close-icon e-icons"
                title="Close"
                (click)="close()"
            ></button>
        </div>
    </div>
</div>
