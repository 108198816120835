import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../modules/modal/modal.service';
import { ModalComponent } from '../../modules/modal/modal.component';

@Component({
    selector: 'lib-btn-close',
    templateUrl: './btn-close.component.html',
    styleUrls: ['./btn-close.component.scss'],
})
export class BtnCloseComponent extends ModalComponent {
    constructor(_ngbModal: NgbModal, modalService: ModalService) {
        super(_ngbModal, modalService);
    }
}
