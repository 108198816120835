import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NativeTakePhotoService {
    private base64PictureContentBehaviorSubject: BehaviorSubject<string> =
        new BehaviorSubject('');
    private inputDomElement?: HTMLInputElement;

    constructor() {}

    init(): void {
        try {
            this.inputDomElement = document.getElementById(
                'takePhotoInput'
            ) as HTMLInputElement;

            this.inputDomElement?.addEventListener('change', (e: Event) => {
                const value = (e.target as HTMLInputElement).value;

                this.base64PictureContentBehaviorSubject.next(value);
            });
        } catch (e) {
            console.error(e);
        }
    }

    getBase64ContentResult(): Observable<string> {
        return this.base64PictureContentBehaviorSubject;
    }

    truncateBase64ContentResult(optionalValue = ''): void {
        this.base64PictureContentBehaviorSubject.next('');
    }
}
