import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
    ACCOUNTS_SERVICE_IMPL,
    IAccountsService,
} from '../../../services/accounts/iaccounts.service';
import { IonToasterService } from '../../../services/ionic/ion.toaster.service';
import { ColorClass } from '../../../types/color-class';
import { FlashMessageService } from '../../flash-message/flash-message.service';
import { ForgotPasswordComponent } from './forgot-password.component';

@Component({
    selector: 'lib-forgot-password-ionic',
    templateUrl: './forgot-password.ionic.component.html',
    styleUrls: ['./forgot-password.ionic.component.scss'],
})
export class ForgotPasswordIonicComponent extends ForgotPasswordComponent {
    flashMessageService: FlashMessageService;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) _accountsService: IAccountsService,
        _formBuilder: FormBuilder,
        _flashMessageService: FlashMessageService,
        private _toasterService: IonToasterService
    ) {
        super(_accountsService, _formBuilder, _flashMessageService);

        this.flashMessageService = _flashMessageService;

        this.flashMessageService.type.subscribe((type) => {
            if (type == ColorClass.Danger) {
                this._toasterService.error(_flashMessageService.lastMessage());
            }
        });
    }
}
