import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { ISupplierWorktimeControlService } from './isupplier-worktime-control.service';
import { SupplierWorktimeControl } from '../../dtos/supplier-worktime-control';
import { ComletedStartControl } from '../../dtos/comleted-start-control';

@Injectable()
export class SupplierWorktimeControlService implements ISupplierWorktimeControlService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public GetStartControls(supplierID: string): Observable<SupplierWorktimeControl[]> {
        return this._apiClient.get<SupplierWorktimeControl[]>(
            'supplierWorktimes/get-start-controls/' + supplierID
        );
    }

    public AddComletedStartControl(
        comletedStartControl: ComletedStartControl
    ): Observable<HttpResponse<SupplierWorktimeControl[]>> {
        return this._apiClient.putWithResponse<SupplierWorktimeControl[]>(
            'supplierWorktimes/add-completed-start-control/',
            comletedStartControl
        );
    }
}
