import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class IonLoaderService {
    isLoading = false;

    constructor(
        public loadingController: LoadingController,
        private _translateService: TranslateService
    ) {}

    showHideAutoLoader(interval: number = 2000) {
        this.loadingController
            .create({
                message: '',
                duration: interval,
            })
            .then((res) => {
                res.present();

                res.onDidDismiss().then((dis) => {});
            });
    }

    showLoader(message?: string) {
        if (!this.isLoading) {
            this.isLoading = true;
            this.loadingController
                .create({
                    message: this.translate(message),
                    cssClass: message ? '' : 'custom-loading-nobackground',
                })
                .then((res) => {
                    res.present().then(() => {
                        if (!this.isLoading) {
                            res.dismiss();
                        }
                    });
                });
        }
    }

    hideLoader() {
        this.isLoading = false;
        this.loadingController
            .dismiss()
            .then((res) => {
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
            });
    }

    public translate(message: string): string {
        try {
            return this._translateService.instant(message) || message;
        } catch {
            return message;
        }
    }
}
