import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Quagga, { QuaggaJSResultObject_CodeResult } from '@ericblade/quagga2';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'lib-barcode-reader',
    templateUrl: './barcode-reader.component.html',
    styleUrls: ['./barcode-reader.component.scss'],
})
export class BarcodeReaderComponent implements OnInit {
    public lastScannedCode: string;
    public lastFormat: any;
    public lastResult: any;

    public manualGTIN: string;

    @Input() isTest = false;
    @Input() errorFactor = 0.2;
    @Input() pauseBetween = 1000;
    @Input() set state(value: BarcodeReaderState) {
        this.onStateChanged(value);
    }
    @Output() onBarcodeRead = new EventEmitter<string>();
    @Output() onError = new EventEmitter<string>();
    @Output() onMessage = new EventEmitter<string>();
    private lastScannedCodeDate: number;
    private audio: HTMLAudioElement;
    private lastScannedProdDate: number;
    public isSetup: boolean = false;
    public readonly CAMERA_ENVIRONMENT = 'environment';
    public cameraID: string = this.CAMERA_ENVIRONMENT;
    public cameraList: Array<MediaDeviceInfo> = [];
    constructor(private platform: Platform) {
        platform.ready().then(() => {});

        this.cameraID = localStorage.getItem('scancamid') || this.CAMERA_ENVIRONMENT;
        this.collectCameralist();
        this.initSound();
    }

    ngOnInit(): void {
        this.lastScannedCodeDate = new Date().getTime();
        this.state = BarcodeReaderState.StandBy;
    }

    private initSound(): void {
        this.audio = new Audio();
        this.audio.src = 'shared/assets/sounds/beep.wav';
        this.audio.volume = 0.5;
        this.audio.load();
    }

    collectCameralist() {
        this.cameraList = [];
        if (
            !navigator.mediaDevices ||
            !(typeof navigator.mediaDevices.getUserMedia === 'function')
        ) {
            this.onError.emit('getUserMedia is not supported');
            return;
        }

        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            this.onError.emit('enumerateDevices() not supported.');
            return;
        }

        // List cameras and microphones.
        this.cameraList = [];
        Quagga.CameraAccess.enumerateVideoDevices().then((devices) => {
            let sum = '';

            devices.forEach((device) => {
                if (device.kind == 'videoinput') {
                    this.cameraList.push(device);
                }
            });
        });
    }

    private initBarcodeReader(): void {
        let width = window.innerWidth - window.innerWidth / 10;
        let constraint: any = {
            // width: width,
            // height: window.innerHeight / 3
        };

        if (this.cameraID == this.CAMERA_ENVIRONMENT) {
            constraint.facingMode = 'environment';
        } else {
            constraint.deviceId = this.cameraID;
        }
        this.onMessage.emit('set camera: ' + this.cameraID);
        this._initQuagga(constraint);
    }

    initDim() {
        let el = <HTMLElement>document.getElementById('barcode');
        if (el) {
            el.style.width = window.innerWidth + 'px';
            let el2 = <HTMLElement>el.firstChild;
            if (el2) {
                el2.style.width = window.innerWidth + 'px';
            }
        }
    }

    _initQuagga(constraint: MediaTrackConstraints) {
        this.initDim();

        Quagga.init(
            {
                inputStream: {
                    name: 'barcode',
                    type: 'LiveStream',
                    target: document.querySelector('#barcode'),
                    constraints: constraint,
                },
                decoder: {
                    readers: ['ean_reader', 'ean_8_reader'],
                    debug: {
                        drawBoundingBox: true,
                        showFrequency: true,
                        drawScanline: true,
                        showPattern: true,
                    },
                },
            },
            (err) => {
                if (err) {
                    this.onError.emit(`QuaggaJS could not be initialized, err: ${err}`);
                } else {
                    this.initDim();
                    Quagga.start();
                    Quagga.onDetected((res) => {
                        this.onBarcodeScanned(res.codeResult);
                    });
                }
            }
        );
    }

    private deactivateBarcodeReader(): void {
        Quagga.stop();
    }

    private getMedian(arr: number[]): number {
        const sorted = [...arr].sort((a, b) => a - b);
        const half = Math.floor(sorted.length / 2);
        if (arr.length % 2 === 1) {
            return arr[half];
        }
        return (arr[half - 1] + arr[half]) / 2;
    }

    /*
  _getMedian(arr: number[]): number {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) // Odd length
      return arr[half];
    return (arr[half - 1] + arr[half]) / 2.0;
  }
  */

    private getQuality(result): number {
        try {
            const errors: number[] = result.decodedCodes
                .filter((code) => code.error !== undefined)
                .map((code) => code.error);
            // return (median < 0.10) // probably correct
            return this.getMedian(errors);
        } catch (e) {
            return e;
        }
    }

    private onBarcodeScanned(result: QuaggaJSResultObject_CodeResult): void {
        const gtin = result.code;
        // ignore duplicates for an interval of 1.5 seconds
        const now = new Date().getTime();

        if (this.state === BarcodeReaderState.Done) {
            if (now < this.lastScannedProdDate + 1500) {
                return;
            }
        } else if (
            this.state === BarcodeReaderState.Reading &&
            gtin === this.lastScannedCode &&
            now < this.lastScannedCodeDate + 100
        ) {
            return;
        }

        const fact = this.getQuality(result);
        this.lastScannedCode = gtin;
        this.lastScannedCodeDate = now;
        this.lastFormat = result.format + ', Error-factor:' + fact;

        if (fact > this.errorFactor) {
            return;
        }

        this.beep();
        this.onBarcodeRead.emit(gtin);
    }

    private beep(): void {
        this.audio.play();
    }

    onStateChanged(state: BarcodeReaderState): void {
        switch (state) {
            case BarcodeReaderState.StandBy:
                this.deactivateBarcodeReader();
                break;
            case BarcodeReaderState.Reading:
                this.initBarcodeReader();
                break;
            case BarcodeReaderState.Done:
                setTimeout(
                    () => (this.state = BarcodeReaderState.Reading),
                    this.pauseBetween
                );
                this.lastScannedProdDate = new Date().getTime();
                break;
        }
    }

    public camChange($event: any) {
        this.cameraID = $event.target.value;
        localStorage.setItem('scancamid', $event.target.value);
        this.isSetup = false;
        this.restartBarcodeReader();
    }

    restartBarcodeReader() {
        this.deactivateBarcodeReader();
        setTimeout(() => {
            this.initBarcodeReader();
        }, 1000);
    }

    public onManualGTINSet(): void {
        this.onBarcodeRead.emit(this.manualGTIN);
    }
}

export enum BarcodeReaderState {
    StandBy,
    Reading,
    Done,
}
