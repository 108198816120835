import { OrderReturnItemState } from '../types/order-return-item-state';
import { OrderItemDepositProduct } from './order-item-deposit-product';
import { OrderReplacementItemDepositProduct } from './order-replacement-item-deposit-product';

export class OrderReturnItem {
    public orderReturnItemID: string;
    public orderItemID: number;
    public itemName: string;
    public unitPrice: number;
    public quantity: number;
    public price: number;
    public categoryID: number;
    public parentCategoryID: number;
    public orderReturnItemStateID: OrderReturnItemState;
    public deliveryTaskID: string;
    public orderItemDepositProductID: string;
    public orderItemDepositProduct: OrderItemDepositProduct;
    public orderReplacementItemDepositProductID: string;
    public orderReplacementItemDepositProduct: OrderReplacementItemDepositProduct;
}
