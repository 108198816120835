<th scope="row">
    <div class="form-group form-check">
        <input
            type="checkbox"
            class="form-check-input"
            (change)="
                tasksService.setTaskCollection(!task.isInCollection, task.deliveryTaskID)
            "
            [checked]="task.isInCollection"
        />
    </div>
</th>
<th scope="row">{{ listService.listIndexOffset + index + 1 }}</th>
<td *ngIf="isVisibleColumn(0)">{{ task.customerName }}</td>
<td *ngIf="isVisibleColumn(1)">
    <span
        [ngClass]="task.isSuperOrder ? 'badge badge-danger' : 'badge badge-warning'"
        (click)="openOrderDetailsModal()"
        >{{ task.orderCode }}</span
    >
</td>
<td *ngIf="isVisibleColumn(2)" class="text-center">
    <app-task-type-badge [type]="task.type"></app-task-type-badge>
</td>
<td *ngIf="isVisibleColumn(3)" class="text-center">
    <app-task-state-badge
        [taskState]="DeliveryTaskState[task.state]"
        [task]="task"
    ></app-task-state-badge>
</td>
<td *ngIf="isVisibleColumn(4)">
    <app-user-chooser [username]="task.username" [task]="task"></app-user-chooser>
</td>
<td *ngIf="isVisibleColumn(5)" class="text-nowrap">
    <span>{{ task.deliveryDeadline | amDateFormat : 'YYYY.MM.DD. HH:mm' }}</span>
    <span *ngIf="task.deliveryTimeSlotEnd != null">
        -
        {{
            task.deliveryDeadline
                | amAdd : task.deliveryTimeSlotEnd : 'minutes'
                | amDateFormat : 'HH:mm'
        }}
    </span>
</td>
<td *ngIf="isVisibleColumn(6)">
    {{ task.supportComment }}
</td>
<td *ngIf="isVisibleColumn(7)">
    {{ task.timeSlotStartHour }}
</td>
<td *ngIf="isVisibleColumn(8)">
    {{ task.timeSlotEndHour }}
</td>
<td *ngIf="isVisibleColumn(9)">
    {{ task.timeSlot?.hoursRange }}
</td>
<td *ngIf="isVisibleColumn(10)">
    {{ task.estimatedStartDate | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
</td>
<td *ngIf="isVisibleColumn(11)">
    {{ task.estimatedEndDate | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
</td>
<td *ngIf="isVisibleColumn(12)">
    {{ task.realStartDate | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
</td>
<td *ngIf="isVisibleColumn(13)">
    {{ task.realEndDate | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
</td>
<td *ngIf="isVisibleColumn(14)">{{ task.zipCode }}</td>
<td *ngIf="isVisibleColumn(15)">
    {{ task.supplierRegion }}
</td>
<td *ngIf="isVisibleColumn(16)" class="text-nowrap">
    {{ task.deliveryAddress }}
</td>
<td *ngIf="isVisibleColumn(17)">
    {{ task.cityName }}
</td>
<td *ngIf="isVisibleColumn(18)">{{ task.providerName }}</td>
<td *ngIf="isVisibleColumn(19)" class="text-nowrap">{{ task.taskName }}</td>
<td *ngIf="isVisibleColumn(20)">
    {{ task.notes }}
</td>
<td *ngIf="isVisibleColumn(21)">
    {{ 'payment-type.' + task.paymentTypeID.toLowerCase() | translate }}
</td>
<td *ngIf="isVisibleColumn(22)">
    <app-payment-state-badge [state]="task.paymentState"></app-payment-state-badge>
</td>
<td *ngIf="isVisibleColumn(23)">
    {{ task.consultationType | translate }}
</td>
<td *ngIf="isVisibleColumn(24)">
    {{ BagType[task.paperBagType]?.toString() }}
</td>
<td *ngIf="isVisibleColumn(25)">
    {{ task.originalProviderShopName }}
</td>
<td *ngIf="isVisibleColumn(26)">
    {{ task.realProviderShopName }}
</td>
<td *ngIf="isVisibleColumn(27)">
    {{ task.createdAt | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
</td>
<td *ngIf="isVisibleColumn(28)">
    {{ task.stateChangedAt | amDateFormat : 'YYYY.MM.DD. HH:mm' }}
</td>
