import {
    FormHelper,
    LogicalOperator,
    QueryOperator,
    TableColumn,
    TableColumnFilterType as FilterType,
} from 'shared';
import { OrderState } from 'dm-src/types/order-state';
import { DeliveryType } from 'dm-src/types/delivery-type';

export const columns = [
    new TableColumn({
        name: ['OrderCode'],
        label: 'Order Code',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
    }),
    new TableColumn({
        name: ['ProviderID'],
        label: 'Provider',
        logicalOperator: LogicalOperator.Or,
        filterType: FilterType.Multiselect,
    }),
    new TableColumn({
        name: ['OrdererLastName', 'OrdererFirstName'],
        label: 'Orderer',
        filterOperator: QueryOperator.Contains,
        filterType: FilterType.Text,
        logicalOperator: LogicalOperator.Or,
        wordPerField: true,
    }),
    new TableColumn({
        name: ['Created'],
        label: 'Order Date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['DeliveryDate'],
        label: 'Delivery Date',
        filterType: FilterType.DatePicker,
    }),
    new TableColumn({
        name: ['DeliveryTypeID'],
        label: 'Delivery Type',
        filterOptions: FormHelper.createSelectOptions(DeliveryType),
        filterType: FilterType.Select,
    }),
    new TableColumn({
        name: ['DeliveryAddress.PostalCode', 'DeliveryAddress.AddressLine'],
        label: 'Delivery Address',
        filterType: FilterType.Text,
        filterOperator: QueryOperator.Contains,
        logicalOperator: LogicalOperator.Or,
    }),
    new TableColumn({
        name: ['OrderStateID'],
        label: 'Order State',
        filterOptions: FormHelper.createSelectOptions(OrderState),
        filterType: FilterType.Select,
    }),
    new TableColumn({
        name: ['OrderPaymentList.StatusID'],
        label: 'Payment State',
        filterType: FilterType.Multiselect,
        logicalOperator: LogicalOperator.Or,
    }),
];
