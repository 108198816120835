<ng-template #orderDetailsModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">{{ order.orderCode }}</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <lib-flash-message [id]="'provider-order-details-modal'"></lib-flash-message>
        <nav ngbNav #nav="ngbNav" class="nav-tabs nav-fill">
            <ng-container ngbNavItem>
                <a ngbNavLink>Order items</a>
                <ng-template ngbNavContent>
                    <app-order-items-list
                        [orderID]="order.orderID"
                    ></app-order-items-list>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="billPhotoURL">
                <a ngbNavLink>Bill photo</a>
                <ng-template ngbNavContent>
                    <img class="bill-photo" [src]="billPhotoURL" />
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</ng-template>
