import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { IRealtimeClientService } from './irealtime-client.service';

@Injectable()
export class RealtimeClientService implements IRealtimeClientService {
    private _isConnectionEstablished = false;
    private _hubConnections: HubConnection[];
    private _intentionallyStopped: boolean = false;

    public connectionId: string;

    public initHubConnection(hubName: string, baseUrl: string = '') {
        if (this._hubConnections == null) this._hubConnections = new Array();

        this._hubConnections.push(
            new HubConnectionBuilder()
                .withUrl(baseUrl + '/' + hubName)
                .withAutomaticReconnect()
                .build()
        );

        this._hubConnections[
            this._hubConnections.length - 1
        ].serverTimeoutInMilliseconds = 900000; // server would close connection without this
        this._hubConnections[this._hubConnections.length - 1].onclose(() => {
            this._isConnectionEstablished = false;
            if (!this._intentionallyStopped) {
                setTimeout(() => this.connect(baseUrl + '/' + hubName), 3000);
            }
        });
    }

    public connect(connId: string = ''): Promise<void> {
        if (!this._isConnectionEstablished) {
            return this.getHubConnection(connId)
                ?.start()
                ?.then(() => {
                    this._isConnectionEstablished = true;
                    this._intentionallyStopped = false;
                })
                .catch((err) => {
                    this._isConnectionEstablished = false;
                    setTimeout(() => this.connect(connId), 3000);
                });
        }
    }

    public getConnectionId = (connId: string = '') => {
        this.getHubConnection(connId)
            .invoke('getconnectionid')
            .then((data) => {
                this.connectionId = data;
            });
    };

    public disconnect(connId: string = ''): Promise<void> {
        let indexDelete;
        let hubConn = this.getHubConnection(connId);

        this._hubConnections.forEach((conn, index) => {
            if (connId != '' && conn.connectionId == connId) indexDelete = index;
        });

        this._hubConnections.splice(indexDelete, 1);
        return hubConn?.stop();
    }

    public invoke<T>(methodName: string, connId: string = '', ...args: any): Promise<T> {
        return this.getHubConnection(connId).invoke(methodName, ...args);
    }

    public on(methodName: string, connId: string = '', callback): void {
        this.getHubConnection(connId).on(methodName, (...args: any) => callback(args));
    }

    private getHubConnection(connId: string = '') {
        let hubConn = this._hubConnections[0];
        if (connId != '') hubConn = this._hubConnections.find((x) => x.baseUrl == connId);

        return hubConn;
    }
}
