import { ISelectOption } from 'shared';

export class SupplierRegion {
    public supplierRegionID: string;
    public regionName: string;
    public assignedZipCodes: ISelectOption[];
    public isChanged: boolean;
    public lat: number;
    public long: number;
}
