import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ISelectOption } from 'shared';

export const NATIVE_BARCODE_READER =
    new InjectionToken<IproviderNativeBarcodeReaderService>(
        'providerNativeBarcodeReaderService'
    );

export interface IproviderNativeBarcodeReaderService {
    getProviderNativeBarcodeReadeOptions(): Observable<HttpResponse<ISelectOption[]>>;
}
