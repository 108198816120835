<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    {{ 'registration.user-details' | translate }}
                </div>
                <div class="card-body">
                    <div *ngIf="isRegisteredSuccessfully || errorMessages">
                        <div
                            class="alert alert-danger"
                            role="alert"
                            *ngIf="errorMessages"
                        >
                            <strong>{{ 'common.error' | translate }}: </strong>
                            <div *ngFor="let message of errorMessages">{{ message }}</div>
                        </div>
                        <div
                            class="alert alert-success"
                            role="alert"
                            *ngIf="isRegisteredSuccessfully"
                        >
                            <h2>{{ 'registration.success-header' | translate }}</h2>
                            <p>
                                {{ 'registration.success-body' | translate }}
                            </p>
                        </div>
                    </div>
                    <form
                        class="form-horizontal"
                        [formGroup]="regForm"
                        (ngSubmit)="onSubmit()"
                    >
                        <div class="form-group is-invalid">
                            <label for="name" class="cols-sm-2 control-label">{{
                                'registration.your-first-name' | translate
                            }}</label>
                            <div class="cols-sm-10">
                                <div class="input-group is-invalid">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="firstName"
                                        id="first-name"
                                        placeholder="{{
                                            'registration.enter-your-first-name'
                                                | translate
                                        }}"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.firstName.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.firstName.errors.required">
                                        {{ 'registration.first-name' | translate }}
                                        {{ 'common.is-required' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="cols-sm-2 control-label">{{
                                'registration.your-last-name' | translate
                            }}</label>
                            <div class="cols-sm-10">
                                <div class="input-group is-invalid">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="lastName"
                                        id="name"
                                        placeholder="{{
                                            'registration.enter-your-last-name'
                                                | translate
                                        }}"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.lastName.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.lastName.errors.required">
                                        {{ 'registration.last-name' | translate }}
                                        {{ 'common.is-required' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email" class="cols-sm-2 control-label">{{
                                'registration.your-email' | translate
                            }}</label>
                            <div class="cols-sm-10">
                                <div class="input-group is-invalid">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <i
                                                class="fa fa-envelope"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                    <input
                                        type="email"
                                        class="form-control"
                                        formControlName="email"
                                        id="email"
                                        placeholder="{{
                                            'registration.enter-your-email' | translate
                                        }}"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.email.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.email.errors.required">
                                        {{ 'common.email' | translate }}
                                        {{ 'common.is-required' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password" class="cols-sm-2 control-label">{{
                                'common.password' | translate
                            }}</label>
                            <div class="cols-sm-10">
                                <div class="input-group is-invalid">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <i
                                                class="fa fa-lock fa-lg"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                    <input
                                        type="password"
                                        class="form-control"
                                        formControlName="password"
                                        id="password"
                                        placeholder="{{
                                            'userform-common.enter-password' | translate
                                        }}"
                                    />
                                </div>
                            </div>
                            <div id="passwordHelp" class="form-text text-muted">
                                {{ 'registration.password-help' | translate }}
                            </div>
                            <div
                                *ngIf="isSubmitted && fields.password.errors"
                                class="invalid-feedback"
                            >
                                <div *ngIf="fields.password.errors.required">
                                    {{ 'common.password' | translate }}
                                    {{ 'common.is-required' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="repassword" class="cols-sm-2 control-label">{{
                                'userform-common.confirm-password' | translate
                            }}</label>
                            <div class="cols-sm-10">
                                <div class="input-group is-invalid">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <i
                                                class="fa fa-lock fa-lg"
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    </div>
                                    <input
                                        type="password"
                                        class="form-control"
                                        formControlName="repassword"
                                        id="repassword"
                                        placeholder="{{
                                            'userform-common.confirm-password' | translate
                                        }}"
                                    />
                                </div>
                                <div
                                    *ngIf="isSubmitted && fields.repassword.errors"
                                    class="invalid-feedback"
                                >
                                    <div *ngIf="fields.repassword.errors.required">
                                        {{
                                            'userform-common.password-confirmation'
                                                | translate
                                        }}
                                        {{ 'common.is-required' | translate }}
                                    </div>
                                    <div *ngIf="fields.repassword.errors.mustMatch">
                                        {{
                                            'userform-common.passwords-must-match'
                                                | translate
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <input
                                formControlName="approveTermsAndConditions"
                                type="checkbox"
                                class="form-check-input"
                                id="gtac-approval"
                            />
                            <label class="form-check-label" for="gtac-approval"
                                >{{ 'registration.i-accept-the-general' | translate }}
                                <a
                                    href="https://www.bevasarlas.roksh.com/futar-aszf"
                                    target="_blank"
                                    >{{
                                        'registration.terms-and-conditions' | translate
                                    }}</a
                                >.
                            </label>
                        </div>
                        <div
                            *ngIf="isSubmitted && fields.approveTermsAndConditions.errors"
                            class="invalid-feedback"
                        >
                            <div *ngIf="fields.approveTermsAndConditions.errors.required">
                                {{
                                    'registration.terms-and-conditions-required'
                                        | translate
                                }}
                            </div>
                        </div>

                        <div class="form-group mt-2">
                            <button
                                type="submit"
                                class="btn btn-primary btn-lg btn-block login-button"
                            >
                                {{ 'registration.register' | translate }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
