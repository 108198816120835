export class StringHelper {
    public static convertToHumanCase(value: string): string {
        value = value.split(/(?=[A-Z])/).join(' ');
        return value[0].toUpperCase() + value.slice(1);
    }

    public static getTicks(): number {
        var d = new Date();
        return d.getTime();
    }
}
