<table class="custom-event-editor" width="100%" cellpadding="5">
    <tbody>
        <tr>
            <td class="e-textlabel">{{ 'my-worktime.worktime-from' | translate }}</td>
            <td colspan="4">
                <input id="StartTime" class="e-field" type="text" name="StartTime" />
            </td>
        </tr>
        <tr>
            <td class="e-textlabel">{{ 'my-worktime.worktime-to' | translate }}</td>
            <td colspan="4">
                <input id="EndTime" class="e-field" type="text" name="EndTime" />
            </td>
        </tr>
    </tbody>
</table>
