import { Component, Input, OnInit } from '@angular/core';
import { OrderItem, ColorClass, OrderItemState } from 'shared';

@Component({
    selector: 'app-order-items-view-modal',
    templateUrl: './order-items-view-modal.component.html',
    styleUrls: ['./order-items-view-modal.component.scss'],
})
export class OrderItemsViewModalComponent implements OnInit {
    public ColorClass = ColorClass;
    public OrderItemState = OrderItemState;
    @Input() public orderItems: OrderItem[];

    ngOnInit(): void {}
}
