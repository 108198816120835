import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-btn-action',
    templateUrl: './btn-action.component.html',
    styleUrls: ['./btn-action.component.scss'],
})
export class BtnActionComponent {
    @Input() public btnClass = '';
    @Input() public iconClass = '';
    @Input() public disabled = false;
    @Output() public onClick = new EventEmitter<Event>();

    constructor() {}

    public clickHandler($event: Event): void {
        this.onClick.emit($event);
    }
}
