<div class="card mb-3">
    <div class="card-header">
        {{ orderItem.orderItemName }}
    </div>
    <div class="card-body">
        <div class="row">
            <div
                *ngIf="
                    orderItem.state === orderItemState.Collected &&
                    orderItem.orderReplacementItem !== null
                "
                class="col-12"
            >
                <p>{{ 'collecting.replacement-order-item' | translate }}</p>
            </div>
            <div class="col-4">
                <a
                    *ngIf="orderItem.itemThumbnailSrc !== null; else noImage"
                    (click)="onImageClick()"
                >
                    <img
                        [src]="orderItem.itemThumbnailSrc"
                        [alt]="orderItem.orderItemName"
                    />
                </a>
                <ng-template #noImage>
                    <div class="placeholder">
                        <span>{{ 'common.no-photo' | translate }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="col-8">
                <app-order-item-details [orderItem]="orderItem"></app-order-item-details>
            </div>
        </div>
        <div
            *ngIf="
                orderItem.state === orderItemState.Collected &&
                orderItem.orderReplacementItem !== null &&
                orderItem.orderReplacementItem.quantityRequestedByUser === 0
            "
            class="col-12 alert alert-danger"
        >
            <p class="m-0">
                {{ 'collecting.not-requested-by-customer' | translate }}
            </p>
        </div>
        <div
            *ngIf="
                orderItem.state === orderItemState.Collected &&
                orderItem.orderReplacementItem !== null
            "
            class="row mt-3"
        >
            <div class="col-12">
                <p>{{ 'collecting.original-order-item' | translate }}</p>
            </div>
            <div class="col-4">
                <a
                    *ngIf="
                        orderItem.orderReplacementItem.itemThumbnailSrc !== null;
                        else noImage
                    "
                    (click)="onImageClick()"
                >
                    <img
                        [src]="orderItem.orderReplacementItem.itemThumbnailSrc"
                        [alt]="orderItem.originalItemName"
                    />
                </a>
                <ng-template #noImage>
                    <div class="placeholder">
                        <span>{{ 'common.no-photo' | translate }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="col-8">
                <table>
                    <tr>
                        <td>{{ orderItem.originalItemName }}</td>
                    </tr>
                    <td class="font-weight-bold">
                        {{ 'collecting.quantity' | translate }}:
                        {{ orderItem.orderedQuantity }}
                        {{ 'collecting.' + orderItem.unit | translate }}
                    </td>
                </table>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="btn-toolbar justify-content-between">
            <div
                class="btn-group btn-group-sm"
                app-items-to-collect-buttons
                *ngIf="orderItem.state === null"
                [canCollectManually]="!states.hasMarkupPayment"
                [orderItem]="orderItem"
                [orderRemovedItemReasons]="orderRemovedItemReasons"
            ></div>
            <div
                class="btn-group btn-group-sm"
                app-collected-items-buttons
                *ngIf="orderItem.state === orderItemState.Collected"
                [orderItem]="orderItem"
            ></div>
            <div
                class="btn-group btn-group-sm"
                app-replaced-items-buttons
                *ngIf="orderItem.state === orderItemState.Replaced"
                [orderItem]="orderItem"
                [canEditManually]="!states.hasMarkupPayment"
                [hasBarcodeReader]="states.isBarcodeReadingEnabled"
            ></div>
            <div
                class="btn-group btn-group-sm"
                app-items-not-found-buttons
                *ngIf="orderItem.state == orderItemState.NotFound"
                [orderItem]="orderItem"
            ></div>
        </div>
    </div>
</div>
