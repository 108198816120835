export interface IUpdateOrderItem {
    productProviderID: number;
    orderItemID: number;
    orderItemQuantity: number;
    orderItemUnit: string;
    orderItemUnitPrice: number;
    isCollecting: boolean;
    offlineProductID: number;
    providerID?: number;
    isWerwProduct: boolean;
    isBulk: boolean;
    vat?: number;
    optionalDepositProductQuantity?: number;
}
