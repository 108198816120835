import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISortEvent } from '../../../types/isort-event';
import { TableColumn, TableColumnFilterType } from '../../../types/table-column';
import { SortDirection } from '../../../types/sort-direction';
import { FilterEvent } from '../../../types/filter-event';
import { QueryOperator } from '../../../types/query-operator';
import { DateRange } from '../../../dtos/date-range';
import { LogicalOperator } from '../../../types/logical-operator';
import { ISelectOption } from '../../../types/i-select-option';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: '[interactive-table-header]',
    templateUrl: './interactive-table-header.component.html',
    styleUrls: ['./interactive-table-header.component.scss'],
})
export class InteractiveTableHeaderComponent {
    paymentStatesControl = new FormControl();

    @Input() columns: TableColumn[];
    @Input() inputTimeout: number;
    @Input() hasBatchColumn = false;
    @Output() onSort = new EventEmitter<ISortEvent>();
    @Output() onFilter = new EventEmitter<FilterEvent[]>();
    @Output() onCheckAll = new EventEmitter<HTMLInputElement>();
    public sortedColumn: string[];
    public filteredColumn: string[];
    public sortDirection: SortDirection;
    public FilterType = TableColumnFilterType;
    private inputTimer: NodeJS.Timeout;
    private isListRendered = false;
    oldFilterOptions = [];

    constructor() {}

    public setIsListRendered(isListRendered: boolean): void {
        this.isListRendered = isListRendered;
    }

    public sort(column: string[]): void {
        if (this.sortedColumn === column) {
            this.sortDirection =
                this.sortDirection === SortDirection.Asc
                    ? SortDirection.Desc
                    : SortDirection.Asc;
        } else {
            this.sortedColumn = column;
            this.sortDirection = SortDirection.Asc;
        }

        this.onSort.emit({
            column: this.sortedColumn,
            isDescending: this.sortDirection === SortDirection.Desc,
        });
    }

    public filterDateRange(column: TableColumn, dates: DateRange): any {
        if (dates.dateFrom === undefined) {
            return;
        }
        const filters: FilterEvent[] = [];
        const filterDateFrom = new FilterEvent();
        filterDateFrom.column = column.name[0];
        filterDateFrom.value = dates.dateFrom;

        if (filterDateFrom.value == null) {
            filters.push(filterDateFrom);
            this.onFilter.emit(filters);
            return;
        }

        filterDateFrom.value += ' 00:00:00';
        filterDateFrom.filterOperator = QueryOperator.GreaterThanOrEqual;
        filters.push(filterDateFrom);

        const filterDateTo: FilterEvent = Object.create(filterDateFrom);
        filterDateTo.filterOperator = QueryOperator.LessThanOrEqual;
        filterDateTo.value = dates.dateTo + ' 23:59:59';
        filterDateTo.append = true;
        filters.push(filterDateTo);

        this.onFilter.emit(filters);
    }

    public filterSelect(column: TableColumn, value: string): void {
        this.filter(column, value);
    }

    public filterMultiselect(column: TableColumn, selectedOption: ISelectOption): void {
        if (column.model.length > 0) {
            const values = column.model.map((option) => option.id).join(' ');
            this.filter(column, values);
        } else {
            this.filterClear(column);
        }
    }

    public filterClear(column: TableColumn): void {
        this.filter(column, null);
    }

    public filterText(column: TableColumn, event: KeyboardEvent): void {
        clearTimeout(this.inputTimer);
        let value = (event.target as HTMLInputElement).value;
        this.inputTimer = setTimeout(() => {
            value = value.length > 0 ? value : null;
            this.filter(column, value);
        }, this.inputTimeout || 1000);
    }

    public filterBoolean(column: TableColumn, value: boolean): void {
        this.filter(column, value.toString());
    }

    private filter(column: TableColumn, value: string): void {
        column = Object.assign(new TableColumn(), column);
        const filterEvents: FilterEvent[] = [];
        const searchedWords = value != null ? value.toString().split(' ') : [];
        let filterEvent: FilterEvent;
        column.name.map((columnName, colIndex) => {
            filterEvent = new FilterEvent();
            filterEvent.column = columnName;
            filterEvent.logicalOperator = column.logicalOperator;
            filterEvent.append = column.name.length > 1;
            filterEvent.filterOperator = column.filterOperator;
            if (searchedWords.length > 1) {
                if (column.wordPerField) {
                    filterEvent.value = searchedWords[colIndex];
                    filterEvent.logicalOperator = LogicalOperator.And;
                    filterEvents.push(filterEvent);
                } else {
                    searchedWords.forEach((word) => {
                        filterEvent = Object.assign({}, filterEvent);
                        filterEvent.append = true;
                        filterEvent.value = word;
                        filterEvents.push(filterEvent);
                    });
                }
            } else {
                filterEvent.value = value;
                filterEvents.push(filterEvent);
            }
        });
        this.onFilter.emit(filterEvents);
    }

    toggleAllSelection(matSelect: MatSelect) {
        const isSelected: boolean = matSelect.options
            // The "Select All" item has the value 0, so find that one
            .filter((item: MatOption) => item.value === 0)
            // Get the value of the property 'selected' (this tells us whether "Select All" is selected or not)
            .map((item: MatOption) => item.selected)[0]; // Get the first element (there should only be 1 option with the value 0 in the select)

        if (isSelected) {
            matSelect.options.forEach((item: MatOption) => item.select());
        } else {
            matSelect.options.forEach((item: MatOption) => item.deselect());
        }
    }

    public getColumnClass(column: string[]): string {
        let cls = 'label';
        if (this.sortedColumn === column) {
            cls += ' ' + this.sortDirection;
        }
        return cls;
    }

    public showColumnFilter(column: string[]): void {
        this.filteredColumn = this.filteredColumn === column ? null : column;
    }

    public checkAll(event: HTMLInputElement): void {
        this.onCheckAll.emit(event);
    }

    filterColumns(model: TableColumn, event: KeyboardEvent, i: number) {
        let value = (event.target as HTMLInputElement).value;
        if (this.oldFilterOptions.length === 0) {
            this.oldFilterOptions[i] = this.columns[i].filterOptions;
        }
        model.filterOptions = this.columns[i].filterOptions.filter(
            (item) =>
                item.label.toLowerCase().includes(value.toLowerCase()) || value == ''
        );
        if (value == '') {
            model.filterOptions = this.oldFilterOptions[i];
        }
    }
}
