import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProgressLineService {
    private _visibleProgressLine: BehaviorSubject<string>;

    get visibleProgressLine(): Observable<string> {
        return this._visibleProgressLine.asObservable();
    }

    constructor() {
        this._visibleProgressLine = new BehaviorSubject<string>(null);
    }

    public showProgressLine(elementID = 'main') {
        this._visibleProgressLine.next(elementID);
    }

    public hideProgressLine(elementID = 'main') {
        this._visibleProgressLine.next(null);
    }
}
