<div id="barcode" class="viewport">
    <!-- QuaggaJS will populate this element -->
</div>
<div class="panel-setup">
    <i class="fa fa-cog btn-setup fa-2x" (click)="isSetup = !isSetup"></i>
    <select
        class="cam-select"
        *ngIf="isSetup"
        (change)="camChange($event)"
        [ngModel]="cameraID"
    >
        <option [value]="CAMERA_ENVIRONMENT">environment</option>
        <option *ngFor="let cam of cameraList; let i = index" [value]="cam.deviceId">
            {{ cam.label || 'camera' + i }}
        </option>
    </select>
</div>
<div>
    <input type="text" class="form-control mt-3" [(ngModel)]="manualGTIN" />
    <button class="btn btn-success mt-3 float-right" (click)="onManualGTINSet()">
        Find GTIN manually
    </button>
</div>
