import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { IProviderShopService } from './iprovider-shop.service';

@Injectable()
export class ProviderShopService implements IProviderShopService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getUsersProviderShop(userID: string): Observable<number> {
        return this._apiClient.get<number>(
            'providershop/get-users-provider-shop/' + userID,
            { responseType: 'text' }
        );
    }
}
