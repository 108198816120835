import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from './logout.component';
import { LoginComponent } from './login.component';
import { FlashMessageModule } from '../flash-message/flash-message.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoginIonicComponent } from './login.ionic.component';
import { IonicModule } from '@ionic/angular';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordIonicComponent } from './forgot-password/forgot-password.ionic.component';
import { ChangePasswordIonicComponent } from './change-password/change-password.ionic.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        LoginComponent,
        LoginIonicComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ForgotPasswordIonicComponent,
        ChangePasswordComponent,
        ChangePasswordIonicComponent,
    ],
    imports: [
        CommonModule,
        FlashMessageModule,
        ReactiveFormsModule,
        TranslateModule,
        IonicModule,
        RouterModule,
    ],
    exports: [
        LoginComponent,
        LoginIonicComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ForgotPasswordIonicComponent,
        ChangePasswordComponent,
        ChangePasswordIonicComponent,
    ],
})
export class LoginModule {}
