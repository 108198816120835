import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ISelectOption } from 'shared';
import {
    ISupplierRegionsService,
    SUPPLIER_REGIONS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isupplier-regions-service';

@Component({
    selector: 'app-supplier-region-chooser',
    templateUrl: './supplier-region-chooser.component.html',
    styleUrls: ['./supplier-region-chooser.component.scss'],
})
export class SupplierRegionChooserComponent implements OnInit {
    @Output() public onSelect = new EventEmitter<string>();
    @Input() selectedRegionID: string;
    public supplierRegions: ISelectOption[];

    constructor(
        @Inject(SUPPLIER_REGIONS_SERVICE_IMPL)
        private _supplierRegionsService: ISupplierRegionsService
    ) {}

    ngOnInit(): void {
        this._supplierRegionsService.getRegionFilterOptions().subscribe((response) => {
            if (response.status === 200) {
                this.supplierRegions = response.body;
            }
        });
    }

    public onChange(supplierID: string): void {
        this.onSelect.emit(supplierID);
    }

    public getDefaultValue() {
        return [this.selectedRegionID];
    }
}
