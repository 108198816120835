import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { MarkupCheckoutData } from 'projects/shared/src/lib/models/markup-checkout-data';

export const QR_FACTORY_SERVICE_IMPL = new InjectionToken<IQRFactoryService>(
    'qrFactoryServiceImpl'
);

export interface IQRFactoryService {
    getForCheckout(
        orderQrCode: string,
        isUpdateNeeded: boolean
    ): Observable<HttpResponse<Blob>>;
    getOrderData(orderQrCode: string): Observable<HttpResponse<MarkupCheckoutData>>;
}
