<th scope="row">{{ index + 1 }}</th>
<td>{{ orderItem.orderItemName }}</td>
<td><img class="product-photo" src="{{ orderItem.itemThumbnailSrc }}" /></td>
<td><span class="badge badge-primary">Removed</span></td>
<td>{{ orderItem.unitPrice }}</td>
<td>{{ orderItem.orderedQuantity }}</td>
<td>{{ orderItem.collectedQuantity }}</td>
<td>0</td>
<td>{{ orderItem.price }}</td>
<td>-</td>
<td>{{ orderItem.removedReasonComment }}</td>
<td>
    <img
        src="{{ orderItem.removedReasonImageUrl }}"
        class="reason-image"
        (click)="openImage(orderItem.removedReasonImageUrl)"
    />
</td>
