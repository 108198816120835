import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ColorClass } from '../../types/color-class';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class FlashMessageService {
    private _componentID: BehaviorSubject<string>;
    private _type: BehaviorSubject<ColorClass>;
    private _title: BehaviorSubject<string>;

    private _message: BehaviorSubject<string>;

    private _storedMessage: string;

    public get isVisible(): boolean {
        return this._message.getValue() != null;
    }

    public get type() {
        return this._type.asObservable();
    }

    public setType(value: ColorClass) {
        this._type.next(value);
    }

    public get title() {
        return this._title.asObservable();
    }

    public setTitle(value: string) {
        this._title.next(value);
    }

    public get message() {
        return this._message.asObservable();
    }

    public setMessage(value: string) {
        this._message.next(value);
    }

    public get componentID() {
        return this._componentID.asObservable();
    }

    public setComponentID(compnentID: string) {
        this._componentID.next(compnentID);
    }

    constructor(private _translateService: TranslateService) {
        this._componentID = new BehaviorSubject<string>('main');

        this._message = new BehaviorSubject<string>(null);
        this._title = new BehaviorSubject<string>(null);
        this._type = new BehaviorSubject<ColorClass>(ColorClass.Success);
    }

    public showMessage(
        title: string,
        message: string,
        timeout: number = null,
        type: ColorClass = ColorClass.Danger,
        componentID: string = 'main'
    ) {
        this._type.next(type);

        if (title != null) {
            const titleTranslation = this._translateService.instant(title);
            this._title.next(titleTranslation !== null ? titleTranslation : title);
        }

        if (message != null) {
            const messageTranslation = this.translate(message);
            this._message.next(messageTranslation);
        } else {
            this._message.next('');
        }

        this._componentID.next(componentID);

        if (timeout != null) {
            setTimeout(() => this.close(), timeout);
        }
    }

    public showStoredMessage(componentID: string = 'main', timeout: number = null): void {
        this._componentID.next(componentID);
        const messageTranslation = this.translate(this._storedMessage);
        this._message.next(messageTranslation);
        if (timeout != null) {
            setTimeout(() => this.close(), timeout);
        }
    }

    public storeMessage(
        title: string,
        message: string,
        type: ColorClass = ColorClass.Danger
    ): void {
        this._storedMessage = message;
        this.setTitle(title);
        this.setType(type);
    }

    public lastMessage(): string {
        return this.translate(this._storedMessage);
    }

    public lastType(): ColorClass {
        return this._type.getValue();
    }

    public translate(message: string): string {
        try {
            return this._translateService.instant(message) || message;
        } catch {
            return message;
        }
    }

    public close(): void {
        this._type.next(null);
        this._message.next(null);
        this._title.next(null);
        this._componentID.next('main');
    }
}
