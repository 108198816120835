import { Inject, Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AUTH_SERVICE_IMPL } from '../services/authentication/iauthentication.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next));
    }

    private async handle(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Promise<HttpEvent<any>> {
        const authService = await this._injector.get(AUTH_SERVICE_IMPL);
        const currentUser = authService.currentUser;
        if (currentUser && currentUser.authToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.authToken}`,
                },
            });
        }
        return next.handle(request).toPromise();
    }
}
