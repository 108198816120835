import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MyWorktimesService } from 'dm-src/app/modules/my-worktimes/my-worktimes.service';
import { ISelectOption } from 'shared';
import {
    ISuppliersService,
    SUPPLIERS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isuppliers-service';

@Component({
    selector: 'app-supplier-chooser',
    templateUrl: './supplier-chooser.component.html',
    styleUrls: ['./supplier-chooser.component.scss'],
})
export class SupplierChooserComponent implements OnInit {
    @Output() public onSelect = new EventEmitter<string>();
    public supplierFilterOptions: ISelectOption[];

    constructor(
        @Inject(SUPPLIERS_SERVICE_IMPL)
        private _suppliersService: ISuppliersService,
        public myWorktimesService: MyWorktimesService
    ) {}

    ngOnInit(): void {
        this._suppliersService.getFilterOptions().subscribe((response) => {
            if (response.status === 200) {
                this.supplierFilterOptions = response.body;
            }
        });
    }

    onChange(supplierID: string): void {
        this.onSelect.emit(supplierID);
    }
}
