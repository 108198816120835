<div class="container" *appHasRoles="['administrator', 'support', 'itsupport']">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
            <div class="card">
                <!--
        <div class="card-header bg-secondary">
          Order Invoice and Stock Correction
        </div>
          -->
                <div class="card-body">
                    <ng-container *ngIf="order">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Type</td>
                                    <td>Invoice number</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let inv of invoiceList">
                                    <td>
                                        {{
                                            inv.created
                                                | amDateFormat : 'YYYY.MM.DD. HH:mm'
                                        }}
                                    </td>
                                    <td>
                                        {{ inv.invoiceTypeTxt }}
                                    </td>
                                    <td>{{ inv.invoiceNumber }}</td>
                                    <td>
                                        <a
                                            *ngIf="inv.url"
                                            class="btn btn-primary"
                                            href="{{ inv.url }}"
                                            target="_blank"
                                            >Download</a
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
