import { Inject, Injectable } from '@angular/core';
import { User } from '../../../../../shared/src/lib/models/user';
import { BehaviorSubject } from 'rxjs';
import { ACCOUNTS_SERVICE_IMPL, FlashMessageService, IAccountsService } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private _users: BehaviorSubject<User[]>;
    private _selectedUser: BehaviorSubject<User>;

    get users() {
        return this._users.asObservable();
    }

    get selectedUser() {
        return this._selectedUser.asObservable();
    }

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        private _flashMessageService: FlashMessageService
    ) {
        this._users = new BehaviorSubject<User[]>(null);
        this._selectedUser = new BehaviorSubject(null);

        this._accountsService.getUsers().subscribe((users) => {
            users = users.map((user) => {
                const newUser = new User();
                Object.assign(newUser, user);
                return newUser;
            });
            this._users.next(users);
        });
    }

    public updateUser(user: User) {
        const users = this._users.value.map((currentUser) => {
            if (currentUser.userID === user.userID) {
                currentUser.roles = user.roles;
            }
            return currentUser;
        });
        this._users.next(users);
    }

    public setSelectedUser(user: User) {
        this._selectedUser.next(user);
    }

    public inactivateUser(userID: string) {
        this._accountsService.inactivateUser(userID).subscribe((res) => {
            (error) => this._flashMessageService.showStoredMessage();
        });
    }

    public editUserName(userID: string, username: string) {
        this._accountsService.editUserName(userID, username).subscribe((res) => {
            (error) => this._flashMessageService.showStoredMessage();
        });
    }
}
