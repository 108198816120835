import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfflineProduct } from '../../models/offline-product';
import { IOfflineProductService } from './ioffline-product.service';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';

@Injectable()
export class OfflineProductService implements IOfflineProductService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public getByGTIN(
        providerID: number,
        gtin: string
    ): Observable<HttpResponse<OfflineProduct>> {
        return this._apiClient.getWithResponse<OfflineProduct>(
            `offlineproduct/getByGtin/${providerID}/${gtin}`
        );
    }

    public getBySKU(
        providerID: number,
        SKU: string
    ): Observable<HttpResponse<OfflineProduct[]>> {
        return this._apiClient.getWithResponse<OfflineProduct[]>(
            `offlineproduct/getBySku/${providerID}/${SKU}`
        );
    }
}
