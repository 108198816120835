import { Component, Input, Inject, OnInit } from '@angular/core';
import { WishListOrder } from 'projects/shared/src/lib/models/wish-list-order';
import {
    IMAGE_COMPRESSION_SERVICE_IMPL,
    IImageCompressionService,
    ModalService,
    ColorClass,
    Address,
} from 'shared';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { WishListService } from 'dm-src/app/modules/my-tasks/wish-list.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { WishListProductItem } from 'dist/shared/lib/models/wish-list-product-item';

@Component({
    selector: 'app-wish-list-buttons',
    templateUrl: './wish-list-buttons.component.html',
    styleUrls: ['./wish-list-buttons.component.scss'],
})
export class WishListButtonsComponent implements OnInit {
    @Input() wishListOrders: WishListOrder[];
    @Input() customerPhone: string;
    @Input() orderCode: string;

    public ColorClass = ColorClass;

    public photoImageContent: string;
    public photoImageExtension: string;
    public imgResultAfterCompression: string;
    public photoLoaded: boolean;

    public isSaveBlocked = false;

    public wishListOrderID: string;
    public wishListProviderShopName: string;
    public wishListProviderShopAddress: string;
    public wishListProductItems: WishListProductItem[];
    public totalPrice: number | null;

    constructor(
        @Inject(IMAGE_COMPRESSION_SERVICE_IMPL)
        private imageCompressionService: IImageCompressionService,
        private nativeTakePhotoService: NativeTakePhotoService,
        public wishListService: WishListService,
        public modalService: ModalService,
        private iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit(): void {
        if (window.ReactNativeWebView) {
            this.iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'CAMERA_START') {
                        this.setControlPhotoImageContent(
                            'data:image/jpeg;base64,' + response?.result,
                            'jpeg'
                        );
                        this.imgResultAfterCompression =
                            'data:image/jpeg;base64,' + response?.result;
                    }
                });
        } else {
            this.nativeTakePhotoService.getBase64ContentResult().subscribe((result) => {
                if (result) {
                    this.imgResultAfterCompression = result;
                    this.photoLoaded = true;
                }
            });
        }
    }

    public get isPhotoInvalid() {
        return !this.imgResultAfterCompression || this.imgResultAfterCompression === '';
    }

    public get isTotalPriceInvalid() {
        return !this.totalPrice || this.totalPrice <= 0;
    }

    public get hasWishListOrders(): boolean {
        return this.wishListOrders && 0 < this.wishListOrders.length;
    }

    public isCompletedWishListOrder(wishListOrder: WishListOrder): boolean {
        return wishListOrder && wishListOrder.isCompletedWishListOrder;
    }

    public getWishListProviderShopAddress(address: Address) {
        return `${address.postalCode} ${address.countyName} ${address.cityName}, ${address.addressLine}`;
    }

    showWishListOrderModal(wishListOrder: WishListOrder) {
        this.wishListOrderID = wishListOrder.wishListOrderID;
        this.wishListProductItems = wishListOrder.wishListProductItems;
        this.wishListProviderShopName =
            wishListOrder.wishListProviderShop.wishListProviderShopName;
        this.wishListProviderShopAddress = this.getWishListProviderShopAddress(
            wishListOrder.wishListProviderShop.address
        );

        this.resetAll();

        this.modalService.setModalVisibility(
            true,
            'update-wish-list-order-modal' + this.orderCode
        );
    }

    public onPhotoInputChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this.imageCompressionService
                    .compressImage(e.target.result, false, 50)
                    .then((result) => (this.imgResultAfterCompression = result));

                this.photoLoaded = true;
            };

            reader.onloadend = (e) => {
                const imageExtension = fileInput.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();
                this.setControlPhotoImageContent(
                    reader.result.toString(),
                    imageExtension
                );
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    public setControlPhotoImageContent(
        imageContent: string,
        imageExtension: string
    ): void {
        this.photoImageContent = imageContent;
        this.photoImageExtension = imageExtension;
        this.photoLoaded = true;
    }

    public updateWishListOrder() {
        if (this.isTotalPriceInvalid || this.isPhotoInvalid) {
            return;
        }

        this.isSaveBlocked = true;

        this.wishListService
            .updateWishListOrder(
                this.wishListOrderID,
                this.totalPrice,
                this.imgResultAfterCompression,
                this.photoImageExtension
            )
            .subscribe((result) => {
                if (result) {
                    let wishListOrder = this.wishListOrders.find(
                        (x) => x.wishListOrderID === this.wishListOrderID
                    );

                    wishListOrder.isCompletedWishListOrder = true;
                    wishListOrder.totalPrice = this.totalPrice;

                    this.modalService.setModalVisibility(
                        false,
                        'update-wish-list-order-modal' + this.orderCode
                    );
                }

                this.isSaveBlocked = false;
            });
    }

    handleTakeAPhoto() {
        this.iosAndAndroidNatvieFunctionsService.takeAPicture();
    }

    public resetAll() {
        this.totalPrice = null;
        this.isSaveBlocked = false;
        this.deletePhoto();
    }

    public deletePhoto(): void {
        this.photoImageContent = '';
        this.photoImageExtension = '';
        this.photoLoaded = false;
        this.imgResultAfterCompression = null;
    }
}
