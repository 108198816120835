import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { CommonEventLog } from '../../models/common-event-log';
import { OrderStateEventLog } from '../../models/order-state-event-log';
import { SupplierWorktimeEventLog } from '../../models/supplier-worktime-event-log';
import { SupplierResourceEventLog } from '../../models/supplier-resource-event-log';
import { ErrorLogDto } from '../../dtos/error-log-dto';
import { InfoLogDto } from '../../dtos/info-log-dto';
import { ILoggerClientService } from './ilogger-client.service';
import { AppSettings } from '../../../appsettings';
import {
    IAuthenticationService,
    AUTH_SERVICE_IMPL,
} from '../authentication/iauthentication.service';
import { ServiceBaseService } from '../service-base.service';
import { ModuleID } from '../../types/module-id';

@Injectable()
export class LoggerClientService implements ILoggerClientService {
    constructor(
        private _httpClient: HttpClient,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {}

    public addCommonEventLog(
        commonEventLog: CommonEventLog
    ): Observable<HttpResponse<boolean>> {
        var environmentName = ServiceBaseService.getEnvironmentName;
        commonEventLog.environmentName = environmentName;

        var actor = this._authService.currentUser;
        commonEventLog.actorType = actor.actorType;
        commonEventLog.userName = actor.actorUserName;
        commonEventLog.ip = actor.actorIp;
        commonEventLog.userAgent = actor.actorUserAgent;

        return this._httpClient.post<boolean>(
            this.getAPIUrl('event/common'),
            commonEventLog,
            {
                observe: 'response',
                withCredentials: true,
                // headers,
            }
        );
    }

    public addOrderStateEventLog(
        orderStateEventLog: OrderStateEventLog
    ): Observable<HttpResponse<boolean>> {
        var environmentName = ServiceBaseService.getEnvironmentName;

        orderStateEventLog.environmentName = environmentName;

        var user = this._authService.getUserFromStorage();

        orderStateEventLog.actorType = user.actorType;
        orderStateEventLog.userName = user.actorUserName;
        orderStateEventLog.ip = user.actorIp;
        orderStateEventLog.userAgent = user.actorUserAgent;

        return this._httpClient.post<boolean>(
            this.getAPIUrl('event/order-state'),
            orderStateEventLog,
            {
                observe: 'response',
                withCredentials: true,
                // headers,
            }
        );
    }

    public addSupplierWorktimeEventLog(
        supplierWorktimeEventLog: SupplierWorktimeEventLog
    ): Observable<HttpResponse<boolean>> {
        var environmentName = ServiceBaseService.getEnvironmentName;
        supplierWorktimeEventLog.environmentName = environmentName;

        var actor = this._authService.currentUser;
        supplierWorktimeEventLog.actorType = actor.actorType;
        supplierWorktimeEventLog.userName = actor.actorUserName;
        supplierWorktimeEventLog.ip = actor.actorIp;
        supplierWorktimeEventLog.userAgent = actor.actorUserAgent;

        return this._httpClient.post<boolean>(
            this.getAPIUrl('event/supplier-worktime'),
            supplierWorktimeEventLog,
            {
                observe: 'response',
                withCredentials: true,
                // headers,
            }
        );
    }

    public addSupplierResourceEventLog(
        supplierResourceEventLog: SupplierResourceEventLog
    ): Observable<HttpResponse<boolean>> {
        var environmentName = ServiceBaseService.getEnvironmentName;
        supplierResourceEventLog.environmentName = environmentName;

        var actor = this._authService.currentUser;
        supplierResourceEventLog.actorType = actor.actorType;
        supplierResourceEventLog.userName = actor.actorUserName;
        supplierResourceEventLog.ip = actor.actorIp;
        supplierResourceEventLog.userAgent = actor.actorUserAgent;

        return this._httpClient.post<boolean>(
            this.getAPIUrl('event/supplier-resource'),
            supplierResourceEventLog,
            {
                observe: 'response',
                withCredentials: true,
                // headers,
            }
        );
    }

    public addErrorLog(
        errorLogDto: ErrorLogDto,
        moduleID: ModuleID
    ): Observable<HttpResponse<boolean>> {
        var environmentName = ServiceBaseService.getEnvironmentName;
        errorLogDto.environmentName = environmentName;

        errorLogDto.moduleID = moduleID;

        return this._httpClient.post<boolean>(this.getAPIUrl('error'), errorLogDto, {
            observe: 'response',
            withCredentials: true,
            // headers,
        });
    }

    public addInfoLog(
        infoLogDto: InfoLogDto,
        moduleID: ModuleID
    ): Observable<HttpResponse<boolean>> {
        var environmentName = ServiceBaseService.getEnvironmentName;
        infoLogDto.environmentName = environmentName;

        infoLogDto.moduleID = moduleID;

        return this._httpClient.post<boolean>(this.getAPIUrl('info'), infoLogDto, {
            observe: 'response',
            withCredentials: true,
            // headers,
        });
    }

    private getAPIUrl(endpoint: string): string {
        return `${AppSettings.get('loggerBaseURL')}/${endpoint}`;
    }
}
