import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';

@Component({
    selector: 'app-support-comment',
    templateUrl: './support-comment.component.html',
    styleUrls: ['./support-comment.component.css'],
})
export class SupportCommentComponent implements OnInit {
    @Input() public notes: string;
    public isEditMode = false;
    public isSubmitted = false;
    public supportCommentForm: FormGroup;

    public get fields() {
        return this.supportCommentForm.controls;
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _ordersService: OrdersService
    ) {}

    ngOnInit(): void {
        this.supportCommentForm = this._formBuilder.group({
            notes: [this.notes, Validators.required],
        });
    }

    public updateCustomerNotes() {
        this.isSubmitted = true;

        if (this.supportCommentForm.invalid) {
            return false;
        }

        this._ordersService
            .updateSupportComment(this.fields.notes.value)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.notes = this.fields.notes.value;
                    this.isEditMode = false;
                }
            });
    }
}
