import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MyTasksComponent } from './my-tasks.component';
import { CollectingTaskItemComponent } from 'dm-src/app/modules/my-tasks/task-group/task-group-item/collecting-task-item/collecting-task-item.component';
import { CollectingTaskButtonComponent } from 'dm-src/app/modules/my-tasks/task-group/task-group-item/collecting-task-item/collecting-task-button/collecting-task-button.component';
import { WishListButtonsComponent } from 'dm-src/app/modules/my-tasks/task-group/task-group-item/wish-list-buttons/wish-list-buttons.component';
import { RouterModule } from '@angular/router';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DeliveringTaskItemComponent } from 'dm-src/app/modules/my-tasks/task-group/task-group-item/delivering-task-item/delivering-task-item.component';
import { DeliveringTaskButtonsComponent } from 'dm-src/app/modules/my-tasks/task-group/task-group-item/delivering-task-item/delivering-task-buttons/delivering-task-buttons.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { TaskGroupComponent } from './task-group/task-group.component';
import { TaskGroupItemComponent } from './task-group/task-group-item/task-group-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { LibCommonModule, ModalModule, QRReaderModule, SharedModule } from 'shared';
import { RefundingTaskItemComponent } from './task-group/task-group-item/refunding-task-item/refunding-task-item.component';
import { RefundingTaskButtonComponent } from './task-group/task-group-item/refunding-task-item/refunding-task-button/refunding-task-button.component';
import { PackageVerifyModalComponent } from '../delivering/delivering-modals/package-verify-modal/package-verify-modal.component';
import { OrderItemsViewModalComponent } from '../delivering/delivering-modals/order-items-view-modal/order-items-view-modal.component';
import { OrderItemViewComponent } from '../delivering/delivering-modals/order-item-view/order-item-view.component';
import { OrderItemStateViewComponent } from '../delivering/delivering-modals/order-item-state-view/order-item-state-view.component';
import { OrderReplacementItemViewComponent } from '../delivering/delivering-modals/order-replacement-item-view/order-replacement-item-view.component';
import { DeliveryContainerPlacingTaskItemComponent } from './task-group/task-group-item/delivery-container-placing-task-item/delivery-container-placing-task-item.component';
import { ContainerPlacingTaskItemComponent } from './task-group/task-group-item/container-placing-task-item/container-placing-task-item.component';
import { OrderBoxReaderComponent } from './task-group/task-group-item/container-placing-task-item/order-box-reader/order-box-reader.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        MyTasksComponent,
        CollectingTaskItemComponent,
        CollectingTaskButtonComponent,
        WishListButtonsComponent,
        DeliveringTaskItemComponent,
        DeliveringTaskButtonsComponent,
        RefundingTaskItemComponent,
        RefundingTaskButtonComponent,
        TaskGroupComponent,
        TaskGroupItemComponent,
        PackageVerifyModalComponent,
        OrderItemsViewModalComponent,
        OrderItemViewComponent,
        OrderItemStateViewComponent,
        OrderReplacementItemViewComponent,
        ContainerPlacingTaskItemComponent,
        DeliveryContainerPlacingTaskItemComponent,
        OrderBoxReaderComponent,
    ],
    imports: [
        QRReaderModule,
        LibCommonModule,
        CommonModule,
        RouterModule,
        PreloaderModule,
        CollapseModule,
        NgbAlertModule,
        MomentModule,
        AppSharedModule,
        FormsModule,
        TranslateModule,
        ModalModule,
        SharedModule,
    ],
    providers: [DatePipe],
})
export class MyTasksModule {
    public alertMessage: string;
}
