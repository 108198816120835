<div class="btn-group btn-block" *ngIf="isWaitingForAccept">
    <button class="btn btn-sm btn-success" (click)="acceptAssignment()">
        {{ 'my-tasks.accept-task' | translate }}
    </button>
    <button class="btn btn-sm btn-danger" (click)="rejectAssignment()">
        {{ 'my-tasks.reject-task' | translate }}
    </button>
</div>
<button *ngIf="!isWaitingForAccept" [class]="buttonClasses" (click)="onClick()">
    {{ 'my-tasks.' + buttonText | translate }}
</button>
