import { BaseOrderItem } from './base-order-item';
import { OrderItemDepositProduct } from './order-item-deposit-product';
import { OrderReplacementItemDepositProduct } from './order-replacement-item-deposit-product';

export class OrderReplacementItem extends BaseOrderItem {
    public orderReplacementItemID: string;
    public orderItemID: number;
    public itemName: string;
    public isApprovedAtInspection = false;
    public orderItemDepositProductList: OrderItemDepositProduct[];
    public orderReplacementItemDepositProductList: OrderReplacementItemDepositProduct[];
}
