import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { CloseCollectingTask } from 'dm-src/dtos/close-collecting-task';
import { CollectingDoneResponse } from 'shared';
import { CollectingPayment } from 'dm-src/dtos/collecting-payment';
import { CollectingPaymentResponse } from 'dm-src/dtos/collecting-payment-response';
import { CollectingOrderItems } from 'dm-src/models/collecting-order-items';
import { SetDeliveryTaskFailed } from 'shared';
import { CheckVirtualPaymentResponse } from 'dm-src/dtos/check-virtual-payment-response';

export const COLLECTING_SERVICE_IMPL = new InjectionToken<ICollectingService>(
    'collectingServiceImpl'
);

export interface ICollectingService {
    getOrderItems(
        taskID: string,
        orderID: number
    ): Observable<HttpResponse<CollectingOrderItems>>;
    startPayment(
        requestBody: CollectingPayment
    ): Observable<HttpResponse<CollectingPaymentResponse>>;
    releaseReservedPayment(
        requestBody: CollectingPayment
    ): Observable<HttpResponse<CheckVirtualPaymentResponse>>;
    finishPayment(
        requestBody: CloseCollectingTask
    ): Observable<HttpResponse<CollectingDoneResponse>>;
    close(
        requestBody: CloseCollectingTask
    ): Observable<HttpResponse<CollectingDoneResponse>>;
    failed(requestBody: SetDeliveryTaskFailed): Observable<HttpResponse<void>>;
}
