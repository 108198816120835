import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetUserSettingBtnComponent } from './set-user-setting-btn/set-user-setting-btn.component';

@NgModule({
    declarations: [SetUserSettingBtnComponent],
    imports: [CommonModule],
    exports: [SetUserSettingBtnComponent],
})
export class UserSettingsModule {}
