<p>{{ 'my-tasks.order-items' | translate }}</p>
<content>
    <ul class="list-group">
        <ng-container *ngFor="let orderItem of orderItems">
            <app-order-item-view
                [orderItem]="orderItem"
                *ngIf="orderItem.orderReplacementItem === null"
            ></app-order-item-view>
            <app-order-item-view
                [orderItem]="orderItem"
                [label]="'collecting.original-order-item' | translate"
                [color]="ColorClass.Warning"
                *ngIf="orderItem.orderReplacementItem !== null"
            ></app-order-item-view>
            <app-order-replacement-item-view
                [orderReplacementItem]="orderItem.orderReplacementItem"
                [orderItemState]="orderItem.state"
                [label]="'collecting.replacement-order-item' | translate"
                [color]="ColorClass.Warning"
                *ngIf="orderItem.orderReplacementItem !== null"
            ></app-order-replacement-item-view>
        </ng-container>
    </ul>
</content>
