import { Inject, Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAccountsService } from './iaccounts.service';
import { ConfirmRegistration } from '../../dtos/confirm-registration';
import { UserRegistration } from 'projects/shared/src/lib/dtos/user-registration';
import { UpdateUserRole } from 'projects/shared/src/lib/dtos/update-user-role';
import { AssignUserToProvider } from 'projects/shared/src/lib/dtos/assign-user-to-provider';
import { User } from 'projects/shared/src/lib/models/user';
import { AssignRole } from 'projects/shared/src/lib/models/assign-role';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from '../iapi-client.service';
import { IForgotPassword } from '../../dtos/i-forgot-password';
import { IChangePassword } from '../../dtos/i-change-password';

@Injectable()
export class AccountsService implements IAccountsService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public registerUser(userData: UserRegistration): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>('accounts/register', userData);
    }

    public confirmRegistration(
        userId: string,
        token: string
    ): Observable<HttpResponse<void>> {
        const body = new ConfirmRegistration();

        body.id = userId;
        body.token = token;

        return this._apiClient.postWithResponse<void>(
            'accounts/confirm-registration',
            body
        );
    }

    getProfile(userId: string): Observable<HttpResponse<User>> {
        return undefined;
    }

    public getUsers(): Observable<User[]> {
        return this._apiClient.get<User[]>('accounts');
    }

    assignUserToRole(userId: string, roleId: string): Observable<HttpResponse<void>> {
        return undefined;
    }

    createUser(user: User): Observable<HttpResponse<void>> {
        return undefined;
    }

    deleteUser(userId: string): Observable<HttpResponse<void>> {
        return undefined;
    }

    unassignUserFromRole(userId: string, roleId: string): Observable<HttpResponse<void>> {
        return undefined;
    }

    public assignUserToProvider(
        data: AssignUserToProvider
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'accounts/assign-to-provider',
            data
        );
    }

    public getAssignRoleData(): Observable<AssignRole> {
        return this._apiClient.get<AssignRole>('roles');
    }

    public setRoles(requestBody: UpdateUserRole): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>('roles/update', requestBody);
    }

    public inactivateUser(userId: string): Observable<HttpResponse<void>> {
        const userID = JSON.stringify(userId);
        return this._apiClient.postWithResponse<void>(
            'accounts/inactivate-user',
            userID,
            new HttpHeaders({
                'Content-Type': 'application/json',
            })
        );
    }

    public inactivateMyUser(userId: string): Observable<HttpResponse<void>> {
        const userID = JSON.stringify(userId);
        return this._apiClient.postWithResponse<void>(
            'accounts/inactivate-my-user',
            userID,
            new HttpHeaders({
                'Content-Type': 'application/json',
            })
        );
    }

    public editUserName(
        userId: string,
        username: string
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'accounts/edit-username',
            { userID: userId, username },
            new HttpHeaders({
                'Content-Type': 'application/json',
            })
        );
    }

    public forgotPassword(
        forgotPasswordDto: IForgotPassword
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'accounts/forgot-password',
            forgotPasswordDto
        );
    }

    public resetPassword(
        resetPasswordDto: IChangePassword
    ): Observable<HttpResponse<void>> {
        return this._apiClient.postWithResponse<void>(
            'accounts/change-password',
            resetPasswordDto
        );
    }

    public canUseDmInBrowser(isApplication: boolean): Observable<HttpResponse<boolean>> {
        return this._apiClient.getWithResponse<boolean>(
            `accounts/can-use-dm-in-browser/${isApplication}`
        );
    }
}
