import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks.component';
import { TaskEditComponent } from './task.edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TaskListItemComponent } from './task-list-item/task-list-item.component';
import { TaskStateBadgeComponent } from './task-list-item/task-state-badge/task-state-badge.component';
import { UserChooserComponent } from './user-chooser/user-chooser.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { TaskTypeBadgeComponent } from './task-list-item/task-type-badge/task-type-badge.component';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { OrderDetailsModalComponent } from './order-details-modal/order-details-modal.component';
import {
    NgbAlertModule,
    NgbNavModule,
    NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'shared';
import { TaskStateModalComponent } from './task-state-modal/task-state-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BatchTaskActionsComponent } from './batch-task-actions/batch-task-actions.component';
import { MomentModule } from 'ngx-moment';

@NgModule({
    declarations: [
        TasksComponent,
        TaskEditComponent,
        TaskListItemComponent,
        TaskStateBadgeComponent,
        UserChooserComponent,
        UserModalComponent,
        TaskTypeBadgeComponent,
        OrderDetailsModalComponent,
        TaskStateModalComponent,
        BatchTaskActionsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppSharedModule,
        NgbPaginationModule,
        NgSelectModule,
        NgbAlertModule,
        MomentModule,
        NgbNavModule,
        SharedModule,
    ],
})
export class TasksModule {}
