<div class="container" *appHasRoles="['administrator', 'support']">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-6">
            <table
                class="table table-hover table-responsive-md"
                *ngIf="selectedOrder; else loading"
            >
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Username</th>
                        <th scope="col">Orderstate</th>
                        <th scope="col">Created</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let orderHistoryEntry of orderHistory">
                        <tr>
                            <td>{{ orderHistoryEntry.username }}</td>
                            <td>{{ orderHistoryEntry.orderState }}</td>
                            <td>
                                {{
                                    orderHistoryEntry.created
                                        | amDateFormat : 'YYYY.MM.DD. HH:mm'
                                }}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <ng-template #loading>
                <app-preloader></app-preloader>
            </ng-template>
        </div>
    </div>
</div>
