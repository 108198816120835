<div *ngIf="!states.isBillApprovalNeeded; else billApprovalButtons">
    <div *ngIf="states.areCollectingButtonsVisible">
        <button
            *ngIf="states.isCheckoutButtonVisible"
            (click)="actions.startCollectingPayment()"
            class="btn btn-block btn-success task-action-btn"
            [disabled]="states.isButtonClickForbidden"
        >
            <span *ngIf="!states.isStartPaymentInProgress"
                ><i class="fa fa-check"></i> {{ 'collecting.checkout' | translate }}</span
            >
            <span
                *ngIf="states.isStartPaymentInProgress"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span *ngIf="states.isStartPaymentInProgress">
                {{ 'common.working' | translate }}...</span
            >
        </button>
        <app-photo-button *ngIf="states.isPhotoButtonVisible"></app-photo-button>
        <button
            *ngIf="states.isPaymentReserved"
            class="btn btn-block btn-warning task-action-btn"
            (click)="actions.releaseReservedPayment()"
            [disabled]="states.isPaymentSucceeded"
        >
            <span *ngIf="!states.isReleasePaymentInProgress">
                <i class="fa fa-undo"></i>
                {{ 'collecting.back-to-the-shop' | translate }}
            </span>
            <span
                *ngIf="states.isReleasePaymentInProgress"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span *ngIf="states.isReleasePaymentInProgress">
                {{ 'common.working' | translate }}...</span
            >
        </button>
        <button
            *ngIf="states.isPhotoReady"
            (click)="actions.finishCollectingPayment()"
            [disabled]="states.isFinishPaymentInProgress"
            class="btn btn-block btn-success task-action-btn"
        >
            <span *ngIf="!states.isFinishPaymentInProgress"
                ><i class="fa fa-check"></i>
                {{ 'collecting.task-done' | translate }}</span
            >
            <span
                *ngIf="states.isFinishPaymentInProgress"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            <span *ngIf="states.isFinishPaymentInProgress">
                {{ 'common.working' | translate }}...</span
            >
        </button>
    </div>
</div>
<ng-template #billApprovalButtons>
    <div *ngIf="states.isCollectionReady">
        <app-photo-button></app-photo-button>
        <button
            *ngIf="states.isPhotoReady"
            (click)="states.setTotalPriceApprovalModalVisible(true)"
            class="btn btn-block btn-success task-action-btn"
        >
            <i class="fa fa-check"></i>{{ 'collecting.task-done' | translate }}
        </button>
    </div>
</ng-template>
