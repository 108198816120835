import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISortEvent } from '../../types/isort-event';
import { FilterEvent } from '../../types/filter-event';
import { FilterItem } from '../../dtos/filter-item';
import { FilteredPageQuery } from '../../dtos/filtered-page-query';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { IListPageResult } from '../../types/ilist-page-result';
import { ProgressLineService } from '../progress-line/progress-line.service';
import {
    API_CLIENT_SERVICE_IMPL,
    IAPIClientService,
} from '../../services/iapi-client.service';

@Injectable({
    providedIn: 'root',
})
export class ListService {
    public currentPaginatorPage = 1;
    public currentPaginatorPageSize: number;
    public listApiEndpoint: string;
    public onQueryResult: Function;
    private _listProperties: BehaviorSubject<FilteredPageQuery>;

    public get listProperties(): Observable<FilteredPageQuery> {
        return this._listProperties.asObservable();
    }

    public get listIndexOffset(): number {
        return (this.currentPaginatorPage - 1) * this.currentPaginatorPageSize;
    }

    constructor(
        @Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService,
        private _progressLineService: ProgressLineService
    ) {
        this.resetList();
    }

    public setPageSize(pageSize: number): void {
        const newListProperties = this._listProperties.getValue();
        this.currentPaginatorPageSize = pageSize;
        newListProperties.pageSize = pageSize;
        this._listProperties.next(newListProperties);
        this.getResults();
    }

    public resetList(): void {
        const defaultListProperties = new FilteredPageQuery();
        defaultListProperties.page = 1;
        defaultListProperties.pageSize = 50;
        defaultListProperties.filters = [];
        this.currentPaginatorPageSize = defaultListProperties.pageSize;
        this._listProperties = new BehaviorSubject<FilteredPageQuery>(
            defaultListProperties
        );
    }

    public onColumnSort({ column, isDescending }: ISortEvent): void {
        const newListProperties = this._listProperties.getValue();
        newListProperties.orderBy = column;
        newListProperties.orderDescending = isDescending;
        this.getResults();
    }

    public onColumnFilter(filterEvents: FilterEvent[]): void {
        const newProps = this._listProperties.getValue();
        const filters = newProps.filters;
        let newFilters: FilterItem[] = [];
        Object.values(filterEvents).forEach((event) => {
            if (!event.append || event.value === null) {
                for (let idx = 0; idx < filters.length; idx++) {
                    if (filters[idx].propertyName === event.column) {
                        filters[idx].value = null;
                    }
                }
                newFilters = filters.filter((x) => x.value !== null);
            }

            if (event.value !== null) {
                const filterItem = new FilterItem();
                filterItem.propertyName = event.column;
                filterItem.operator = event.filterOperator;
                filterItem.value = event.value;
                filterItem.logicalOperator = event.logicalOperator;
                newFilters.push(filterItem);
            }
        });
        newProps.filters = newFilters;
        this._listProperties.next(newProps);
        this.getResults();
    }

    public onPageChange(): void {
        const newListProperties = this._listProperties.getValue();
        newListProperties.page = this.currentPaginatorPage;
        this._listProperties.next(newListProperties);
        this.getResults();
    }

    public getResults(): void {
        this._progressLineService.showProgressLine();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
        });
        const result = this._apiClient.get<IListPageResult>(this.listApiEndpoint, {
            params: this.getHttpParams(),
            headers,
        });

        result.subscribe((resultListPage: IListPageResult) => {
            this.onQueryResult(resultListPage.results);
            const newListProperties = this._listProperties.getValue();
            newListProperties.rowCount = resultListPage.rowCount;
            this._listProperties.next(newListProperties);
            this._progressLineService.hideProgressLine();
        });
    }

    private getHttpParams(): HttpParams {
        let httpParams = new HttpParams();
        for (let [key, value] of Object.entries(this._listProperties.getValue())) {
            if (typeof value === 'object') {
                key += 'JSON';
                value = JSON.stringify(value);
            }
            httpParams = httpParams.append(key, value);
        }
        return httpParams;
    }
}
