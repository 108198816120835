import { Component, Input } from '@angular/core';
import { ColorClass, OrderItem, OrderItemState, OrderReplacementItem } from 'shared';
import { OrderItemListService } from 'dm-src/app/modules/shared/list/order-items-list/order-item-list.service';

@Component({
    selector: '[order-replacement-item]',
    templateUrl: './order-replacement-item.component.html',
    styleUrls: ['./order-replacement-item.component.scss'],
})
export class OrderReplacementItemComponent {
    @Input() public replacementItem: OrderReplacementItem;
    @Input() public orderItem: OrderItem;
    @Input() public orderItemID: number;
    @Input() index: number;
    @Input() isReturnInProgress = false;

    public get orderItemState(): string {
        return OrderItemState[this.orderItem.state];
    }

    public get class(): ColorClass {
        switch (this.orderItem.state) {
            case OrderItemState.Collected:
                return ColorClass.Success;
            case OrderItemState.Approved:
                return ColorClass.Dark;
            default:
                return ColorClass.Primary;
        }
    }

    constructor(public orderItemListService: OrderItemListService) {}

    public toggleOriginalItem(): void {
        this.orderItemListService.toggleOrderItemVisibility(this.orderItemID);
    }

    public openImage(src: string): void {
        this.orderItemListService.openImage(src);
    }
}
