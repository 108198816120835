import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { User } from 'projects/shared/src/lib/models/user';
import { UserRegistration } from 'projects/shared/src/lib/dtos/user-registration';
import { UpdateUserRole } from 'projects/shared/src/lib/dtos/update-user-role';
import { AssignRole } from 'projects/shared/src/lib/models/assign-role';
import { AssignUserToProvider } from 'projects/shared/src/lib/dtos/assign-user-to-provider';
import { IForgotPassword } from '../../dtos/i-forgot-password';
import { IChangePassword } from '../../dtos/i-change-password';

export const ACCOUNTS_SERVICE_IMPL = new InjectionToken<IAccountsService>(
    'accountsServiceImpl'
);

export interface IAccountsService {
    registerUser(userData: UserRegistration): Observable<HttpResponse<void>>;
    confirmRegistration(userId: string, token: string): Observable<HttpResponse<void>>;
    createUser(user: User): Observable<HttpResponse<void>>;
    deleteUser(userId: string): Observable<HttpResponse<void>>;
    assignUserToRole(userId: string, roleId: string): Observable<HttpResponse<void>>;
    unassignUserFromRole(userId: string, roleId: string): Observable<HttpResponse<void>>;
    getUsers(): Observable<User[]>;
    getAssignRoleData(): Observable<AssignRole>;
    setRoles(roleData: UpdateUserRole): Observable<HttpResponse<void>>;
    getProfile(userId: string): Observable<HttpResponse<User>>;
    assignUserToProvider(data: AssignUserToProvider): Observable<HttpResponse<void>>;
    inactivateUser(userId: string): Observable<HttpResponse<void>>;
    inactivateMyUser(userId: string): Observable<HttpResponse<void>>;
    editUserName(userId: string, username: string): Observable<HttpResponse<void>>;
    forgotPassword(forgotPasswordDto: IForgotPassword): Observable<HttpResponse<void>>;
    resetPassword(resetPasswordDto: IChangePassword): Observable<HttpResponse<void>>;
    canUseDmInBrowser(isApplication: boolean): Observable<HttpResponse<boolean>>;
}
