import { InjectionToken } from '@angular/core';
import { User } from '../../models/user';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ILoginParams } from '../../dtos/ilogin-params';
import { IPolicyRoles } from '../../types/i-policy-roles';
export const AUTH_SERVICE_IMPL = new InjectionToken<IAuthenticationService>(
    'authenticationServiceImpl'
);

export interface IAuthenticationService {
    readonly currentUser: User;
    applicationUser: Observable<User>;
    updateCurrentUser(user: User): void;
    isAuthenticated(): boolean;
    login(loginParams: ILoginParams): Observable<HttpResponse<User>>;
    logout(): Observable<HttpResponse<void>>;
    removeUserFromStorage(): void;
    getPolicyRoles(): IPolicyRoles[];
    getUserFromStorage(): User;
}
