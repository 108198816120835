import {
    Component,
    Inject,
    Input,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    IImageCompressionService,
    IMAGE_COMPRESSION_SERVICE_IMPL,
    IOrdersService,
    OrderItem,
    ORDERS_SERVICE_IMPL,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    EventType,
    ActionType,
    OrderStateEventLog,
} from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { CollectingStatesService } from '../../../collecting-states.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderRemovedItemReasons } from 'projects/shared/src/lib/types/order-removed-item-reasons';
import { CreateOrderRemovedItem } from 'projects/shared/src/lib/dtos/create-order-removed-item';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { ProductIdentificationSource } from 'dm-src/types/product-identification-source';

@Component({
    selector: '[app-items-to-collect-buttons]',
    templateUrl: './items-to-collect-buttons.component.html',
    styleUrls: ['./items-to-collect-buttons.component.scss'],
})
export class ItemsToCollectButtonsComponent implements OnInit {
    @Input() orderItem: OrderItem;
    @Input() orderRemovedItemReasons: OrderRemovedItemReasons[];
    @Input() canCollectManually = true;
    public imgResultAfterCompression = '';
    public returnImgSrc;
    public comment = '';
    public photoLoaded: boolean;
    public returnPhotoImageContent: string;
    public returnPhotoImageExtension: string;
    public selectedRemovedReasonID = '';
    @ViewChild('missingReplacementReasonModal', { static: false })
    private _missingReplacementReasonModal: TemplateRef<any>;
    public isReplaceItem = false;
    public title: string;
    public identifier: string;
    isWrapping = false;

    isNewWrapper = !!window.ReactNativeWebView;
    constructor(
        public states: CollectingStatesService,
        private _orderItemsService: CollectingItemsService,
        private _modalService: NgbModal,
        @Inject(ORDERS_SERVICE_IMPL)
        private _ordersService: IOrdersService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _nativeTakePhotoService: NativeTakePhotoService,
        private _isWrappingService: IsWrappingService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        @Inject(IMAGE_COMPRESSION_SERVICE_IMPL)
        private _imageCompressionService: IImageCompressionService
    ) {
        this.isWrapping = this._isWrappingService.isWrapping();

        if (this.isWrapping) {
            this.imgResultAfterCompression = null;
            this.photoLoaded = false;
        }
    }

    ngOnInit(): void {
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'CAMERA_START') {
                        this.setReturnPhotoImageContent(
                            'data:image/jpeg;base64,' + response?.result,
                            'jpeg'
                        );
                        this.imgResultAfterCompression =
                            'data:image/jpeg;base64,' + response?.result;
                    }
                });
        }
    }

    public get isRequiredComment() {
        if (this.orderRemovedItemReasons && this.selectedRemovedReasonID) {
            const selectedRemovedItemReason = this.orderRemovedItemReasons.find(
                (reason) => reason.id == this.selectedRemovedReasonID
            );
            return selectedRemovedItemReason.isRequiredComment;
        }
        return false;
    }

    public get isCommentInvalid() {
        return this.isRequiredComment && this.comment == '';
    }

    public get isRequiredPhoto() {
        if (this.orderRemovedItemReasons && this.selectedRemovedReasonID) {
            const selectedRemovedItemReason = this.orderRemovedItemReasons.find(
                (reason) => reason.id == this.selectedRemovedReasonID
            );
            return selectedRemovedItemReason.isRequiredPhoto;
        }
        return false;
    }

    public get isPhotoInvalid() {
        return this.isRequiredPhoto && this.imgResultAfterCompression == '';
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.reset();
    }

    public approveItem() {
        this._orderItemsService.approveItem(this.orderItem);
    }

    public replaceItem() {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.ReplaceOrderItemButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this.isReplaceItem = true;
        this.title = 'collecting.reason-for-replacement-product';
        this._modalService.open(this._missingReplacementReasonModal, {
            windowClass: 'modal-holder',
            centered: true,
            size: 'sm',
        });
    }

    public itemNotFound(): void {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.MissingOrderItemButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this.resetAll();
        this.isReplaceItem = false;
        this.title = 'collecting.reason-for-missing-product';
        this._modalService.open(this._missingReplacementReasonModal, {
            windowClass: 'modal-holder',
            centered: true,
            size: 'sm',
        });
    }

    onSubmit(): void {
        if (this.IsInvalid()) {
            return;
        }

        this.emptyNotRequiredProperty();

        const orderRemovedItem = {
            orderItemID: this.orderItem.orderItemID,
            imageUrl: this.imgResultAfterCompression,
            comment: this.comment,
            productRemovalReasonType: this.selectedRemovedReasonID,
        } as CreateOrderRemovedItem;

        this._ordersService.createOrderRemovedItem(orderRemovedItem).subscribe((resp) => {
            if (resp.status === 200) {
                this.closeModal();
                if (this.isReplaceItem) {
                    this._orderItemsService.replaceItem(this.orderItem);
                    if (this.states.onlineConsultation) {
                        this.showProductIdentifyModal();
                    }
                } else {
                    this._orderItemsService.itemNotFound(this.orderItem);
                }
            }
        });
    }

    handleReasonTypeChange(event: any) {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = this.isReplaceItem
            ? ActionType.ReplaceOrderItemReasonChanged
            : ActionType.MissingOrderItemReasonChanged;
        orderStateEventLog.fromStateJson = null;

        var selectedReasonName = '';

        if (this.orderRemovedItemReasons && this.selectedRemovedReasonID) {
            const selectedRemovedItemReason = this.orderRemovedItemReasons.find(
                (reason) => reason.id == this.selectedRemovedReasonID
            );

            selectedReasonName = selectedRemovedItemReason?.label;
        }

        orderStateEventLog.toStateJson = JSON.stringify({
            orderItem: this.orderItem,
            reasonID: this.selectedRemovedReasonID,
            reasonName: selectedReasonName,
        });
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();
    }

    showProductIdentifyModal(): void {
        this._orderItemsService
            .checkIfOrderModificationIsBlocked(this.states.orderID)
            .subscribe((blocked) => {
                if (!blocked) {
                    this.states.productIdentificationSource =
                        ProductIdentificationSource.Replacement;
                    this.states.replaceableOrderItem = this.orderItem;
                    this.states.setProductIdentifyModalVisible(true);
                }
            });
    }

    public closeModal(): void {
        this.resetAll();
        this._modalService.dismissAll();
    }

    public onPhotoInputChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            var orderStateEventLog = new OrderStateEventLog();

            orderStateEventLog.eventType = EventType.OrderItemStateChanged;
            orderStateEventLog.actionType = this.isReplaceItem
                ? ActionType.ReplaceOrderItemReasonImageChanged
                : ActionType.MissingOrderItemReasonImageChanged;
            orderStateEventLog.fromStateJson = JSON.stringify({
                orderItem: this.orderItem,
                fileName: fileInput.target.files[0].name,
            });
            orderStateEventLog.toStateJson = null;
            orderStateEventLog.orderID = this.states.orderID;
            orderStateEventLog.orderCode = this.states.orderCode;

            this._loggerClientService
                .addOrderStateEventLog(orderStateEventLog)
                .subscribe();
            const reader = new FileReader();

            reader.onload = (e) => {
                this._imageCompressionService
                    .compressImage(e.target.result, false, 50)
                    .then((result) => (this.imgResultAfterCompression = result));

                this.photoLoaded = true;
            };

            reader.onloadend = (e) => {
                const imageExtension = fileInput.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();
                this.setReturnPhotoImageContent(reader.result.toString(), imageExtension);
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    public setReturnPhotoImageContent(
        imageContent: string,
        imageExtension: string
    ): void {
        this.returnPhotoImageContent = imageContent;
        this.returnPhotoImageExtension = imageExtension;
        this.photoLoaded = true;
    }

    public resetAll() {
        this.comment = '';
        this.selectedRemovedReasonID = '';
        this.deletePhoto();
    }

    public deletePhoto(): void {
        this.returnPhotoImageContent = '';
        this.returnPhotoImageExtension = '';
        this.photoLoaded = false;
        this.imgResultAfterCompression = '';
        this._nativeTakePhotoService.truncateBase64ContentResult();
    }

    reset() {
        this.comment = '';
        this.deletePhoto();
    }

    handleTakeAPhoto() {
        this._iosAndAndroidNatvieFunctionsService.takeAPicture();
        if (this.isWrapping) {
            this._nativeTakePhotoService.getBase64ContentResult().subscribe((result) => {
                if (result && result !== '') {
                    this.imgResultAfterCompression = result;
                    this.photoLoaded = true;
                }
            });
        }
    }

    private IsInvalid(): boolean {
        if (
            this.selectedRemovedReasonID == '' ||
            this.isCommentInvalid ||
            this.isPhotoInvalid
        ) {
            return true;
        }
        return false;
    }

    private emptyNotRequiredProperty() {
        if (!this.isRequiredComment) {
            this.comment = '';
        }
        if (!this.isRequiredPhoto) {
            this.imgResultAfterCompression = '';
        }
    }
}
