import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';
import { CustomerContactData } from 'shared';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
    selector: 'app-customer-contact-data',
    templateUrl: './customer-contact-data.component.html',
    styleUrls: ['./customer-contact-data.component.scss'],
})
export class CustomerContactDataComponent implements OnInit {
    public isEditMode = false;
    public isSubmitted = false;
    public contactDataForm: FormGroup;
    public customerData = this._ordersService.customerData;
    preferredCountries: CountryISO[] = [CountryISO.Hungary, CountryISO.Austria];
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;
    separateDialCode = false;
    private correctPhoneNumber = '';

    public get fields() {
        return this.contactDataForm.controls;
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _ordersService: OrdersService
    ) {}

    ngOnInit(): void {
        this._ordersService.customerData.subscribe((customerData) => {
            this.contactDataForm = this._formBuilder.group({
                firstName: [customerData.firstName, Validators.required],
                lastName: [customerData.lastName, Validators.required],
                email: [customerData.email, [Validators.required, Validators.email]],
                phone: [customerData.phone, Validators.required],
                deliveryAddress: this._formBuilder.group({
                    postalCode: [
                        customerData.deliveryAddress.postalCode,
                        Validators.required,
                    ],
                    countyName: [
                        customerData.deliveryAddress.countyName,
                        Validators.required,
                    ],
                    cityName: [
                        customerData.deliveryAddress.cityName,
                        Validators.required,
                    ],
                    addressLine: [
                        customerData.deliveryAddress.addressLine,
                        Validators.required,
                    ],
                }),
            });
        });
    }

    public getAddressField(field: string) {
        return this.contactDataForm.get(['deliveryAddress', field]);
    }

    public updateContactData(): boolean {
        this.isSubmitted = true;

        if (this.contactDataForm.invalid) {
            return false;
        }

        this.contactDataForm.controls.phone.setValue(this.correctPhoneNumber);

        const customerData: CustomerContactData = { ...this.contactDataForm.value };
        this._ordersService
            .updateCustomerContactData(customerData)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.isEditMode = false;
                }
            });
    }

    onPhoneChanges(): void {
        this.correctPhoneNumber = this.contactDataForm.controls?.phone?.value?.number;
    }
}
