import { Component, Input, OnInit } from '@angular/core';
import { CustomerCard } from 'dm-src/models/customer-card';

@Component({
    selector: 'app-customer-card',
    templateUrl: './customer-card.component.html',
    styleUrls: ['./customer-card.component.scss'],
})
export class CustomerCardComponent implements OnInit {
    @Input() card: CustomerCard;
    constructor() {}

    ngOnInit(): void {}
}
