import { Inject, Injectable } from '@angular/core';
import {
    ISupplierRegionsService,
    SUPPLIER_REGIONS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isupplier-regions-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupplierRegion } from 'dm-src/models/supplier-region';
import { ISelectOption } from 'shared';
import { FlashMessageService } from 'shared';
import { map, share } from 'rxjs/operators';
import { ISupplierRegionOption } from 'dm-src/types/i-supplier-region-option';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class SupplierRegionsService {
    private _supplierRegions: BehaviorSubject<SupplierRegion[]>;
    private _zipCodes: ISelectOption[];
    private _availableZipCodes: BehaviorSubject<ISelectOption[]>;
    private _selectedRegionID: string;
    private _selectedZipCodes: BehaviorSubject<ISelectOption[]>;

    public get supplierRegionOptions(): Observable<ISupplierRegionOption[]> {
        return this._supplierRegions.asObservable().pipe(
            map((regions: SupplierRegion[]) =>
                regions.map((region) => {
                    const option: ISupplierRegionOption = {
                        id: region.supplierRegionID,
                        label: region.regionName,
                        name: region.regionName,
                        lat: region.lat,
                        long: region.long,
                    };
                    return option;
                })
            )
        );
    }

    public get availableZipCodes(): Observable<ISelectOption[]> {
        return this._availableZipCodes.asObservable();
    }

    public get selectedZipCodes(): Observable<ISelectOption[]> {
        return this._selectedZipCodes.asObservable();
    }

    constructor(
        @Inject(SUPPLIER_REGIONS_SERVICE_IMPL)
        private _supplierRegionsService: ISupplierRegionsService,
        private _flashMessageService: FlashMessageService
    ) {
        this._supplierRegions = new BehaviorSubject<SupplierRegion[]>([]);
        this._selectedRegionID = null;
        this._selectedZipCodes = new BehaviorSubject<ISelectOption[]>([]);
        this._availableZipCodes = new BehaviorSubject<ISelectOption[]>([]);
    }

    public getSupplierRegionsData(): void {
        this._supplierRegionsService.getSupplierRegionsData().subscribe((response) => {
            if (response.status !== 200) {
                this._flashMessageService.showStoredMessage();
            } else {
                let supplierRegions = response.body.supplierRegions;
                supplierRegions = supplierRegions.map((region) => {
                    region.isChanged = false;
                    return region;
                });
                this._supplierRegions.next(supplierRegions);
                this._zipCodes = response.body.zipCodes;
            }
        });
    }

    public addNewSupplierRegion(newRegionName: string): void {
        const currentRegions = this._supplierRegions.getValue();
        const newRegion = new SupplierRegion();
        newRegion.regionName = newRegionName;
        newRegion.assignedZipCodes = [];
        newRegion.isChanged = true;
        this._supplierRegions.next([...currentRegions, newRegion]);
    }

    public assignZipCode(zipCode: ISelectOption): void {
        const updatedRegions = this._supplierRegions.getValue().map((region) => {
            if (region.supplierRegionID === this._selectedRegionID) {
                region.isChanged = true;
                this._selectedZipCodes.next(region.assignedZipCodes);
            }

            return region;
        });

        this._supplierRegions.next(updatedRegions);
    }

    public assignLatLong(lat: number, long: number): void {
        const updatedRegions = this._supplierRegions.getValue().map((region) => {
            if (region.supplierRegionID === this._selectedRegionID) {
                region.isChanged = true;
                region.lat = lat;
                region.long = long;
            }

            return region;
        });
        this._supplierRegions.next(updatedRegions);
    }

    public changeSelectedRegion(supplierRegionID: string) {
        this._selectedRegionID = supplierRegionID;
        this.updateAvailableZipCodes();
        this.updateSelectedZipCodes();
    }

    public unassignZipCode(zipCodeID: string): void {
        const updatedRegions = this._supplierRegions.getValue().map((region) => {
            if (
                region.supplierRegionID === this._selectedRegionID &&
                region.assignedZipCodes.some((x) => x.id)
            ) {
                region.assignedZipCodes = region.assignedZipCodes.filter(
                    (assignedZipCode) => assignedZipCode.id !== zipCodeID
                );

                this._selectedZipCodes.next(region.assignedZipCodes);
                region.isChanged = true;
            }

            return region;
        });

        this._supplierRegions.next(updatedRegions);
    }

    public saveRegions(): Observable<HttpResponse<SupplierRegion[]>> {
        const changedRegions = this._supplierRegions
            .getValue()
            .filter((x) => x.isChanged === true);

        const apiResonse = this._supplierRegionsService
            .saveSupplierRegionsData(changedRegions)
            .pipe(share());

        apiResonse.subscribe((response) => {
            if (response.status === 200) {
                const newSupplierRegions = response.body;

                if (newSupplierRegions.length) {
                    const updatedRegions = this._supplierRegions
                        .getValue()
                        .map((region) => {
                            const newRegionIdx = newSupplierRegions.findIndex(
                                (r) => r.regionName === region.regionName
                            );

                            if (newRegionIdx > -1) {
                                region.supplierRegionID =
                                    newSupplierRegions[newRegionIdx].supplierRegionID;
                            }

                            region.isChanged = false;

                            return region;
                        });
                    this._supplierRegions.next(updatedRegions);
                }
            }
        });
        return apiResonse;
    }

    public deleteRegion(supplierRegionID: string): Observable<HttpResponse<void>> {
        const apiResponse = this._supplierRegionsService
            .deleteSupplierRegion(supplierRegionID)
            .pipe(share());

        apiResponse.subscribe((response) => {
            if (response.status === 200) {
                const updatedRegions = this._supplierRegions
                    .getValue()
                    .filter((x) => x.supplierRegionID !== this._selectedRegionID);
                this._supplierRegions.next(updatedRegions);
                this._selectedZipCodes.next([]);
                this._selectedRegionID = null;
            }
        });
        return apiResponse;
    }

    private updateAvailableZipCodes(): void {
        const reservedZipIDs = [];
        this._supplierRegions.getValue().forEach((region) => {
            if (
                region.supplierRegionID !== this._selectedRegionID &&
                region.assignedZipCodes.length > 0
            ) {
                region.assignedZipCodes.forEach((zipCode) =>
                    reservedZipIDs.push(zipCode.id)
                );
            }
        });
        const newZipCodes = this._zipCodes.filter(
            (zipCode) => !reservedZipIDs.includes(zipCode.id)
        );

        this._availableZipCodes.next(newZipCodes);
    }

    private updateSelectedZipCodes(): void {
        const selectedRegion = this._supplierRegions
            .getValue()
            .find((x) => x.supplierRegionID === this._selectedRegionID);
        this._selectedZipCodes.next(selectedRegion.assignedZipCodes);
    }
}
