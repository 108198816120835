import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { OrdersService } from './orders.service';
import { Order } from 'dm-src/models/order';
import {
    AUTH_SERVICE_IMPL,
    BaseList,
    IAuthenticationService,
    QueryOperator,
} from 'shared';
import { OrderState } from 'dm-src/types/order-state';
import { ISelectOption } from 'shared';
import { TableColumnFilterType } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { ListService } from 'shared';
import { DeliveryType } from 'dm-src/types/delivery-type';
import { LogicalOperator } from 'shared';
import { ProgressLineService } from 'shared';
import { FormHelper } from 'shared';
import { PaymentState } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { columns } from './orders.table.columns';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent extends BaseList implements OnInit, AfterViewInit {
    public QueryOperator = QueryOperator;
    public LogicalOperator = LogicalOperator;
    public paymentStateOptions: ISelectOption[];
    public FilterType = TableColumnFilterType;
    public dateFormat: string;
    public isReady: boolean;
    public areColumnsReady: boolean;
    public visibleColumnIndexes: number[];
    public selectedColumnKeys: string;

    constructor(
        public ordersService: OrdersService,
        public listService: ListService,
        private _progressLineService: ProgressLineService,
        private _translateService: TranslateService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {
        super(columns);

        this.isReady = false;
        this.dateFormat = environment.deliveryDateFormat;
        this.paymentStateOptions = this.createPaymentOptions();
    }

    public openOrderItemsModal(order: Order) {
        this.ordersService.selectOrder(order.orderID);
        this.ordersService.setOrderItemsModalVisibility(true);
    }

    public isVisibleColumn(index: number): boolean {
        return this.visibleColumnIndexes.includes(index);
    }

    ngOnInit(): void {
        this.ordersService.initOrdersList();

        this.ordersService.filterOptions.pipe(skip(1)).subscribe((filterOptions) => {
            this.setColumnProperty(
                ['ProviderID'],
                'filterOptions',
                filterOptions.providers
            );
            this.setColumnProperty(
                ['OrderPaymentList.StatusID'],
                'filterOptions',
                this.createPaymentOptions()
            );

            this.setUserSelectedColumns();
        });

        this.areColumnsReady = true;

        this.setVisibleColumnIndexes();
    }

    ngAfterViewInit(): void {
        this._progressLineService.hideProgressLine();
        this.isReady = true;
    }

    public setColumnsVisibility(selectedColumns: ISelectOption[]): void {
        if (!selectedColumns) return;
        super.setColumnsVisibility(selectedColumns);
        const selectedColumnKeys = this.getSelectedColumnKeys(selectedColumns);
        if (this.selectedColumnKeys !== selectedColumnKeys) {
            this.selectedColumnKeys = selectedColumnKeys;
            this.setVisibleColumnIndexes();
        }
    }

    private getSelectedColumnKeys(selectedColumns: ISelectOption[]): string {
        if (!selectedColumns) return;
        return JSON.stringify(selectedColumns.map((column) => column?.id));
    }

    private createPaymentOptions(): ISelectOption[] {
        const options = FormHelper.createTranslatedSelectOptions(
            PaymentState,
            this._translateService,
            'payment-state'
        );
        return options.map((option) => {
            option.id = this.createPaymentOptionValue(
                parseInt(option.id) as PaymentState
            );
            return option;
        });
    }

    private createPaymentOptionValue(paymentState: PaymentState): string {
        let optionValue;

        switch (paymentState) {
            case PaymentState.Refunded:
                optionValue = 'REFUND';
                break;
            case PaymentState.Failed:
                optionValue = 'FAIL FAILED';
                break;
            case PaymentState.Expired:
                optionValue = 'EXPIRED TIMEOUT';
                break;
            case PaymentState.Canceled:
                optionValue = 'CANCEL CANCELED';
                break;
            case PaymentState.NotStarted:
                optionValue = 'INPROGRESS INIT INVALID';
                break;
            case PaymentState.Reserved:
                optionValue = 'RESERVED';
                break;
            case PaymentState.Succeeded:
                optionValue = 'SUCCESS FINISHED SUCCEEDED';
                break;
        }

        return optionValue;
    }

    private setUserSelectedColumns(): void {
        let k =
            this._authService.currentUser.getUserSettingValue(
                'orders',
                'visibleColumns'
            ) || JSON.stringify(this.columns.map((x) => JSON.stringify(x.name)));

        this.selectedColumnKeys = k;
    }

    private setVisibleColumnIndexes(): void {
        this.visibleColumnIndexes = this.columns
            .filter((x) => x.isVisible)
            .map((column) => this.columns.indexOf(column));
    }
}
