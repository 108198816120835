<div *ngIf="hasCardToDisplay" class="cardContainer">
    <app-customer-card
        [card]="customerCard"
        *ngIf="!isInprogress; else inProgress"
    ></app-customer-card>
    <ng-template #inProgress>
        <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        ></span>
        <span>{{ 'common.working' | translate }}...</span>
    </ng-template>
</div>
