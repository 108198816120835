import { Component, Input, Inject } from '@angular/core';
import {
    OrderItem,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { CollectingStatesService } from '../../../collecting-states.service';
import { ProductIdentificationSource } from '../../../../../../types/product-identification-source';

@Component({
    selector: '[app-replaced-items-buttons]',
    templateUrl: './replaced-items-buttons.component.html',
    styleUrls: ['./replaced-items-buttons.component.scss'],
})
export class ReplacedItemsButtonsComponent {
    @Input() orderItem: OrderItem;
    @Input() hasBarcodeReader = false;
    @Input() canEditManually = true;

    constructor(
        private _collectingItemsService: CollectingItemsService,
        public states: CollectingStatesService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService
    ) {}

    public cancel() {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.ReplacedOrderItemCancelButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._collectingItemsService.removeOrderItemFromReplaced(this.orderItem);
    }

    public showProductIdentifyModal(): void {
        this._collectingItemsService
            .checkIfOrderModificationIsBlocked(this.states.orderID)
            .subscribe((blocked) => {
                if (!blocked) {
                    this.states.productIdentificationSource =
                        ProductIdentificationSource.Replacement;
                    this.states.replaceableOrderItem = this.orderItem;
                    this.states.setProductIdentifyModalVisible(true);

                    var orderStateEventLog = new OrderStateEventLog();

                    orderStateEventLog.eventType = EventType.OrderItemStateChanged;
                    orderStateEventLog.actionType =
                        ActionType.ReplacedOrderItemIdentifyingStarted;
                    orderStateEventLog.fromStateJson = null;
                    orderStateEventLog.toStateJson = null;
                    orderStateEventLog.orderID = this.states.orderID;
                    orderStateEventLog.orderCode = this.states.orderCode;

                    this._loggerClientService
                        .addOrderStateEventLog(orderStateEventLog)
                        .subscribe();
                }
            });
    }

    public edit() {
        this._collectingItemsService.editReplacementItem(this.orderItem);
    }

    public itemNotFound() {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.ReplacedOrderItemNotFoundButtonClicked;
        orderStateEventLog.fromStateJson = null;
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.states.orderID;
        orderStateEventLog.orderCode = this.states.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._collectingItemsService.replacementItemNotFound(this.orderItem);
    }
}
