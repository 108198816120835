import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierRegionsComponent } from './supplier-regions.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ZipBadgeComponent } from './zip-badge/zip-badge.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ModalModule } from 'shared';
import { BtnProgressModule } from 'shared';

@NgModule({
    declarations: [SupplierRegionsComponent, ZipBadgeComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NgxSelectModule,
        FormsModule,
        NgMultiSelectDropDownModule,
        AngularMultiSelectModule,
        ModalModule,
        BtnProgressModule,
    ],
})
export class SupplierRegionsModule {}
