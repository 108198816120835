<lib-modal
    [title]="'markup.manual-sku-check-title'"
    [componentID]="'manual-sku-check-modal'"
    [bgColorClass]="ColorClass.Warning"
>
    <p class="font-weight-bold">
        {{ 'markup.manual-sku-check-text' | translate }}
    </p>
    <form [formGroup]="manualSKUCheckForm">
        <div class="form-group">
            <label for="sku">{{ 'common.sku' | translate }}</label>
            <input
                type="number"
                id="sku"
                formControlName="sku"
                class="form-control"
                placeholder="{{ 'markup.placeholder-text' | translate }}: 11067"
                required
                autofocus
                [ngClass]="{
                    'is-invalid': isSubmitted && fields.sku.errors
                }"
            />
            <div *ngIf="isSubmitted && fields.sku.errors" class="invalid-feedback">
                <div *ngIf="fields.sku.errors.required">
                    {{ 'common.sku' | translate }} {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>
        <lib-btn-progress
            [btnClass]="'btn btn-primary btn-block'"
            [iconClass]="'fa fa-search'"
            [inProgress]="isRequestInProgress"
            (onClick)="submit()"
        >
            {{ 'collecting.identify' | translate }}
        </lib-btn-progress>
        <lib-btn-close></lib-btn-close>
    </form>
    <ng-container *ngIf="canDebugOrder">
        <input type="text" class="form-control mt-3" [(ngModel)]="manualGTIN" />
        <button class="btn btn-success mt-3" (click)="onManualGTINSet()">
            Find GTIN
        </button>
    </ng-container>
</lib-modal>
