<h1>{{ 'my-worktime.select-worktime' | translate }}</h1>
<div class="justify-content-start">
    <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem *appHasRoles="['administrator']">
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Area</th>
                            <th scope="col">Availability</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let area of allAreas; let i = index"
                            [attr.data-index]="i"
                        >
                            <th scope="row">{{ i }}</th>
                            <td>{{ area.name }}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    [checked]="area.available"
                                    (click)="changeStatus(area.instantDeliveryAreaID)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </nav>

    <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem *appHasRoles="['instantsupplier']">
            <div *ngIf="worktime; else createWorktimeBlock">
                <div class="h5 alert alert-success">
                    {{ 'my-worktime.active-worktime' | translate }}:
                    <span class="h5 font-weight-bold text-uppercase"
                        >{{ worktime.worktimeStart | date : 'yyyy-MM-dd' }}&nbsp;</span
                    >
                    <span class="h5 font-weight-bold text-uppercase">{{
                        worktime.worktimeStart | date : 'HH:mm:ss'
                    }}</span>
                    -
                    <span class="h5 font-weight-bold text-uppercase">{{
                        worktime.worktimeEnd | date : 'HH:mm:ss'
                    }}</span>
                </div>
                <button type="button" class="btn btn-danger" (click)="cancelWorktime()">
                    {{ 'my-worktime.cancel-worktime' | translate }}
                </button>
            </div>

            <ng-template #createWorktimeBlock>
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <p class="mt-2 mb-1">
                            {{ 'my-worktime.worktime-from' | translate }}
                        </p>
                        <ngx-select
                            [noAutoComplete]="false"
                            [allowClear]="false"
                            (select)="selectFrom($event)"
                            [items]="selectOptions"
                        >
                        </ngx-select>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <p class="mt-2 mb-1">
                            {{ 'my-worktime.worktime-to' | translate }}
                        </p>
                        <ngx-select
                            [noAutoComplete]="false"
                            [allowClear]="false"
                            (select)="selectTo($event)"
                            [items]="worktimeEndSelectOptions"
                        >
                        </ngx-select>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <p class="mt-2 mb-1">{{ 'my-worktime.area' | translate }}</p>
                        <ngx-select
                            [noAutoComplete]="false"
                            [allowClear]="false"
                            (select)="selectArea($event)"
                            [items]="areas"
                        >
                        </ngx-select>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-3">
                        <p class="mt-2 mb-1">&nbsp;</p>
                        <!--align div. please do not remove -->
                        <button
                            type="button"
                            class="btn btn-success w-100"
                            (click)="submitWorktime()"
                        >
                            {{ 'my-worktime.start-worktime' | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </nav>
</div>
