<li class="list-group-item">
    <div class="container">
        <div class="row">
            <span class="badge badge-primary" *ngIf="label !== undefined">{{
                label
            }}</span>
        </div>
        <div class="row flex">
            <div class="col-md-3">
                <img [src]="orderItem.itemThumbnailSrc" />
            </div>
            <div class="col-md-9">
                <span>
                    <div class="row">
                        <div class="col center">
                            <span class="text-small">{{ orderItem.orderItemName }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>
                                <strong>{{ 'common.sku' | translate }}:</strong>
                                {{ orderItem.sku || '-' }}
                            </label>
                            <label>
                                <strong>{{ 'common.price' | translate }}:</strong>
                                {{ orderItem.price | priceround }}
                                {{ 'common.currency' | translate }}
                            </label>
                        </div>
                        <div class="col" *ngIf="orderItem.orderReplacementItem === null">
                            <app-order-item-state-view
                                [orderItemState]="orderItem.state"
                            ></app-order-item-state-view>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>
                    <strong>{{ 'collecting.original' | translate }}: </strong>
                    {{ orderItem.orderedQuantity | decimal }}
                </p>
            </div>
            <div
                class="col"
                class="align-self-start"
                *ngIf="orderItem.orderReplacementItem === null"
            >
                <p class="item-padding">
                    <strong>{{ 'collecting.collected' | translate }}: </strong>
                    {{ orderItem.collectedQuantity | decimal }}
                </p>
            </div>
            <div
                class="col"
                class="align-self-end"
                *ngIf="orderItem.orderReplacementItem === null"
            >
                <p>
                    <strong>{{ 'collecting.approved' | translate }}: </strong>
                    {{ quantity | decimal }}
                </p>
            </div>
        </div>
    </div>
</li>
