import { Inject, Injectable } from '@angular/core';
import { ListService } from 'shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrdersService, ORDERS_SERVICE_IMPL } from 'shared';
import { ProviderOrder } from 'dm-src/models/provider-order';
import { OrderListFilterOptions } from 'shared';
import { ProviderOrderListFilterOptions } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class ProviderOrdersService {
    public selectedOrder: ProviderOrder;
    private _orders: BehaviorSubject<ProviderOrder[]>;
    private _filterOptions: BehaviorSubject<ProviderOrderListFilterOptions>;
    private _isOrderDetailsModalShown: BehaviorSubject<boolean>;

    public get filterOptions(): Observable<ProviderOrderListFilterOptions> {
        return this._filterOptions.asObservable();
    }

    public get isOrderDetailsModalShown() {
        return this._isOrderDetailsModalShown.asObservable();
    }

    public get orders(): Observable<ProviderOrder[]> {
        return this._orders.asObservable();
    }

    constructor(
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        private _listService: ListService
    ) {
        this._isOrderDetailsModalShown = new BehaviorSubject<boolean>(false);
        this._orders = new BehaviorSubject<ProviderOrder[]>([]);
        this._filterOptions = new BehaviorSubject<ProviderOrderListFilterOptions>(
            new ProviderOrderListFilterOptions()
        );
    }

    public initOrdersList() {
        this._ordersService.getProviderFilterOptions().subscribe((filterOptions) => {
            this._filterOptions.next(filterOptions);
        });
        this._listService.onQueryResult = (result) => this._orders.next(result);
        this._listService.listApiEndpoint = 'providerorders';
        this._listService.resetList();
        this._listService.getResults();
    }

    public setOrderDetailsModalVisibility(isVisible: boolean) {
        this._isOrderDetailsModalShown.next(isVisible);
    }
}
