<div class="btn-group btn-block" *ngIf="isWaitingForAccept">
    <button class="btn btn-sm btn-success" (click)="acceptAssignment()">
        {{ 'my-tasks.accept-task' | translate }}
    </button>
    <button class="btn btn-sm btn-danger">
        {{ 'my-tasks.reject-task' | translate }}
    </button>
</div>
<button
    *ngIf="!isWaitingForAccept && !isLoading"
    [class]="buttonClasses"
    appPreventDoubleClick
    (throttledClick)="startRefunding()"
>
    {{ 'my-tasks.refunded-order-return-price' | translate }}
</button>
<div *ngIf="isLoading" class="w-100 p-6 text-center">
    <i class="fa fa-spinner fa-spin loading-icon-custom" aria-hidden="true"></i>
</div>
