<h1>Users</h1>
<ng-template #loading>
    <app-preloader></app-preloader>
</ng-template>
<table class="table table-hover table-responsive-md" *ngIf="users; else loading">
    <thead class="thead-light">
        <tr>
            <th scope="col">#</th>
            <th scope="col">{{ 'common.username' | translate }}</th>
            <th scope="col">{{ 'common.email' | translate }}</th>
            <th scope="col">{{ 'users.user-roles' | translate }}</th>
            <th scope="col">{{ 'users.user-efficiency' | translate }}</th>
            <th scope="col">{{ 'users.actions' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>
                {{ user.username }}
                <button
                    class="btn"
                    *ngIf="canEditUserName"
                    (click)="loadUserNameEditModal(user)"
                >
                    <i class="fa fa-edit"></i>
                </button>
            </td>
            <td>{{ user.email }}</td>
            <td><app-role-chooser [user]="user"></app-role-chooser></td>
            <td>{{ user.efficiency }}</td>
            <td>
                <button
                    type="button"
                    class="btn btn-warning"
                    (click)="loadInactivateCofirmModal(user)"
                >
                    {{ 'users.inactivate' | translate }}
                </button>
            </td>
        </tr>
    </tbody>
</table>
<app-role-modal></app-role-modal>
<lib-modal
    [title]="'users.user-inactivation-title' | translate"
    [componentID]="'inactivate-user'"
>
    <div align="center">
        <p>{{ 'users.user-inactivation' | translate }}</p>
        <p>{{ selectedUser?.username }}</p>
    </div>
    <div class="overlay-right d-flex justify-content-around">
        <div class="btn-group">
            <button class="btn btn-success" (click)="inactivateUser()">
                {{ 'common.yes' | translate }}
            </button>
            <button
                class="btn btn-primary"
                (click)="modalService.setModalVisibility(false, 'inactivate-user')"
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </div>
</lib-modal>
<lib-modal
    [title]="'users.edit-username-title' | translate"
    [componentID]="'edit-username'"
    *ngIf="selectedUser"
>
    <div align="center">
        <p>{{ 'users.edit-username' | translate }}</p>
        <p>
            <input class="form-control" [(ngModel)]="selectedUser.username" />
        </p>
    </div>
    <div class="overlay-right d-flex justify-content-around">
        <div class="btn-group">
            <button class="btn btn-success w-100" (click)="saveUserName()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </div>
</lib-modal>
