import { Component, OnInit } from '@angular/core';
import { TasksService } from 'dm-src/app/modules/tasks/tasks.service';
import { Modal } from 'dm-src/types/modal';

@Component({
    selector: '[app-batch-task-actions]',
    templateUrl: './batch-task-actions.component.html',
    styleUrls: ['./batch-task-actions.component.scss'],
})
export class BatchTaskActionsComponent {
    public Modal = Modal;

    get hasSelectedTasks(): boolean {
        return this.tasksService.hasSelectedTasks;
    }

    constructor(public tasksService: TasksService) {}
}
