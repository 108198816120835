import { Component, Input, OnInit } from '@angular/core';
import { ColorClass, OrderItem, OrderItemState } from 'shared';

@Component({
    selector: 'app-order-item-view',
    templateUrl: './order-item-view.component.html',
    styleUrls: ['./order-item-view.component.scss'],
})
export class OrderItemViewComponent implements OnInit {
    public ColorClass = ColorClass;
    public OrderItemState = OrderItemState;
    @Input() public color: ColorClass = ColorClass.White;
    @Input() public label: string;
    @Input() public orderItem: OrderItem;

    public get quantity(): number {
        return this.orderItem.state === OrderItemState.Approved
            ? this.orderItem.quantity
            : 0;
    }

    constructor() {}

    ngOnInit(): void {
        if (this.orderItem.state === OrderItemState.NotFound) {
            this.color = ColorClass.Light;
        }
    }
}
