import { ISelectOption } from '../types/i-select-option';
import { EnumHelper } from './enum.helper';
import { TranslateService } from '@ngx-translate/core';

export class FormHelper {
    public static createSelectOptions<TEnum>(
        enumObj: TEnum,
        toHumanCase = true
    ): ISelectOption[] {
        return EnumHelper.enumToOptions<TEnum>(enumObj, toHumanCase);
    }

    public static createTranslatedSelectOptions<TEnum>(
        enumObj: TEnum,
        translator: TranslateService,
        translationNamespace = ''
    ): ISelectOption[] {
        const options = EnumHelper.enumToOptions<TEnum>(enumObj, false);

        return options.map((option) => {
            option.label = translator.instant(`${translationNamespace}.${option.label}`);
            return option;
        });
    }
}
