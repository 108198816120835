<th *ngIf="!hasReplacementItem" scope="row">{{ index + 1 }}</th>
<td *ngIf="hasReplacementItem"></td>
<td>{{ orderItem.orderItemName }}</td>
<td>
    <img
        class="product-photo"
        *ngIf="orderItem.itemThumbnailSrc !== null; else noImage"
        [src]="orderItem.itemThumbnailSrc"
        [alt]="orderItem.orderItemName"
    />
    <ng-template #noImage>
        <div class="placeholder">
            <span>No photo</span>
        </div>
    </ng-template>
</td>
<td>
    <span *ngIf="hasReplacementItem" class="badge badge-primary">Original</span>
    <span
        *ngIf="
            !hasReplacementItem && hasState && orderItem.state == OrderItemState.Collected
        "
        class="badge badge-success"
        >Collected</span
    >
    <span
        *ngIf="
            !hasReplacementItem && hasState && orderItem.state == OrderItemState.Approved
        "
        class="badge badge-dark"
        >Approved</span
    >
    <span *ngIf="!hasState" class="badge badge-info">ToDo</span>
</td>
<td>{{ orderItem.unitPrice }}</td>
<td>{{ orderItem.orderedQuantity }}</td>
<td>{{ orderItem.collectedQuantity }}</td>
<td>{{ orderItem.quantity }}</td>
<td>{{ orderItem.price }}</td>
<td
    order-return-control
    [orderItem]="orderItem"
    [isReturnInProgress]="orderItemListService.isReturnInProgress | async"
></td>
<td>{{ orderItem.removedReasonComment }}</td>
<td>
    <img
        src="{{ orderItem.removedReasonImageUrl }}"
        class="reason-image"
        (click)="openImage(orderItem.removedReasonImageUrl)"
    />
</td>
