import { Component, Input } from '@angular/core';
import { OrderItem } from 'shared';
import { CollectingStatesService } from 'dm-src/app/modules/collecting/collecting-states.service';

@Component({
    selector: 'app-order-item-details',
    templateUrl: './order-item-details.component.html',
    styleUrls: ['./order-item-details.component.scss'],
})
export class OrderItemDetailsComponent {
    @Input() orderItem: OrderItem;
    @Input() isMainPageCollecting: boolean = true;

    constructor(public states: CollectingStatesService) {}
}
