import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface SumUpCallbackModel {
    smpStatus: string;
    smpTxCode: string;
    foreignTxId: string;
}

@Injectable({
    providedIn: 'root',
})
export class SumupService {
    private sumupBehaviorSubject: BehaviorSubject<SumUpCallbackModel> =
        new BehaviorSubject(null);
    private inputDomElement?: HTMLInputElement;

    constructor() {}

    init(): void {
        try {
            this.inputDomElement = document.getElementById(
                'sumUpInput'
            ) as HTMLInputElement;

            this.inputDomElement?.addEventListener('change', (e: Event) => {
                const value = JSON.parse((e.target as HTMLInputElement).value);
                this.sumupBehaviorSubject.next(value);
            });
        } catch (e) {
            console.error(e);
        }
    }

    getCallbackResult(): Observable<SumUpCallbackModel> {
        return this.sumupBehaviorSubject;
    }
}
