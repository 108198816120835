import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

export const BARCODE_FACTORY_SERVICE_IMPL = new InjectionToken<IBarcodeFactoryService>(
    'barcodeFactoryServiceImpl'
);

export interface IBarcodeFactoryService {
    getBarcode(orderID: number): Observable<HttpResponse<Blob>>;
}
