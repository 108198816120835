<ng-template #supplierAddressApprovalModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'my-account.approve-address' | translate }}
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="closeModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ngb-alert
            [type]="ColorClass.Danger"
            *ngIf="!supplierDataService.isAddressLocated; else validator"
        >
            {{ 'my-account.address-not-found' | translate }}
        </ngb-alert>
        <ng-template #validator>
            <div *ngIf="!supplierDataService.isPartialMatch">
                {{ 'my-account.address-found' | translate }}
            </div>
            <div *ngIf="supplierDataService.isPartialMatch">
                {{ 'my-account.address-partially-found' | translate }}
            </div>
            <strong>{{ supplierDataService.locatedAddressLine | async }}</strong>
            <div>{{ 'my-account.address-found-acceptance' | translate }}</div>
        </ng-template>
    </div>
    <div class="modal-footer">
        <div class="btn-group btn-group-sm">
            <button
                *ngIf="supplierDataService.isAddressLocated"
                type="button"
                (click)="approve()"
                class="btn btn-success"
            >
                {{ 'common.approve' | translate }}
            </button>
            <button
                *ngIf="supplierDataService.isAddressLocated"
                type="button"
                (click)="closeModal()"
                class="btn btn-warning"
            >
                {{ 'common.cancel' | translate }}
            </button>
            <button
                *ngIf="!supplierDataService.isAddressLocated"
                type="button"
                (click)="closeModal()"
                class="btn btn-warning"
            >
                OK
            </button>
        </div>
    </div>
</ng-template>
