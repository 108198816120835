<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    {{ 'my-account.account-data' | translate }}
                </div>
                <div class="card-body">
                    <nav ngbNav #nav="ngbNav" class="nav-tabs">
                        <ng-container ngbNavItem *appHasRoles="['picker', 'deliverer']"
                            ><a ngbNavLink>{{
                                'my-account.supplier-data' | translate
                            }}</a>
                            <ng-template ngbNavContent>
                                <app-account-supplier-data></app-account-supplier-data>
                            </ng-template>
                        </ng-container>
                    </nav>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container text-break w-50">
    {{ serializedLocalStorage }}
</div>
