import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ACCOUNTS_SERVICE_IMPL, IAccountsService, MustMatch } from 'shared';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';

@Component({
    selector: 'app-account-basic-data',
    templateUrl: './account-basic-data.component.html',
    styleUrls: ['./account-basic-data.component.scss'],
})
export class AccountBasicDataComponent implements OnInit {
    public accountDataForm: FormGroup;
    public isSubmitted: boolean;
    public isLoading: boolean;
    public errorMessage: string;
    public isUpdatedSuccessfully: boolean;

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountService: IAccountsService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _formBuilder: FormBuilder
    ) {}

    ngOnInit() {
        const userData = this._authService.currentUser;
        this.isUpdatedSuccessfully = false;
        this.accountDataForm = this._formBuilder.group(
            {
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                username: ['', Validators.required],
                email: [userData.email, [Validators.required, Validators.email]],
                password: [''],
                repassword: [''],
            },
            {
                validator: MustMatch('password', 'repassword'),
            }
        );
        this.isSubmitted = false;
    }

    public get fields() {
        return this.accountDataForm.controls;
    }

    onSubmit() {
        this.isSubmitted = true;

        if (this.accountDataForm.invalid) {
            return;
        }

        this.isLoading = true;

        this._accountService
            .registerUser(this.accountDataForm.value)
            .pipe(first())
            .subscribe(
                (success) => {
                    this.isUpdatedSuccessfully = true;
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;
                    this.errorMessage = error.error;
                }
            );
    }
}
