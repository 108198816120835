import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Supplier } from 'dm-src/models/supplier';
import { SupplierAddress } from 'dm-src/models/supplier-address';
import { SuppilerAddressResponse } from 'dm-src/dtos/supplier-address-response';
import { GetSupplierData } from 'dm-src/dtos/get-supplier-data';
import { SupplierWorktime } from 'dm-src/models/supplier-worktime';
import { SchedulerEvent } from 'dm-src/models/scheduler-event';
import { CreateSupplierWorktimeResponse } from 'dm-src/dtos/create-supplier-worktime-response';
import { ISelectOption } from 'shared';
import { ResetSupplierAccount } from 'dm-src/dtos/reset-supplier-account';
import { InstantDeliveryWorktime } from '../../models/instant-delivery-worktime';
import { SupplierWorktimeActivity } from 'dm-src/models/supplier-worktime-activity';
import { SumUpUserData } from 'dm-src/models/sumup-user-data';
import { InstantDeliveryArea } from '../../models/instant-delivery-area';
import { GetSupplierRevolutData } from 'dm-src/dtos/get-supplier-revolut-data';
import { SupplierRevolutData } from 'dm-src/models/supplier-revolut-data';
import { SetSupplierRevolutBalance } from 'dm-src/dtos/set-supplier-revolut-balance';
export const SUPPLIERS_SERVICE_IMPL = new InjectionToken<ISuppliersService>(
    'suppliersServiceImpl'
);

export interface ISuppliersService {
    getSupplierData(supplierID: string): Observable<HttpResponse<GetSupplierData>>;
    getFilterOptions(): Observable<HttpResponse<ISelectOption[]>>;
    checkSupplierAddress(
        address: SupplierAddress
    ): Observable<HttpResponse<SuppilerAddressResponse>>;
    getSupplierWorktimes(supplierID: string): Observable<HttpResponse<SchedulerEvent[]>>;
    getInstantSupplierWorktime(
        supplierID: string
    ): Observable<HttpResponse<InstantDeliveryWorktime>>;
    getInstantDeliveryAreas(): Observable<HttpResponse<string[]>>;
    getAllAreas(): Observable<HttpResponse<InstantDeliveryArea[]>>;
    changeInstantDeliveryAreaStatus(
        id: string
    ): Observable<HttpResponse<InstantDeliveryArea[]>>;
    updateSupplier(supplier: Supplier): Observable<HttpResponse<void>>;
    createSupplierWorktime(
        supplierWorktime: SupplierWorktime
    ): Observable<HttpResponse<CreateSupplierWorktimeResponse>>;
    createInstantDeliveryWorktime(
        instantDeliveryWorktime: InstantDeliveryWorktime
    ): Observable<HttpResponse<InstantDeliveryWorktime>>;
    updateSupplierWorktime(
        supplierWorktime: SupplierWorktime
    ): Observable<HttpResponse<CreateSupplierWorktimeResponse>>;
    updateInstantSupplierWorktime(
        instantDeliveryWorktime: InstantDeliveryWorktime
    ): Observable<HttpResponse<InstantDeliveryWorktime>>;
    deleteSupplierWorktime(supplierWorktimeID: string): Observable<HttpResponse<void>>;
    resetSupplierAccount(
        requestBody: ResetSupplierAccount
    ): Observable<HttpResponse<void>>;
    deleteInstantSupplierWorktime(
        supplierWorktimeID: string
    ): Observable<HttpResponse<void>>;
    setWorkTimeActivity(
        supplierWorktimeActivity: SupplierWorktimeActivity
    ): Observable<HttpResponse<SupplierWorktimeActivity>>;
    setSumUpUserData(sumUpUserData: SumUpUserData): Observable<HttpResponse<void>>;
    getSupplierRevolutData(
        requestBody: GetSupplierRevolutData
    ): Observable<HttpResponse<SupplierRevolutData>>;
    setSupplierRevolutBalance(
        requestBody: SetSupplierRevolutBalance
    ): Observable<HttpResponse<void>>;
}
