<table>
    <tr *ngIf="!isMainPageCollecting">
        <td>{{ orderItem.orderItemName }}</td>
    </tr>
    <tr *ngIf="orderItem.orderReplacementItem === null">
        <td class="font-weight-bold">
            {{ 'collecting.quantity' | translate }}: {{ orderItem.orderedQuantity }}
            {{ 'collecting.' + orderItem.unit | translate }}
        </td>
    </tr>
    <tr *ngIf="orderItem.state === 1">
        <td class="font-weight-bold">
            {{ 'collecting.already-added-to-cart' | translate }}:
            {{ orderItem.quantity }}
            {{ 'collecting.' + orderItem.unit | translate }}
        </td>
    </tr>
    <tr
        *ngIf="
            orderItem.state === 1 &&
            orderItem.orderReplacementItem !== null &&
            orderItem.orderReplacementItem.quantityRequestedByUser !== null &&
            states.onlineConsultation
        "
    >
        <td class="font-weight-bold">
            {{ 'collecting.approved-by-customer' | translate }}:
            {{ orderItem.orderReplacementItem.quantityRequestedByUser ?? '-' }}
            {{ 'collecting.' + orderItem.unit | translate }}
        </td>
    </tr>
    <tr>
        <td>
            {{ orderItem.unitPrice }} {{ 'common.currency' | translate }} /
            {{ 'collecting.' + orderItem.unit | translate }}
        </td>
    </tr>
    <tr>
        <td *ngIf="orderItem.orderReplacementItem">
            {{ 'collecting.total' | translate }}:
            {{
                orderItem.orderReplacementItem?.unitPrice *
                    orderItem.orderReplacementItem?.collectedQuantity
            }}
            {{ 'common.currency' | translate }}
        </td>
        <td *ngIf="!orderItem.orderReplacementItem">
            {{ 'collecting.total' | translate }}:
            {{ orderItem.totalPrice }}
            {{ 'common.currency' | translate }}
        </td>
    </tr>
    <tr>
        <td>
            GTIN:
            {{ orderItem.gtinList[0] }}
        </td>
    </tr>

    <tr
        *ngFor="let orderItemDepositProduct of orderItem.orderItemDepositProductList"
        class="border my-1"
    >
        <td class="p-1">
            <strong
                >{{ 'collecting.optional-deposit-quantity-title' | translate }}:</strong
            ><br />
            {{ orderItemDepositProduct.depositUnitTypeName }}
            {{ orderItemDepositProduct.price | priceround }}
            {{ 'common.currency' | translate }}
            {{ orderItemDepositProduct.unitName }}
            <br />
            <div>
                <strong>{{ 'collecting.quantity' | translate }}</strong
                ><br />
                {{ orderItemDepositProduct.unitValue }}
                {{ 'collecting.piece(s)' | translate }}*
                {{ orderItemDepositProduct.price | priceround }}
                {{ 'common.currency' | translate }} =
                <strong>
                    {{
                        orderItemDepositProduct.unitValue * orderItemDepositProduct.price
                            | priceround
                    }}
                    {{ 'common.currency' | translate }}</strong
                >
            </div>

            <div *ngIf="orderItemDepositProduct.collectedQuantity">
                <strong>{{ 'collecting.already-added-to-cart' | translate }}</strong
                ><br />
                {{ orderItemDepositProduct.collectedQuantity }}
                {{ 'collecting.piece(s)' | translate }}*
                {{ orderItemDepositProduct.price | priceround }}
                {{ 'common.currency' | translate }} =
                <strong>
                    <u>
                        {{
                            orderItemDepositProduct.collectedQuantity *
                                orderItemDepositProduct.price | priceround
                        }}
                        {{ 'common.currency' | translate }}</u
                    ></strong
                >
            </div>
        </td>
    </tr>

    <ng-container
        *ngIf="
            orderItem.orderReplacementItem &&
            orderItem.orderReplacementItem.orderReplacementItemDepositProductList
        "
    >
        <tr
            *ngFor="
                let orderReplacementItemDepositProduct of orderItem.orderReplacementItem
                    .orderReplacementItemDepositProductList
            "
            class="border my-1"
        >
            <td class="p-1">
                <strong
                    >{{
                        'collecting.optional-deposit-quantity-title' | translate
                    }}:</strong
                ><br />
                {{ orderReplacementItemDepositProduct.depositUnitTypeName }}
                {{ orderReplacementItemDepositProduct.price | priceround }}
                {{ 'common.currency' | translate }}
                {{ orderReplacementItemDepositProduct.unitName }}
                <br />
                <div>
                    <strong>{{ 'collecting.quantity' | translate }}</strong
                    ><br />
                    {{ orderReplacementItemDepositProduct.unitValue }}
                    {{ 'collecting.piece(s)' | translate }}*
                    {{ orderReplacementItemDepositProduct.price | priceround }}
                    {{ 'common.currency' | translate }} =
                    <strong>
                        {{
                            orderReplacementItemDepositProduct.unitValue *
                                orderReplacementItemDepositProduct.price | priceround
                        }}
                        {{ 'common.currency' | translate }}</strong
                    >
                </div>

                <div *ngIf="orderReplacementItemDepositProduct.collectedQuantity">
                    <strong>{{ 'collecting.already-added-to-cart' | translate }}</strong
                    ><br />
                    {{ orderReplacementItemDepositProduct.collectedQuantity }}
                    {{ 'collecting.piece(s)' | translate }}*
                    {{ orderReplacementItemDepositProduct.price | priceround }}
                    {{ 'common.currency' | translate }} =
                    <strong>
                        <u>
                            {{
                                orderReplacementItemDepositProduct.collectedQuantity *
                                    orderReplacementItemDepositProduct.price | priceround
                            }}
                            {{ 'common.currency' | translate }}</u
                        ></strong
                    >
                </div>
            </td>
        </tr>
    </ng-container>
</table>
