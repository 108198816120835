import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-quick-info-content',
    templateUrl: './quick-info-content.component.html',
    styleUrls: ['./quick-info-content.component.scss'],
})
export class QuickInfoContentComponent implements OnInit {
    @Input() public data: any;

    constructor() {}

    ngOnInit(): void {}
}
