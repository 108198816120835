import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { faSearchPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import {
    ContainerPlacingTaskListItem,
    DeliveryTaskState,
    FinalizeOrderDto,
    IProviderShopBoxService,
    ITasksService,
    PROVIDER_SHOPBOX_SERVICE_IMPL,
    TASKS_SERVICE_IMPL,
} from 'shared';
import { FlashMessageService, ColorClass } from 'shared';
import { forkJoin } from 'rxjs';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { DeliveryType } from 'dm-src/types/delivery-type';

@Component({
    selector: 'app-order-box-reader',
    templateUrl: './order-box-reader.component.html',
    styleUrls: ['./order-box-reader.component.scss'],
})
export class OrderBoxReaderComponent implements OnInit {
    @Input() task: ContainerPlacingTaskListItem;

    isScannerVisible: boolean = false;
    isLoading = false;
    scannerType = null;

    boxTexts: string[] = [];
    slotTexts: string[] = [];

    faSearch = faSearchPlus;
    faTrash = faTrash;
    ColorClass: ColorClass;

    constructor(
        @Inject(TASKS_SERVICE_IMPL) private tasksService: ITasksService,
        @Inject(PROVIDER_SHOPBOX_SERVICE_IMPL)
        private service: IProviderShopBoxService,
        private messageService: FlashMessageService,
        public myTasksService: MyTasksService,
        private translateService: TranslateService,
        private nativeBarcodeReaderService: NativeBarcodeReaderService,
        private isWrappingService: IsWrappingService,
        private iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit(): void {
        if (window.ReactNativeWebView) {
            console.log('barcode reader subscribed on order box');
            this.iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'BARCODE_READING_START') {
                        console.log('barcode resutl on order box:', response);
                        this.onQRCodeFound(response?.result);
                    }
                });
        } else if (this.isWrappingService.isWrapping()) {
            this.nativeBarcodeReaderService.getBarcode().subscribe((response) => {
                if (response?.identifier === this.task.deliveryTaskID) {
                    this.onQRCodeFound(response.barcode);
                }
            });
        }
    }

    public openQRModal(scannerType: string): void {
        if (window.ReactNativeWebView) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader();
        } else if (this.isWrappingService.isWrapping()) {
            this.iosAndAndroidNatvieFunctionsService.openBarcodeReader(
                0,
                this.task.deliveryTaskID
            );
        } else {
            this.isScannerVisible = true;
        }
        this.scannerType = scannerType;
    }

    public onQRCodeFound(scannedCode: string): void {
        forkJoin([
            this.translateService.get('error.invalid-qr-code'),
            this.translateService.get('error.qr-not-found-in-db'),
        ]).subscribe(([title, text]) => {
            if (this.scannerType === 'box') {
                this.service.IsBoxValid(scannedCode).subscribe((valid) => {
                    if (valid) {
                        this.boxTexts.push(scannedCode);
                    } else {
                        this.messageService.showMessage(
                            title,
                            text,
                            10000,
                            ColorClass.Danger
                        );
                    }
                });
            } else if (this.scannerType === 'slot') {
                this.service.IsSlotValid(scannedCode).subscribe((valid) => {
                    if (valid) {
                        this.slotTexts.push(scannedCode);
                    } else {
                        this.messageService.showMessage(
                            title,
                            text,
                            10000,
                            ColorClass.Danger
                        );
                    }
                });
            }

            this.isScannerVisible = false;
            this.scannerType = null;
        });
    }

    public removeBox(boxText: string): void {
        this.boxTexts = this.boxTexts.filter((x) => x != boxText);
    }

    public removeSlot(slotText: string): void {
        this.slotTexts = this.slotTexts.filter((x) => x != slotText);
    }

    public finalizeOrder() {
        if (this.boxTexts.length < this.slotTexts.length) {
            forkJoin([
                this.translateService.get('error.common-error'),
                this.translateService.get('error.slot-box-number'),
            ]).subscribe(([title, text]) => {
                this.messageService.showMessage(title, text, 10000, ColorClass.Danger);
            });
            return;
        }

        const dto: FinalizeOrderDto = {
            orderId: this.task.orderID,
            boxTexts: this.boxTexts,
            slotText: this.slotTexts,
        };

        this.isLoading = true;
        this.service.finalizeOrder(dto).subscribe((resp) => {
            if (resp.status === 200) {
                this.tasksService
                    .setTaskState(this.task.deliveryTaskID, DeliveryTaskState.Done)
                    .subscribe((_) => {
                        if (this.task.deliveryTypeID === DeliveryType.Instant) {
                            this.isLoading = false;
                            this.myTasksService.removeTask(this.task.deliveryTaskID);
                            this.myTasksService.getNextTask();
                        } else {
                            this.tasksService
                                .createDeliveringTask(this.task.orderID)
                                .subscribe((_) => {
                                    this.isLoading = false;
                                    this.myTasksService.removeTask(
                                        this.task.deliveryTaskID
                                    );
                                    this.myTasksService.getNextTask();
                                });
                        }
                    });
            }
        });
    }
}
