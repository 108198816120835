<div class="card-footer border border-primary bg-dark" *ngIf="hasWishListOrders">
    <h5 class="card-title">
        {{ 'my-tasks.wish-lists' | translate }}
    </h5>

    <div class="text-left font-italic pb-2">
        {{ 'my-tasks.wish-lists-information' | translate }}
    </div>

    <div class="btn-group btn-block" *ngFor="let wishListOrder of wishListOrders">
        <button
            type="button"
            class="btn btn-danger"
            [disabled]="isCompletedWishListOrder(wishListOrder)"
            (click)="showWishListOrderModal(wishListOrder)"
        >
            {{ wishListOrder.wishListProviderShop.wishListProviderShopName }}

            <span *ngIf="wishListOrder.isCompletedWishListOrder">
                ({{ wishListOrder.totalPrice }} {{ 'common.currency' | translate }})
            </span>
        </button>
    </div>
</div>

<lib-modal
    [title]="'my-tasks.update-wish-list-order' | translate"
    [componentID]="'update-wish-list-order-modal' + orderCode"
    [bgColorClass]="ColorClass.Info"
    [hasCloseIcon]="false"
>
    <ng-container>
        <div
            class="wish-list-order-info border border-info rounded ng-container pl-1 mb-3"
        >
            <div class="row pb-2">
                <div class="col-4 font-weight-bold">
                    {{ 'common.store' | translate }}:
                </div>
                <div class="col-8">
                    {{ wishListProviderShopName }}
                </div>
            </div>

            <div class="row">
                <div class="col-4 font-weight-bold">
                    {{ 'common.wish-list-provider-shop-address' | translate }}:
                </div>
                <div class="col-8">
                    <a
                        href="http://maps.google.com/maps?q={{
                            wishListProviderShopAddress
                        }}"
                        class="text-black"
                        target="_blank"
                    >
                        {{ wishListProviderShopAddress }}
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-4 font-weight-bold">
                    {{ 'common.phone-number' | translate }}:
                </div>
                <div class="col-8">
                    <a href="tel:{{ customerPhone }}">
                        {{ customerPhone }}
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-4 font-weight-bold">
                    {{ 'common.wish-list-products' | translate }}:
                </div>
                <div class="col-8"></div>
            </div>

            <div class="row">
                <div class="col-4"></div>

                <div class="col-8">
                    <ul class="pl-3 ml-0">
                        <li *ngFor="let wishListProductItem of wishListProductItems">
                            {{ wishListProductItem.productName }} -
                            {{ wishListProductItem.quantityText }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div *ngIf="!isPhotoInvalid" class="photo-thumbnail-container ml-auto mr-auto">
            <img
                class="img-thumbnail d-block mr-auto ml-auto"
                [src]="imgResultAfterCompression"
            />
        </div>
        <div class="file-input" *ngIf="!photoLoaded">
            <input
                type="file"
                accept="image/*"
                capture="environment"
                id="photo-input"
                onclick="this.value = null"
                (change)="onPhotoInputChanged($event)"
                hidden
            />
            <label
                class="photo-btn btn btn-block btn-info"
                for="photo-input"
                (click)="handleTakeAPhoto()"
            >
                <i class="fa fa-camera"></i> {{ 'collecting.photo-upload' | translate }}
            </label>
            <div *ngIf="isPhotoInvalid" class="text-danger text-center">
                <div>
                    {{ 'collecting.photo-upload' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>
        <hr />
        <div class="w-100 mt-4 mb-4">
            <input
                type="number"
                id="totalPrice"
                [(ngModel)]="totalPrice"
                class="form-control"
                autofocus
            />
            <div *ngIf="isTotalPriceInvalid" class="text-danger text-center">
                <div>
                    {{ 'my-tasks.invalid-wish-list-order-total-price' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>

        <div class="text-center w-100">
            <button
                class="btn btn-success w-75 mb-4"
                *ngIf="!isSaveBlocked"
                (click)="updateWishListOrder()"
            >
                {{ 'common.save' | translate }}
            </button>
            <button
                class="btn btn-secondary w-75"
                (click)="
                    modalService.setModalVisibility(
                        false,
                        'update-wish-list-order-modal' + orderCode
                    );
                    this.resetAll()
                "
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </ng-container>
</lib-modal>
