import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSizeDropdownComponent } from './page-size-dropdown/page-size-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InteractiveTableHeaderComponent } from './interactive-table-header/interactive-table-header.component';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { TranslateModule } from '@ngx-translate/core';
import { LibCommonModule } from '../lib-common/lib-common.module';
import { VisibleColumnDropdownComponent } from './visible-column-dropdown/visible-column-dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

export const CustomSelectOptions: INgxSelectOptions = {
    keepSelectedItems: false,
    allowClear: true,
    optionValueField: 'id',
    optionTextField: 'label',
};

@NgModule({
    declarations: [
        PageSizeDropdownComponent,
        InteractiveTableHeaderComponent,
        VisibleColumnDropdownComponent,
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSelectModule.forRoot(CustomSelectOptions),
        TranslateModule,
        LibCommonModule,
        MatSelectModule,
        MatFormFieldModule,
        MatButtonModule,
        NgxMatSelectSearchModule,
    ],
    exports: [
        InteractiveTableHeaderComponent,
        PageSizeDropdownComponent,
        VisibleColumnDropdownComponent,
    ],
})
export class ListModule {}
