import { PaymentState } from 'shared';

export class ProviderOrder {
    public orderID: Number;
    public orderCode: string;
    public orderState: string;
    public providerShop: string;
    public created?: Date;
    public deliveryDate?: Date;
    public totalPrice: Number;
    public deliveryFee: Number;
    public comment: string;
    public ordererName: string;
    public billPhotoFilename: string;
    public isOrderConfirmationEmailSent: boolean;
    public paymentState: PaymentState;
}
