import { OrderItemState } from '../types/order-item-state';
import { OrderReplacementItem } from './order-replacement-item';
import { OrderReturnItem } from './order-return-item';
import { BaseOrderItem } from './base-order-item';
import { AppSettings } from '../../appsettings';
import { OrderItemDepositProduct } from './order-item-deposit-product';
import { OrderReplacementItemDepositProduct } from './order-replacement-item-deposit-product';

export class OrderItem extends BaseOrderItem {
    private readonly _readyStates: OrderItemState[] = [
        OrderItemState.Collected,
        OrderItemState.Approved,
    ];
    public orderItemID: number;
    public productProviderID: number;
    public orderReplacementItem: OrderReplacementItem;
    public orderReturnItemList: OrderReturnItem[];
    public orderItemDepositProductList: OrderItemDepositProduct[];
    public orderReplacementItemDepositProductList: OrderReplacementItemDepositProduct[];
    public optionalDepositProductQuantity: number;
    public orderItemName: string;
    public orderedQuantity = 0;
    public state: OrderItemState;
    public isVisible = false;
    public isIdentifiedAtInspection = false;
    public removedReasonComment: string;
    public removedReasonImageUrl: string;
    public orderRemovedItemID?: string;
    public originalItemName: string;
    public get isReady(): boolean {
        return this._readyStates.includes(this.state);
    }
    public setImageSrcs(): void {
        if (this.productPhotoID !== null) {
            this.itemThumbnailSrc = `${AppSettings.get('mediaServiceUrl')}/thumbnail/${
                this.productPhotoID
            }`;
            this.itemImageSrc = `${AppSettings.get('mediaServiceUrl')}/large/${
                this.productPhotoID
            }`;
        } else if (this.providerProductImageURL !== null) {
            this.itemThumbnailSrc = this.providerProductImageURL;
            this.itemImageSrc = this.providerProductImageURL;
        }
    }
}
