import { Component, Input, OnInit } from '@angular/core';
import { SupplierResourceUser } from 'dm-src/types/supplier-resource-user';
import { SupplierResourcesService } from 'dm-src/app/modules/supplier-resources/supplier-resources.service';

@Component({
    selector: 'app-resource-entry-editor',
    templateUrl: './resource-entry-editor.component.html',
    styleUrls: ['./resource-entry-editor.component.scss'],
})
export class ResourceEntryEditorComponent implements OnInit {
    @Input() public resourceSuppliers: SupplierResourceUser[];

    constructor(public supplierResourcesService: SupplierResourcesService) {}

    ngOnInit(): void {}
}
