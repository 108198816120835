import { OrderReturnItemDto } from './order-return-item-dto';

export class StartOrderReturn {
    public orderID: number;
    public orderReturnItemDtoList: OrderReturnItemDto[];
    public comment: string;
    public providerShopID?: number;
    public orderReturnImageContent?: string;
    public orderReturnImageExtension?: string;
}
