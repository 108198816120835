import { InjectionToken } from '@angular/core';

export const IMAGE_COMPRESSION_SERVICE_IMPL =
    new InjectionToken<IImageCompressionService>('imageCompressionServiceImpl');

export interface IImageCompressionService {
    compressImage(
        image: any,
        originalResolution: boolean,
        quality: number
    ): Promise<string>;
}
