import { Component, Input, OnInit } from '@angular/core';
import { ColorClass, DeliveryTaskType } from 'shared';

@Component({
    selector: 'app-task-type-badge',
    templateUrl: './task-type-badge.component.html',
    styleUrls: ['./task-type-badge.component.scss'],
})
export class TaskTypeBadgeComponent implements OnInit {
    @Input() type: string;

    public label: string;
    public classes = 'badge ';

    ngOnInit(): void {
        let colorClass: ColorClass;
        switch (this.type) {
            case DeliveryTaskType[DeliveryTaskType.Collecting]:
                colorClass = ColorClass.Info;
                break;
            case DeliveryTaskType[DeliveryTaskType.Delivering]:
                colorClass = ColorClass.Success;
                break;
            case DeliveryTaskType[DeliveryTaskType.Invoicing]:
                colorClass = ColorClass.Primary;
                break;
            case DeliveryTaskType[DeliveryTaskType.ItemsRefunding]:
                colorClass = ColorClass.Dark;
                break;
            case DeliveryTaskType[DeliveryTaskType.ItemsReturning]:
                colorClass = ColorClass.Secondary;
                break;
            case DeliveryTaskType[DeliveryTaskType.Inspecting]:
                colorClass = ColorClass.Danger;
                break;
            case DeliveryTaskType[DeliveryTaskType.ContainerPlacing]:
                colorClass = ColorClass.Warning;
                break;
        }
        this.classes += 'badge-' + colorClass;
        this.label = this.type;
    }
}
