<div class="card bg-success text-white">
    <div class="card-header">Support comments</div>
    <div class="card-body order-details">
        <form [formGroup]="supportCommentForm" *ngIf="isEditMode; else notesTemplate">
            <div class="form-group row">
                <div class="col-12">
                    <div class="input-group is-invalid">
                        <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter some notes"
                            formControlName="notes"
                        >
                        </textarea>
                    </div>
                    <p class="text-right m-0 p-0">
                        {{ supportCommentForm.controls['notes']?.value?.length ?? 0 }}/40
                    </p>
                    <div
                        class="invalid-feedback"
                        *ngIf="supportCommentForm.controls['notes']?.value?.length > 40"
                    >
                        The maximum length of support comments is 40 characters!
                    </div>
                    <div
                        *ngIf="isSubmitted && fields.notes.errors"
                        class="invalid-feedback"
                    >
                        <div *ngIf="fields.notes.errors.required">
                            Support comment is required!
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <ng-template #notesTemplate>
            {{ notes }}
        </ng-template>
    </div>
    <div class="card-footer bg-success">
        <div class="btn-group" role="group">
            <button
                *ngIf="!isEditMode"
                type="button"
                class="btn btn-light"
                (click)="isEditMode = !isEditMode"
            >
                Edit
            </button>
            <button
                *ngIf="isEditMode"
                [disabled]="supportCommentForm.controls['notes']?.value?.length > 40"
                type="button"
                class="btn btn-light"
                (click)="updateCustomerNotes()"
            >
                Save
            </button>
            <button
                *ngIf="isEditMode"
                type="button"
                class="btn btn-danger"
                (click)="isEditMode = !isEditMode"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
