export enum ActionType {
    // Fontos, hogy itt a sorrend megegyezzen a backend oldali ActionType enummal.

    // OrderStateChanged

    OrderCreated,
    DeliveryTaskStateChanged,
    DeliveryTaskUserChanged,
    CustomerContactDataChanged,
    EstimatedTimeRecalculated,
    DeliveryTimeSlotChanged,
    MarkupStockCorrectionProcessStarted,
    MarkupCollectingInvoiceAndStockCorrectionProcessStarted,
    MarkupCollectingInvoiceJplGenerated,
    DeliveryInvoiceProcessStarted,
    CustomerCommentsChanged,
    ContainerPlacingTaskCreated,
    DeliveryTaskCreated,
    ReturnProcessStarted,
    DeliveryFeeChanged,
    RefundPaymentStarted,
    OrderCanceled,
    CollectingCheckoutProcessStarted,
    CustomerPhoneCallStarted,
    CollectingDone,

    // DM SupplierRegions.

    SupplierRegionNew,
    SupplierRegionRemove,
    SupplierRegionLatLongChanged,
    SupplierRegionNewZip,
    SupplierRegionRemoveZip,
    UserRolesChanged,
    AccountRegistration,
    AccountDeactivate,
    AccountUsernameChanged,
    ClientChangeProfile,
    ClientChangePassword,

    // DM Supplier form.

    SupplierCreated,
    SupplierUpdated,
    SupplierAccountReset,
    SupplierSumUpAccountUpdated,

    // DM Supplier worktime.

    SupplierWorktimeCreated,
    SupplierWorktimeUpdated,
    SupplierWorktimeDeleted,

    // DM Supplier resource.

    SupplierResourceCreated,
    SupplierResourceUpdated,
    SupplierResourceDeleted,

    // DM OrderItemStateChanged

    MyTasksCollectingStarted,
    MyTasksContinueCollectingButtonClicked,
    SearchOfflineProductForOrderItemByGTINButtonClicked,
    SearchOfflineProductForOrderItemBySKUButtonClicked,
    GetOfflineProductByGTIN,
    GetOfflineProductsBySKU,
    AddOrderItemToBasketAsNewProduct,
    AddOrderItemToBasket,
    AddOrderItemToBasketWithQuantity,
    MissingOrderItemButtonClicked,
    MissingOrderItemReasonChanged,
    MissingOrderItemReasonImageChanged,
    ReplaceOrderItemButtonClicked,
    CollectedOrderItemCancelButtonClicked,
    ReplacedOrderItemCancelButtonClicked,
    NotFoundOrderItemCancelButtonClicked,
    ReplacedOrderItemNotFoundButtonClicked,
    ReplaceOrderItemReasonChanged,
    ReplaceOrderItemReasonImageChanged,
    ReplacedOrderItemIdentifyingStarted,
    SearchOfflineProductForReplacedOrderItemByGTINButtonClicked,
    SearchOfflineProductForReplacedOrderItemBySKUButtonClicked,
    AddReplacedOrderItemToBasket,
    RequestApprovalFromTheCustomer,
    MarkupCheckoutButtonClicked,
    UpdateSupportComment,

    // PM OrderStateChanged

    PMCheckoutReadQRCodeButtonClicked,
    PMCheckoutQRCodeFound,
    PMInspectionReadQRCodeButtonClicked,
    PMInspectionQRCodeFound,
    PMInspectionRequestButtonClicked,
    PMShowOrderItemsButtonClicked,
    PMApproveOrderButtonClicked,
    PMInspectSelectedOrderItem,
    PMInspectSelectedReplacedOrderItem,
    PMApproveOrderItemButtonClicked,
    PMDeclineOrderItemButtonClicked,
    PMApproveReplacedOrderItemButtonClicked,
    PMDeclineReplacedOrderItemButtonClicked,
    PMInspectingApprovedButtonClicked,
    PMInspectingDeclinedButtonClicked,
}
