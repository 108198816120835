import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SupplierAddress } from 'dm-src/models/supplier-address';
import { TranslateService } from '@ngx-translate/core';
import {
    StreetType,
    IAccountsService,
    ACCOUNTS_SERVICE_IMPL,
    FlashMessageService,
} from 'shared';
import { SumUpUserData } from 'dm-src/models/sumup-user-data';
import { Router } from '@angular/router';
import { ColorClass } from 'shared';

@Injectable({
    providedIn: 'root',
})
export class AccountSupplierDataService {
    private _isAddressApprovalModalVisible: BehaviorSubject<boolean>;
    private _locatedAddressLine: BehaviorSubject<string>;
    public selectedSupplierID: BehaviorSubject<string>;
    public isAddressLocated: boolean;
    public isPartialMatch: boolean;

    get isAddressApprovalModalVisible() {
        return this._isAddressApprovalModalVisible.asObservable();
    }

    get locatedAddressLine() {
        return this._locatedAddressLine.asObservable();
    }

    get currentSupplierID(): Observable<string> {
        return this.selectedSupplierID.asObservable();
    }

    constructor(
        @Inject(ACCOUNTS_SERVICE_IMPL) private _accountsService: IAccountsService,
        private _router: Router,
        private _translateService: TranslateService,
        private _flashMessageService: FlashMessageService
    ) {
        this._isAddressApprovalModalVisible = new BehaviorSubject<boolean>(false);
        this._locatedAddressLine = new BehaviorSubject<string>(null);
        this.selectedSupplierID = new BehaviorSubject<string>(null);
    }

    public setLocatedAddressLine(address: SupplierAddress) {
        const locatedAddressLine = `${address.postalCode}
            ${address.cityName},
            ${address.streetName}
            ${this._translateService.instant(
                'street-type.' + StreetType[address.streetType].toLowerCase()
            )}
            ${address.houseNumber}`;
        this._locatedAddressLine.next(locatedAddressLine);
    }

    public setAddressApprovalModalVisible(isVisible: boolean) {
        this._isAddressApprovalModalVisible.next(isVisible);
    }

    public setSelectedSupplierID(supplierID: string) {
        this.selectedSupplierID.next(supplierID);
    }

    public inactivateMyUser(supplierID: string) {
        this._accountsService.inactivateMyUser(supplierID).subscribe((res) => {
            this._flashMessageService.showMessage(
                null,
                'users.account-removed-message',
                3000,
                ColorClass.Success
            );
            this._router.navigate(['logout']);
        });
    }
}
