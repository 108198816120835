import { Component, Input, OnInit } from '@angular/core';
import { ColorClass } from 'shared';
import { PaymentState } from 'shared';

@Component({
    selector: 'app-payment-state-badge',
    templateUrl: './payment-state-badge.component.html',
    styleUrls: ['./payment-state-badge.component.scss'],
})
export class PaymentStateBadgeComponent implements OnInit {
    @Input() state: PaymentState;
    public colorClass: ColorClass;
    public PaymentState = PaymentState;

    ngOnInit() {
        switch (this.state) {
            case PaymentState.Succeeded:
                this.colorClass = ColorClass.Success;
                break;
            case PaymentState.Failed:
            case PaymentState.Canceled:
            case PaymentState.Expired:
                this.colorClass = ColorClass.Danger;
                break;
            case PaymentState.Reserved:
                this.colorClass = ColorClass.Primary;
                break;
            case PaymentState.Refunded:
                this.colorClass = ColorClass.Info;
                break;
            case PaymentState.NotStarted:
                this.colorClass = ColorClass.Warning;
                break;
        }
    }
}
