import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-quick-info-header',
    templateUrl: './quick-info-header.component.html',
    styleUrls: ['./quick-info-header.component.scss'],
})
export class QuickInfoHeaderComponent {
    @Input() public data: any;
    @Output() public onClose = new EventEmitter<void>();

    public close(): void {
        this.onClose.emit();
    }
}
