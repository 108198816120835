import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-native-barcode-reader-input',
    templateUrl: './native-barcode-reader-input.component.html',
    styleUrls: ['./native-barcode-reader-input.component.css'],
})
export class NativeBarcodeReaderInputComponent implements OnInit {
    public barcode: string = '';

    constructor() {}

    ngOnInit(): void {}
}
