import { Component, Input, OnInit } from '@angular/core';
import { BooleanIconComponent } from './boolean-icon.component';

@Component({
    selector: 'lib-ion-boolean-icon',
    templateUrl: './ion-boolean-icon.component.html',
})
export class IonBooleanIconComponent extends BooleanIconComponent {
    @Input() public value = true;
    @Input() public slot = 'start';
}
