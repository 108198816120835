export enum UserRole {
    Administrator,
    User,
    Deliverer,
    Picker,
    ProviderAdmin,
    Supporter,
    InstantDeliverer,
    ProviderShopUser,
    AreaManager,
    Translator,
    ITSupport,
    LogBrowser,
    MobileTester,
    SupplierSupervisor,
    Refunder,
}
