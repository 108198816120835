<div class="card text-white bg-purple" *ngIf="isVisible">
    <div class="card-header" (click)="isCollapsed = !isCollapsed">
        <span>
            <strong>{{ 'my-tasks.order-container-placement' | translate }}</strong>
            -
        </span>
        <span>{{ task.orderCode }}</span
        >,
        <span
            ><strong>{{ task.providerName }}</strong></span
        >
    </div>
    <div class="card-body text-center" [collapse]="isCollapsed" [isAnimated]="true">
        <table class="table table-striped table-responsive table-borderless text-white">
            <tbody>
                <tr>
                    <th scope="row">{{ 'my-tasks.customer-zip-code' | translate }}:</th>
                    <td>
                        {{ task.customerZipCode }}
                    </td>
                </tr>

                <!-- TODO: Estimated Delivery Time (https://weshop.atlassian.net/browse/SP-3994) -->

                <tr>
                    <th scope="row">{{ 'my-tasks.delivery-deadline' | translate }}:</th>
                    <td>
                        <span>{{ task.deliveryDeadline | amDateFormat : 'HH:mm' }}</span
                        ><span *ngIf="task.deliveryTimeSlotEnd != null">
                            -
                            {{
                                task.deliveryDeadline
                                    | amAdd : task.deliveryTimeSlotEnd : 'minutes'
                                    | amDateFormat : 'HH:mm'
                            }}
                        </span>
                    </td>
                </tr>

                <!-- TODO END -->

                <tr>
                    <th scope="row">{{ 'my-tasks.bag' | translate }}:</th>
                    <td *ngIf="task.usePaperBag; else multiUseBag">
                        {{ 'my-tasks.paper-bag' | translate }}
                    </td>
                    <ng-template #multiUseBag>
                        <td *ngIf="task.usePaperBag === false">
                            {{ 'my-tasks.multiuse-bag' | translate }}
                        </td>
                    </ng-template>
                </tr>
                <tr>
                    <th scope="row">{{ 'common.notes' | translate }}:</th>
                    <td>
                        {{ task.notes }}
                    </td>
                </tr>
            </tbody>
        </table>
        <ng-container>
            <app-order-box-reader [task]="task"></app-order-box-reader>
        </ng-container>
    </div>
</div>
