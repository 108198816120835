<div class="container">
    <h1>Útvonaltervezés</h1>
    <br />

    <ngb-accordion #acc="ngbAccordion" animation="true">
        <ngb-panel>
            <ng-template ngbPanelTitle>
                <h5 class="text-black-50">
                    Paraméterek beállítása <i class="fa fa-angle-down"></i>
                </h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <form [formGroup]="parameterForm" (ngSubmit)="startTourPlanning()">
                    <div class="row">
                        <div class="col-4 p-3">
                            <p>Régió kiválasztása:</p>

                            <select
                                class="form-control"
                                formControlName="supplierRegionID"
                                id="orders"
                                (change)="getSupplierRegionTourPlannerParameters()"
                            >
                                >
                                <option
                                    *ngFor="
                                        let supplierRegion of supplierRegions;
                                        let i = index
                                    "
                                    [value]="supplierRegions[i].supplierRegionID"
                                >
                                    {{ supplierRegions[i].regionName }}
                                </option>
                            </select>
                        </div>

                        <div class="col-4 p-3">
                            <p>Referenciadátum:</p>
                            <input
                                class="form-control"
                                type="datetime-local"
                                formControlName="referenceDate"
                                (change)="referenceDate = $event.target.value"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Tervezés automatikusan történik:</p>
                            <input formControlName="isAutomaticPlanned" type="checkbox" />
                        </div>
                        <div class="col-4 p-3">
                            <p>
                                Ennyi órával a legkorábbi shop nyitása előtt kezdődik az
                                első útvonaltervezés:
                            </p>
                            <input
                                class="form-control"
                                formControlName="planBeforeStoreOpening"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Ennyi óránként legyen útvonal újratervezés:</p>
                            <input
                                class="form-control"
                                formControlName="planPeriod"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Ennyi perccel előbb ér véget egy session:</p>
                            <input
                                class="form-control"
                                formControlName="periodEndEarlier"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>
                                Ennyi perc a pickup időtartama picker + deliverer
                                providershop esetén:
                            </p>
                            <input
                                class="form-control"
                                formControlName="pickupTimeForDelivery"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Műszak elejéből ennyi percet vonunk le:</p>
                            <input
                                class="form-control"
                                formControlName="shiftStartMinusMinutes"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Műszak végéhez ennyi percet adunk hozzá:</p>
                            <input
                                class="form-control"
                                formControlName="shiftEndAdditionalMinutes"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Műszakban lévő szünet ennyi perc múlva ér véget:</p>
                            <input
                                class="form-control"
                                formControlName="shiftBreakAdditionalMinutes"
                                type="number"
                            />
                        </div>

                        <div class="col-4 p-3">
                            <p>Ennyi időt tölt el a címen a futár (deliveryduration):</p>
                            <input
                                class="form-control"
                                formControlName="deliveryDurationOnAddress"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Picking idősáv szorzója:</p>
                            <input
                                class="form-control"
                                formControlName="pickingDurationFactor"
                                type="text"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Ennyi perccel előbb kezdheti el a pickinget:</p>
                            <input
                                class="form-control"
                                formControlName="pickingStartMinusMinutes"
                                type="text"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>PickingTime-ot küldjünk?</p>
                            <input formControlName="isPickingTime" type="checkbox" />
                        </div>
                        <div class="col-4 p-3">
                            <p>KM-Benzindíj:</p>
                            <input
                                class="form-control"
                                formControlName="distanceCost"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Órabér:</p>
                            <input
                                class="form-control"
                                formControlName="timeCost"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Alapdíj:</p>
                            <input
                                class="form-control"
                                formControlName="fixedCost"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Forgalom beállítása (traffic):</p>
                            <input
                                class="form-control"
                                formControlName="trafficConfiguration"
                                type="text"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Configuration Reduce:</p>
                            <input
                                formControlName="waitingTimeReduceConfiguration"
                                type="checkbox"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Buffertime (perc):</p>
                            <input
                                class="form-control"
                                formControlName="waitingTimeBufferTimeConfiguration"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Kapacitás:</p>
                            <input
                                class="form-control"
                                formControlName="capacity"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Aszinkron endpoint:</p>
                            <input formControlName="isAsync" type="checkbox" />
                        </div>
                        <div class="col-4 p-3">
                            <p>Csak Reserved státuszú paymentek:</p>
                            <input
                                formControlName="isJustReservedPayments"
                                type="checkbox"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <p>Ennyi orderre számolunk (db):</p>
                            <input
                                class="form-control"
                                formControlName="orderNumber"
                                type="number"
                            />
                        </div>
                        <div class="col-4 p-3">
                            <button
                                type="button"
                                (click)="saveSupplierRegionTourPlannerParameters()"
                                class="btn btn-info w-100"
                            >
                                Útvonalterv paraméterek mentése
                            </button>
                            <br />
                            <br />
                            <button
                                type="button"
                                (click)="getSavedDailyPlan()"
                                class="btn btn-info w-100"
                            >
                                Mentett útvonalterv megtekintése
                            </button>
                            <br />
                            <br />
                            <button type="submit" class="btn btn-success w-100">
                                HERE.com útvonal tervezés
                            </button>
                        </div>
                    </div>
                </form>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <hr />

    <div *ngIf="isLoading" class="w-100 p-6 text-center">
        <i class="fa fa-spinner fa-spin loading-icon-custom" aria-hidden="true"></i>
    </div>
    <div *ngIf="savedTourPlannerDailyPlan" class="w-100">
        <h2>Tervezett útvonalak:</h2>
        <i>(Kattinthatóak ...)</i>
        <br />
        <div id="map"></div>
    </div>
    <br />
    <div *ngIf="savedTourPlannerDailyPlan" class="w-100">
        <h2>
            Futár útiterv táblázatok: &nbsp;&nbsp;&nbsp;<button
                class="btn btn-outline-danger"
                [ngClass]="justCorrigationNeededOrders ? 'btn-danger' : ''"
                (click)="switchCorrigationFilter()"
            >
                Korrigálandó orderek szűrése
            </button>
        </h2>

        <br />
        <ng-container *ngFor="let tour of savedTourPlannerDailyPlan.tourPlannerTourList">
            <div
                class="deliverer-tables w-100 col-12"
                *ngIf="
                    ((selectedDelivererID && selectedDelivererID == tour.supplierID) ||
                        !selectedDelivererID) &&
                    hasCorrigationNeededInStopList(tour.tourPlannerStopList)
                "
            >
                <h5>
                    <strong>
                        Futár azonosító: <strong>{{ tour.delivererName }}</strong>
                    </strong>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        class="btn btn-outline-info"
                        [ngClass]="
                            selectedDelivererID && selectedDelivererID == tour.supplierID
                                ? 'btn-success'
                                : ''
                        "
                        (click)="filterDelivererOnMap(tour.supplierID)"
                    >
                        Szűkítés<i class="fa fa-search"></i>
                    </button>
                </h5>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Koordináták</th>
                            <th>Érkezés</th>
                            <th>Távozás</th>
                            <th colspan="2">Feladat(ok)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let stop of tour.tourPlannerStopList">
                            <tr
                                *ngIf="hasCorrigationNeeded(stop.tourPlannerActivityList)"
                            >
                                <td>{{ stop.lat }}, {{ stop.lng }}</td>
                                <td>{{ getReadableDateTime(stop.arrival) }}</td>
                                <td>{{ getReadableDateTime(stop.departure) }}</td>
                                <td colspan="2">
                                    <ng-container
                                        *ngFor="
                                            let activity of stop.tourPlannerActivityList
                                        "
                                    >
                                        <div
                                            class="card p-4"
                                            style="display: block"
                                            [ngClass]="
                                                activity.isCorrigationNeeed
                                                    ? 'border-danger '
                                                    : ''
                                            "
                                        >
                                            <strong>Rendelés azonosítója: </strong
                                            ><span>#{{ activity.orderCode }}</span>
                                            <br />
                                            <strong>Feladat: </strong
                                            ><span>{{ activity.type }}</span
                                            ><br />
                                            <strong>Start: </strong>
                                            <span>{{
                                                getReadableDateTime(activity.start)
                                            }}</span
                                            ><br />
                                            <strong>End: </strong
                                            ><span>{{
                                                getReadableDateTime(activity.end)
                                            }}</span
                                            ><br />
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <br />
                <br />
            </div>
        </ng-container>

        <hr />
        <h2>Kimaradt feladatok:</h2>
        <br />

        <div class="deliverer-tables w-100 col-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Megrendelés azonosító</th>
                        <th>JobID</th>
                        <th>Hibakód</th>
                        <th>Hiba leírás</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
                            let unassigned of savedTourPlannerDailyPlan.tourPlannerUnassignedList
                        "
                    >
                        <td>#{{ unassigned.orderCode }}</td>
                        <td>{{ unassigned.orderID }}</td>
                        <td>{{ unassigned.reasonCode }}</td>
                        <td>{{ unassigned.reasonDescription }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <br />

            <button class="btn btn-info w-100" (click)="corrigatesavedTourPlan()">
                Korrigálás 3 órás sávokra <i class="fa fa-refresh"></i>
            </button>
            <br />
            <i>
                <i class="fa fa-info"></i> (A jelenleg beállított konfigurációval futtatja
                újra)
            </i>
            <br />
            <button class="btn btn-success w-100" (click)="acceptPlanning()">
                KIOSZTOM a megadott paraméterekkel <i class="fa fa-adjust"></i>
            </button>
            <br /><br /><br />
        </div>
    </div>
</div>
