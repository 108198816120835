<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    {{ 'navigation.supplier-regions' | translate }}
                </div>
                <div class="card-body">
                    <form class="form-horizontal">
                        <div class="form-group row">
                            <div class="col-md-6 col-sm-12">
                                <label for="supplier-regions" class="control-label">
                                    {{ 'navigation.supplier-regions' | translate }}
                                </label>
                                <angular2-multiselect
                                    id="supplier-regions"
                                    name="supplierRegions"
                                    [(ngModel)]="regionsModel"
                                    [data]="supplierRegions"
                                    (onAddFilterNewItem)="onRegionCreated($event)"
                                    [settings]="regionsSettings"
                                    (onSelect)="onRegionSelect($event)"
                                >
                                </angular2-multiselect>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <label for="assignedZipCodes" class="control-label">
                                    {{ 'supplier-regions.zip-codes' | translate }}
                                </label>
                                <angular2-multiselect
                                    id="assignedZipCodes"
                                    name="assignedZipCodes"
                                    [data]="availableZipCodes"
                                    [settings]="zipCodesSettings"
                                    [(ngModel)]="zipCodesModel"
                                    (onSelect)="onZipCodesSelect($event)"
                                    (onDeSelect)="onZipCodeUnassignment($event)"
                                >
                                </angular2-multiselect>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="card">
                                    <div class="card-header">
                                        {{
                                            'supplier-regions.assigned-zip-codes'
                                                | translate
                                        }}
                                    </div>
                                    <div class="card-body">
                                        <app-zip-badge
                                            *ngFor="
                                                let zipCode of supplierRegionsService.selectedZipCodes
                                                    | async
                                            "
                                            [zipCode]="zipCode"
                                            (onDelete)="onZipCodeUnassignment($event)"
                                        ></app-zip-badge>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <div class="card">
                                    <div class="card-header">
                                        {{
                                            'supplier-regions.position-title' | translate
                                        }}
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    [(ngModel)]="_lat"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    id="lat"
                                                    placeholder="{{
                                                        'common.lat' | translate
                                                    }}"
                                                    (change)="onChangeLatLon()"
                                                />
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    [(ngModel)]="_long"
                                                    [ngModelOptions]="{
                                                        standalone: true
                                                    }"
                                                    id="long"
                                                    (change)="onChangeLatLon()"
                                                    placeholder="{{
                                                        'common.long' | translate
                                                    }}"
                                                />
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <a
                                                target="_blank"
                                                href="https://www.openstreetmap.org/#map=14/{{
                                                    _lat
                                                }}/{{ _long }}"
                                                >{{ 'common.goto-map' | translate }}</a
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="btn-toolbar justify-content-between">
                        <div class="btn-group btn-group-sm">
                            <lib-btn-progress
                                (onClick)="onRegionsSave()"
                                [btnClass]="'btn btn-success'"
                                [iconClass]="'fa fa-check'"
                                [inProgress]="isSaveInProgress"
                                [disabled]="isDeleteInProgress"
                            >
                                {{ 'supplier-regions.save-regions' | translate }}
                            </lib-btn-progress>
                            <lib-btn-progress
                                (onClick)="
                                    modalService.setModalVisibility(
                                        true,
                                        'delete-region-confirmation'
                                    )
                                "
                                [btnClass]="'btn btn-warning'"
                                [iconClass]="'fa fa-trash'"
                                [disabled]="!isSelectedRegionSaved || isSaveInProgress"
                                [inProgress]="isDeleteInProgress"
                            >
                                {{
                                    'supplier-regions.delete-selected-region' | translate
                                }}
                            </lib-btn-progress>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<lib-modal
    componentID="delete-region-confirmation"
    [title]="'supplier-regions.confirm-delete' | translate"
>
    <p>{{ 'supplier-regions.region-delete-confirmation' | translate }}</p>
    <div class="btn-toolbar justify-content-between">
        <div class="btn-group btn-group-sm">
            <button (click)="onRegionDeleteConfirmed()" class="btn btn-warning">
                <i class="fa fa-trash"></i>{{ 'common.delete' | translate }}
            </button>
            <button
                (click)="
                    modalService.setModalVisibility(false, 'delete-region-confirmation')
                "
                class="btn btn-danger"
            >
                <i class="fa fa-remove"></i>{{ 'common.cancel' | translate }}
            </button>
        </div>
    </div>
</lib-modal>
