import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../services/authentication/iauthentication.service';
import { UserRole } from '../types/user-role';
import { FlashMessageService } from '../modules/flash-message/flash-message.service';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard implements CanActivate {
    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _router: Router,
        private _flashMessageService: FlashMessageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authService.currentUser;
        const searchedRoles = route.data.roles as Array<UserRole>;
        if (!currentUser) {
            this._router.navigate(['/login'], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        } else if (!currentUser.hasRoles(searchedRoles)) {
            this._flashMessageService.showMessage(
                'Access denied',
                `You're not allowed to access this route`
            );
            this._router.navigate(['']);
            return false;
        }
        return true;
    }
}
