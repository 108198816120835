import { StreetType } from '../types/street-type';

export class Address {
    public countryCode = 'HU';
    public countryName = 'Magyarország';
    public addressName: string;
    public countyName: string;
    public cityName: string;
    public postalCode: string;
    public addressLine: string;
    public streetName: string;
    public streetType: StreetType;
    public houseNumber: string;
}
