import { Inject, Injectable } from '@angular/core';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';
import { SupplierWorktime } from 'dm-src/models/supplier-worktime';
import { Supplier } from 'dm-src/models/supplier';
import { Observable } from 'rxjs';
import { HttpParams, HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class SupplierControlService {
    constructor(@Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService) {}

    public GetAllControlDocuments(): Observable<HttpResponse<Supplier[]>> {
        return this._apiClient.getWithResponse<Supplier[]>(
            `supplierworktimes/get-all-control-documents`
        );
    }
}
