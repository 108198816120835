<ng-template #orderDetailsModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="order">
            Order {{ order.orderCode }} Details
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <lib-flash-message [id]="'order-details-modal'"></lib-flash-message>
        <nav ngbNav #nav="ngbNav" class="nav-tabs">
            <ng-container ngbNavItem>
                <a ngbNavLink>Order Items</a>
                <ng-template ngbNavContent>
                    <app-order-items-list
                        *ngIf="orderID | async"
                        [orderID]="orderID | async"
                    ></app-order-items-list>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
                <a ngbNavLink>Contact data</a>
                <ng-template ngbNavContent>
                    <app-customer-contact-data></app-customer-contact-data>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
                <a ngbNavLink>Order data</a>
                <ng-template ngbNavContent>
                    <app-order-data></app-order-data>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
                <a ngbNavLink>Payment data</a>
                <ng-template ngbNavContent>
                    <app-order-payment-data
                        [orderCode]="order.orderCode"
                    ></app-order-payment-data>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem *appHasRoles="['administrator', 'support']">
                <a ngbNavLink>Delivery data</a>
                <ng-template ngbNavContent>
                    <app-order-delivery-data
                        [orderCode]="order.orderCode"
                    ></app-order-delivery-data>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem *appHasRoles="['administrator', 'itsupport']">
                <a ngbNavLink>Correction</a>
                <ng-template ngbNavContent>
                    <app-order-correction
                        *ngIf="order"
                        [order]="order"
                    ></app-order-correction>
                </ng-template>
            </ng-container>

            <ng-container
                ngbNavItem
                *appHasRoles="['administrator', 'itsupport', 'support']"
            >
                <a ngbNavLink>Invoice</a>
                <ng-template ngbNavContent>
                    <app-order-invoice *ngIf="order" [order]="order"></app-order-invoice>
                </ng-template>
            </ng-container>
            <ng-container ngbNavItem>
                <a ngbNavLink>Order History</a>
                <ng-template ngbNavContent>
                    <app-order-history *ngIf="order" [order]="order"></app-order-history>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</ng-template>
