import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { huLocale } from 'ngx-bootstrap/locale';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { DateRange } from '../../../dtos/date-range';
defineLocale('hu', huLocale);

@Component({
    selector: 'lib-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
    @Input() initialDateRange: Date[] = [];
    @Output() select = new EventEmitter<DateRange>();
    private locale = `hu`;
    @Input() public initialValue = '';
    public defaultConfig: Partial<BsDaterangepickerConfig> = {
        adaptivePosition: true,
        dateInputFormat: environment.columnFilterDateFormat,
        isAnimated: true,
    };
    private _isInInitPhase: boolean;

    constructor(private _localeService: BsLocaleService) {}

    public onValueChange(value: Date[]): void {
        if (this._isInInitPhase) {
            this._isInInitPhase = false;
            return;
        }
        const [dateFrom, dateTo] = value;
        const formattedDates = new DateRange();
        formattedDates.dateFrom = this.convertSQLDateFormat(dateFrom);
        formattedDates.dateTo = this.convertSQLDateFormat(dateTo);
        this.select.emit(formattedDates);
    }

    public onInputChanged(event: KeyboardEvent): void {
        if (this._isInInitPhase) {
            this._isInInitPhase = false;
            return;
        }
        const value = (event.target as HTMLInputElement).value;
        if (value.length < 1) {
            const formattedDates = new DateRange();
            formattedDates.dateFrom = null;
            formattedDates.dateTo = null;
            this.select.emit(formattedDates);
        }
    }

    private convertSQLDateFormat(rawDate: Date): string {
        return rawDate != null ? moment(rawDate).format('YYYY-MM-DD') : null;
    }

    ngOnInit() {
        this._isInInitPhase = true;
        this._localeService.use(this.locale);
    }
}
