import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders.component';
import {
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PreloaderModule } from 'dm-src/app/modules/preloader/preloader.module';
import { OrderDetailsModalComponent } from './order-details-modal/order-details-modal.component';
import { AppSharedModule } from 'dm-src/app/modules/shared/app-shared.module';
import { SharedModule } from 'shared';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [OrdersComponent, OrderDetailsModalComponent],
    imports: [
        CommonModule,
        NgbDropdownModule,
        PreloaderModule,
        AppSharedModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        MomentModule,
        TranslateModule,
        NgbNavModule,
        SharedModule,
    ],
})
export class OrdersModule {}
