import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PermissionRequestWindowComponent } from './permission-request-window.component';

@NgModule({
    declarations: [PermissionRequestWindowComponent],
    imports: [CommonModule, TranslateModule],
    exports: [PermissionRequestWindowComponent],
})
export class PermissionRequestWindowModule {}
