import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfflineProductsService } from 'dm-src/app/modules/collecting/offline-products.service';

interface NativeBarcodeModel {
    identifier: string;
    barcode: string;
}

@Injectable({
    providedIn: 'root',
})
export class NativeBarcodeReaderService {
    private barcodeBehaviorSubject: BehaviorSubject<NativeBarcodeModel> =
        new BehaviorSubject({
            identifier: '',
            barcode: '',
        });
    private inputDomElement?: HTMLInputElement;

    constructor(private _offlieProductsService: OfflineProductsService) {}

    initBarcodeReading(GtinNeeded = true): void {
        try {
            this.inputDomElement = document.getElementById(
                'barcodeInput'
            ) as HTMLInputElement;

            this.inputDomElement?.removeEventListener('change', (e: Event) =>
                this.barcodeReadingInitListener(e, GtinNeeded)
            );

            this.inputDomElement?.addEventListener('change', (e: Event) =>
                this.barcodeReadingInitListener(e, GtinNeeded)
            );
        } catch (e) {
            console.error(e);
        }
    }

    barcodeReadingInitListener(e: Event, GtinNeeded: boolean): void {
        let value = (e.target as HTMLInputElement).value;

        const _identifier = value.split('###')[1];
        const _barcode = value.split('###')[0];

        this.barcodeBehaviorSubject.next({
            identifier: _identifier,
            barcode: _barcode,
        });

        if (GtinNeeded) {
            this._offlieProductsService.processGtin(_barcode);
        }
    }

    getBarcode(): Observable<NativeBarcodeModel> {
        return this.barcodeBehaviorSubject;
    }
}
