import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from 'shared';
import { AppSettings } from 'shared';
import { OrderItemListService } from '../order-item-list.service';

@Component({
    selector: '[order-removed-item]',
    templateUrl: './order-removed-item.component.html',
    styleUrls: ['./order-removed-item.component.scss'],
})
export class OrderRemovedItemComponent implements OnInit {
    @Input() index: number;
    @Input() orderItem: OrderItem;
    @Input() isReturnInProgress = false;
    public productPhotoUrl: string;

    constructor(public orderItemListService: OrderItemListService) {}

    ngOnInit(): void {
        this.productPhotoUrl = `${AppSettings.get('mediaServiceUrl')}/thumbnail/${
            this.orderItem.productPhotoID
        }`;
    }

    public openImage(src: string): void {
        this.orderItemListService.openImage(src);
    }
}
