import { QueryOperator } from './query-operator';
import { LogicalOperator } from './logical-operator';

export class FilterEvent {
    public column: string;
    public value: string;
    public filterOperator: QueryOperator = QueryOperator.Equals;
    public logicalOperator: LogicalOperator = LogicalOperator.And;
    public append = false;
}
