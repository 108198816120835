<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-3">
                <label for="date-picker" class="control-label">{{
                    'supplier-regions.choose-region' | translate
                }}</label>
                <app-supplier-region-chooser (onSelect)="onSupplierRegionChanged($event)">
                </app-supplier-region-chooser>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3">
                <label for="date-picker" class="control-label">{{
                    'supplier-resources.enabled-worktime-date-range' | translate
                }}</label>
                <lib-date-picker
                    id="date-picker"
                    [initialValue]="initialValue"
                    [initialDateRange]="initialDateRange"
                    (select)="onDateRangeSelected($event)"
                ></lib-date-picker>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3">
                <label for="worktime-enable-btn" class="control-label">{{
                    'supplier-resources.change-worktime-registration-state' | translate
                }}</label>
                <button
                    id="worktime-enable-btn"
                    *ngIf="
                        suppierWorktimeSettings.isWorktimeRegistrationEnabled;
                        else enable
                    "
                    (click)="onRegistrationButtonClicked(false)"
                    class="btn btn-block btn-danger"
                >
                    {{ 'supplier-resources.disable-worktime-registration' | translate }}
                </button>

                <ng-template #enable>
                    <button
                        (click)="onRegistrationButtonClicked(true)"
                        class="btn btn-block btn-success"
                    >
                        {{
                            'supplier-resources.enable-worktime-registration' | translate
                        }}
                    </button>
                </ng-template>
            </div>
            <div class="col-lg-3"></div>
        </div>
    </div>
</div>
