import { IPolicyRoles } from 'shared';
import { UserRole } from 'shared';
import { Policy } from 'shared';

export const AppPolicyRoles: IPolicyRoles[] = [
    {
        policy: Policy.EditSuppliers,
        roles: [UserRole.Administrator, UserRole.Supporter],
    },
    {
        policy: Policy.ResetSupplierAccounts,
        roles: [UserRole.Administrator],
    },
    {
        policy: Policy.SetOtherSuppliersWorktime,
        roles: [UserRole.Administrator],
    },
];
