<ng-template #roleChooserModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">User Roles</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div [ngClass]="{ 'with-providers': canSelectProvider, 'modal-body': true }">
        <div class="row">
            <div
                [ngClass]="{
                    'col-lg-6': canSelectProvider,
                    'col-lg-12': !canSelectProvider
                }"
            >
                <form [formGroup]="userRolesForm">
                    <div
                        class="form-check"
                        formArrayName="userRoles"
                        *ngFor="
                            let role of userRolesForm.get('userRoles')['controls'];
                            let i = index
                        "
                    >
                        <input
                            class="form-check-input"
                            [formControlName]="i"
                            type="checkbox"
                            [id]="userRoles[i].roleName"
                            (change)="onRoleClicked()"
                        />
                        <label class="form-check-label" [for]="userRoles[i].roleName">
                            {{ userRoles[i].roleName }}
                        </label>
                    </div>
                </form>
            </div>
            <div *ngIf="canSelectProvider" class="col-lg-6">
                <label>Assigned to provider</label>
                <ng-select [items]="providers" [(ngModel)]="selectedProvider">
                </ng-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="updateUserRoles()">
            Update Roles
        </button>
    </div>
</ng-template>
