import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmRegistrationComponent } from './confirm-registration.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'shared';

@NgModule({
    declarations: [ConfirmRegistrationComponent],
    imports: [CommonModule, RouterModule, SharedModule],
})
export class ConfirmRegistrationModule {}
