import { Component, Input, OnInit } from '@angular/core';
import { RefundingTaskListItem } from 'shared';
import { environment } from 'dm-src/environments/environment';
import { PaymentType } from 'shared';

@Component({
    selector: '[refunding-task-item]',
    templateUrl: './refunding-task-item.component.html',
    styleUrls: ['./refunding-task-item.component.scss'],
})
export class RefundingTaskItemComponent {
    @Input() task: RefundingTaskListItem;
    public isCollapsed = true;
    public dateFormat: string;
    public PaymentType = PaymentType;

    constructor() {
        this.dateFormat = environment.deliveryDateFormat;
    }
}
