<div class="card bg-secondary" *ngIf="hasFrame && taskGroup && taskGroup.tasks">
    <div class="card-header" (click)="isCollapsed = !isCollapsed">
        {{ taskGroup.deliveryDate | date : dateFormat : '+2' : 'hu-HU' }}
    </div>
    <div class="card-body text-center" [collapse]="isCollapsed" [isAnimated]="true">
        <app-task-group-item
            *ngFor="let taskGroupItem of taskGroup.tasks"
            [taskGroupItem]="taskGroupItem"
        ></app-task-group-item>
    </div>
    <div class="card-footer" [collapse]="isCollapsed" [isAnimated]="true"></div>
</div>

<ng-container *ngIf="!hasFrame && taskGroup && taskGroup.tasks">
    <div class="w-100 text-center">
        <app-task-group-item
            *ngFor="let taskGroupItem of taskGroup.tasks"
            [taskGroupItem]="taskGroupItem"
        ></app-task-group-item>
    </div>
</ng-container>
