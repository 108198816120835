<ion-back-button
    backButton
    defaultHref="/login"
    [text]="'common.back' | translate"
></ion-back-button>

<div class="fullheight xc">
    <div class="container-login">
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPassword()">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <h1>{{ 'login.forgot-password' | translate }}</h1>
                        <h3>{{ 'login.please-provide-email' | translate }}</h3>
                        <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                            {{ 'userform-common.success-body' | translate }}
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-item>
                                <ion-icon
                                    name="mail-outline"
                                    class="icon-login"
                                ></ion-icon>
                                <ion-input
                                    name="email"
                                    formControlName="email"
                                    type="text"
                                    autofocus
                                    required
                                    placeholder="{{ 'common.email' | translate }}"
                                >
                                </ion-input>
                                <span
                                    *ngIf="isSubmitted && fields.email.errors"
                                    class="field-error ion-padding"
                                >
                                    <span *ngIf="fields.email.errors.required"
                                        >Email
                                        {{ 'common.is-required' | translate }}</span
                                    >
                                </span>
                                <br />
                            </ion-item>
                        </ion-list>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-button type="submit" expand="block">
                            {{ 'common.submit' | translate }}
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>
</div>
