export class CloseCollectingTask {
    public userID: string;
    public orderID: number;
    public billPhotoImageContent: string;
    public billPhotoImageExtension: string;
    public totalPrice: number;
    public lat: number;
    public long: number;
    public taskId: string;
}
