import { Component, Inject, OnInit } from '@angular/core';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
} from '../../services/authentication/iauthentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'lib-logout',
    template: ``,
})
export class LogoutComponent implements OnInit {
    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        private _router: Router
    ) {}

    ngOnInit(): void {
        this._authService.logout().subscribe(() => {
            this._router.navigate(['login']);
        });
    }
}
