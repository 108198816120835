import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { skip, takeUntil } from 'rxjs/operators';
import { Order } from 'dm-src/models/order';
import { OrdersService } from '../orders.service';

@Component({
    selector: 'app-order-details-modal',
    templateUrl: './order-details-modal.component.html',
    styleUrls: ['./order-details-modal.component.scss'],
})
export class OrderDetailsModalComponent implements OnInit, OnDestroy {
    public order: Order;
    private _orderID: BehaviorSubject<number>;
    private _destroy$: ReplaySubject<boolean>;
    @ViewChild('orderDetailsModal', { static: false })
    private _orderDetailsModal: TemplateRef<any>;
    public billPhotoURL: string;
    public get orderID(): Observable<number> {
        return this._orderID.asObservable();
    }

    constructor(public ordersService: OrdersService, private _modalService: NgbModal) {
        this._orderID = new BehaviorSubject<number>(null);
        this._destroy$ = new ReplaySubject<boolean>(1);
    }

    ngOnInit() {
        this.ordersService.isOrderDetailsModalShown
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((isShown) => {
                if (isShown) {
                    this._modalService.open(this._orderDetailsModal, {
                        windowClass: 'modal-holder',
                        size: 'xl',
                        centered: true,
                    });
                } else {
                    this._modalService.dismissAll();
                }
            });

        this.ordersService.selectedOrder
            .pipe(skip(1))
            .pipe(takeUntil(this._destroy$))
            .subscribe((order) => {
                this.order = order;
                this._orderID.next(order.orderID);
            });
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
        this.ordersService.resetSelectedOrder();
    }
}
