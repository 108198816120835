<button
    [type]="type"
    [class]="btnClass"
    (click)="clickHandler($event)"
    [disabled]="disabled || inProgress"
>
    <i *ngIf="iconClass !== null && !inProgress" [class]="iconClass"></i>
    <span
        *ngIf="inProgress"
        class="spinner-border spinner-border-sm preloader"
        role="status"
        aria-hidden="true"
    ></span>
    <span *ngIf="inProgress; else content">{{ 'common.working' | translate }}...</span>
    <ng-template #content>
        <ng-content> </ng-content>
    </ng-template>
</button>
