<div class="card" *ngFor="let supplier of supplierList">
    <h5 class="card-header">Futárazonosító: {{ supplier.userID }}</h5>
    <div class="card-body">
        <div class="card-text">
            <ng-container *ngFor="let supplierWorktime of supplier.supplierWorktimes">
                <div
                    class="card w-100"
                    *ngIf="
                        supplierWorktime.supplierWorktimeControlDocumentList &&
                        supplierWorktime.supplierWorktimeControlDocumentList.length > 0
                    "
                >
                    <div class="card-header">
                        {{ supplierWorktime.worktimeStart | date : 'yyyy-MM-dd HH:mm' }} -
                        {{ supplierWorktime.worktimeEnd | date : 'yyyy-MM-dd HH:mm' }}
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <ng-container
                                *ngFor="
                                    let supplierWorktimeControlDocument of supplierWorktime.supplierWorktimeControlDocumentList
                                "
                            >
                                <div class="row" *ngIf="supplierWorktimeControlDocument">
                                    <div class="col-4">
                                        <a
                                            href="{{
                                                supplierWorktimeControlDocument.photoUrl
                                            }}"
                                            target="_blank"
                                        >
                                            <img
                                                src="{{
                                                    supplierWorktimeControlDocument.photoUrl
                                                }}"
                                                class="img-thumbnail"
                                            />
                                        </a>
                                    </div>
                                    <div class="col-4">
                                        {{
                                            supplierWorktimeControlDocument.additionalInfo
                                        }}
                                    </div>
                                    <div class="col-4">
                                        {{
                                            supplierWorktimeControlDocument.created
                                                | date : 'yyyy-MM-dd HH:mm'
                                        }}
                                    </div>
                                </div>
                            </ng-container>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</div>
