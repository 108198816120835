import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { SupplierResource } from 'dm-src/models/supplier-resource';
import { SchedulerResourceEvent } from 'dm-src/models/scheduler-resource-event';
import { SchedulerTimeslot } from 'dm-src/types/scheduler-timeslot';
import { SupplierWorktimeSettings } from 'dm-src/dtos/supplier-worktime-settings';

export const SUPPLIER_RESOURCES_SERVICE_IMPL =
    new InjectionToken<ISupplierResourcesService>('supplierResourcesServiceImpl');

export interface ISupplierResourcesService {
    getAvailableTimeSlots(
        supplierID: string
    ): Observable<HttpResponse<SchedulerTimeslot[]>>;
    getSupplierResources(
        supplierRegionID: string
    ): Observable<HttpResponse<SchedulerResourceEvent[]>>;
    createSupplierResources(
        supplierResources: SupplierResource[]
    ): Observable<HttpResponse<string[]>>;
    updateSupplierResource(
        supplierResource: SupplierResource
    ): Observable<HttpResponse<void>>;
    deleteSupplierResource(supplierResourceID: string): Observable<HttpResponse<void>>;
    getWorktimeSettings(): Observable<HttpResponse<SupplierWorktimeSettings>>;
    setWorktimeSettings(
        settings: SupplierWorktimeSettings
    ): Observable<HttpResponse<void>>;
}
