<ng-template #userChooserModal let-modal>
    <div class="modal-header modal-fullscreen">
        <h4 class="modal-title" id="modal-basic-title">Users</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="hideModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="break"></div>
        <ngb-alert [type]="'danger'" *ngIf="invalidUserData !== null" (close)="reset()">
            <strong>{{ invalidUserData.username }}</strong> does not have
            <ul class="validationErrors">
                <li *ngFor="let userRole of validationErrors.userRoles; let idx = index">
                    <strong>{{ UserRole[userRole] | lowercase }}</strong> role to get a
                    <strong>{{
                        DeliveryTaskType[validationErrors.deliveryTypes[idx]] | lowercase
                    }}</strong>
                    task.
                </li>
            </ul>
        </ngb-alert>
    </div>
    <div class="modal-body">
        <ng-template [ngIf]="isUserAssignedToASelectedTask">
            <button
                type="button"
                class="btn btn-warning float-right mr-2"
                (click)="appModalService.setModalVisibility(true, 'unassign-tasks')"
            >
                {{ 'tasks.unassign' | translate }}
            </button>
        </ng-template>
        <table class="table">
            <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Active tasks</th>
                <th>Actions</th>
            </tr>
            <tr
                scope="row"
                *ngFor="let user of (tasksService.filterOptions | async).users"
            >
                <td>
                    {{ user.username }}
                </td>
                <td>
                    {{ user.email }}
                </td>
                <td>
                    {{ user.activeTasks }}
                </td>
                <td>
                    <button class="btn btn-warning btn-sm" (click)="assignToTask(user)">
                        Assign task(s)
                    </button>
                </td>
            </tr>
        </table>
    </div>
</ng-template>
<lib-modal [title]="'tasks.unassign' | translate" [componentID]="'unassign-tasks'">
    <div align="center">
        <p>{{ 'tasks.unassign-confirm' | translate }}</p>
    </div>
    <div class="overlay-right d-flex justify-content-around">
        <div class="btn-group">
            <button class="btn btn-success" (click)="unassignTasks()">
                {{ 'common.yes' | translate }}
            </button>
            <button class="btn btn-primary" (click)="hideUnassignModal()">
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </div>
</lib-modal>
