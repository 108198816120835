import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    ISuppliersService,
    SUPPLIERS_SERVICE_IMPL,
} from 'dm-src/services/suppliers/isuppliers-service';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { InstantDeliveryWorktime } from '../../../models/instant-delivery-worktime';
import { FlashMessageService } from 'shared';
import { InstantDeliveryArea } from '../../../models/instant-delivery-area';

@Injectable({
    providedIn: 'root',
})
export class InstantDeliveryService {
    private _currentSupplierID: string;
    public instantSupplierWorktime: BehaviorSubject<InstantDeliveryWorktime>;
    public instantDeliveryAreas: BehaviorSubject<string[]>;
    public allInstantDeliveryAreas: BehaviorSubject<InstantDeliveryArea[]>;

    constructor(
        @Inject(SUPPLIERS_SERVICE_IMPL)
        private _suppliersService: ISuppliersService,
        private _flashMessageService: FlashMessageService,
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService
    ) {
        this._currentSupplierID = this._authService.currentUser.userID;
        this.instantSupplierWorktime = new BehaviorSubject<InstantDeliveryWorktime>(null);
        this.instantDeliveryAreas = new BehaviorSubject<string[]>([]);
        this.allInstantDeliveryAreas = new BehaviorSubject<InstantDeliveryArea[]>([]);
    }

    public getCurrentUsersInstantWorktime(): void {
        this._suppliersService
            .getInstantSupplierWorktime(this._currentSupplierID)
            .subscribe(
                (response) => {
                    if (response.status === 200) {
                        this.instantSupplierWorktime.next(response.body);
                    }
                },
                (error) => {
                    this._flashMessageService.showStoredMessage();
                }
            );
    }

    public getInstantDeliveryAreas(): void {
        this._suppliersService.getInstantDeliveryAreas().subscribe(
            (response) => {
                if (response.status === 200) {
                    this.instantDeliveryAreas.next(response.body);
                }
            },
            (error) => {
                this._flashMessageService.showStoredMessage();
            }
        );
    }

    public getAllAreas(): void {
        this._suppliersService.getAllAreas().subscribe(
            (response) => {
                if (response.status === 200) {
                    this.allInstantDeliveryAreas.next(response.body);
                }
            },
            (error) => {
                this._flashMessageService.showStoredMessage();
            }
        );
    }

    public changeInstantDeliveryAreaStatus(id: string): void {
        this._suppliersService.changeInstantDeliveryAreaStatus(id).subscribe(
            (response) => {
                if (response.status === 200) {
                    this.allInstantDeliveryAreas.next(response.body);
                }
            },
            (error) => {
                this._flashMessageService.showStoredMessage();
            }
        );
    }

    public cancelWorktime(): void {
        this._suppliersService
            .deleteInstantSupplierWorktime(
                this.instantSupplierWorktime.getValue().supplierWorktimeID
            )
            .subscribe(
                (response) => {
                    if (response.status === 200) {
                        this.instantSupplierWorktime.next(null);
                    }
                },
                (error) => {
                    this._flashMessageService.showStoredMessage();
                }
            );
    }

    public submitWorktime(fromDate: Date, toDate: Date, area: string): void {
        this._suppliersService
            .createInstantDeliveryWorktime({
                worktimeStart: fromDate,
                worktimeEnd: toDate,
                area: area,
                supplierWorktimeID: '',
                supplierID: this._currentSupplierID,
            })
            .subscribe(
                (response) => {
                    if (response.status === 200) {
                        this.instantSupplierWorktime.next(response.body);
                    }
                },
                (error) => {
                    this._flashMessageService.showStoredMessage();
                }
            );
    }

    public update(instantDeliveryWorktime: InstantDeliveryWorktime): void {
        this._suppliersService
            .updateInstantSupplierWorktime(instantDeliveryWorktime)
            .subscribe();
    }
}
